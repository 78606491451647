import { Button, Dropdown } from 'react-bootstrap';

import { useAccount } from 'wagmi';
import { BASE_URL } from '../../../../Config/ApiConfig';
import { INetwork } from '../../../../types/apiTypes';
import { Balance } from './Balance';
import { Tooltip } from '../../../../components/tooltips';

export const NetworksList = ({
  setNetwork,
  networks,
  networkOff,
  handleClose,
  category,
}: {
  networks: INetwork[];
  setNetwork: (value: INetwork) => void;
  networkOff: string;
  handleClose: () => void;
  category?: string;
}) => {
  const { isConnected } = useAccount();
  const onPress = (el: INetwork) => {
    setNetwork(el);
    // if (el.id !== networkOff) {
    // } else {
    //   handleClose();
    // }
  };
  return (
    <div className=" networkListDiv">
      {networks.length !== 0 ? (
        <div className="fs-6 networks-category-text mb-2">
          {category ? category : `Networks`}
          {category === 'Innovative Networks' && (
            <Tooltip>
              Innovative networks may have flexible max limits depending on
              market demand
            </Tooltip>
          )}
        </div>
      ) : (
        <div className="fs-6   text-white mb-2">No networks found</div>
      )}

      {networks.map((el) => {
        return (
          <Dropdown.Item
            onClick={() => onPress(el)}
            className={`d-flex flex-row align-items-center cursor p-1 my-2 link-offset-3-hover ${
              !el.active ? 'disabled-network-wrapper' : ''
            }`}
            key={el.id}
            // disabled={!el.active}
          >
            <div
              className={`d-flex flex-row  align-items-center cursor ${
                !el.active ? 'disabled-network' : ''
              }`}
            >
              <Button
                variant="light"
                className="d-flex coinBtn border-0 p-1 me-3 btn-20"
              >
                <img
                  src={BASE_URL + el.network_image_url}
                  alt="token"
                  className="icon-16"
                />
              </Button>
              {el.name}
            </div>
            {isConnected && el.active && <Balance network={el}/>}
            {!el.active && (
              <p className="disabled-network-description">
                Temporary not available
              </p>
            )}
          </Dropdown.Item>
        );
      })}
    </div>
  );
};
