import { Contract, ContractInterface, ethers } from "ethers";
import { isValidAddress } from "./isValidAddress";

export function getContract(
  address: string,
  ABI: ContractInterface,
  provider: ethers.Signer | ethers.providers.Provider
): Contract {
  if (!isValidAddress(address)) {
    throw Error(`Invalid 'address' parameter '${address}'. `);
  }

  return new ethers.Contract(address, ABI, provider);
}
