import { useMemo } from 'react';
import { NetworkTypes } from '../providers/web3Provider';
// import { Price } from "../services/PriceService";
import clsx from 'clsx';
import { maxPlatformDecimals } from '../constants/numbers';
import { useWalletStore } from '../stores/wallet.store';
import { toFixed } from '../utils/numbers';
import './styles.css';

import { BASE_URL } from '../Config/ApiConfig';
import { LoaderSmall } from './LoaderSmall';

function InfoBlock() {
  const {
    amount,
    receiveAmount,
    currencyPair,
    loading,

    networkFrom,
    networkTo,
    networksList,
  } = useWalletStore();

  const isStarknetInBridge = useMemo(() => {
    const fromNet = networksList.find((item) => item.id === networkFrom);
    const toNet = networksList.find((item) => item.id === networkTo);

    const isFromStark = fromNet?.network_type === NetworkTypes.STARKNET;
    const isToStark = toNet?.network_type === NetworkTypes.STARKNET;
    return isFromStark || isToStark;
  }, [networkFrom, networkTo, networksList]);

  const minutes = isStarknetInBridge ? 2 : 1;

  const [coinSymbol, coinImage] = useMemo(() => {
    if (currencyPair?.[0] && currencyPair?.[0]?.symbol) {
      return [currencyPair?.[0]?.symbol, currencyPair?.[0]?.image_url];
    }
    return ['', ''];
  }, [currencyPair]);

  const receiveTokenAmount = +receiveAmount > 0 ? +receiveAmount : 0;

  return (
    <>
      <div className={clsx(amount.length === 0 && 'd-none')}>
        <div className="d-flex align-items-center justify-content-between amount-receive-text-wrapper">
          <div className="fs-6 text-white bold">You Receive</div>
        </div>
        <div
          className={clsx(
            amount.length === 0 && 'd-none',
            `overflow-hidden text-secondary transitionHeight d-flex flex-direction-row justify-content-between topReceiveBlock infoBlock align-middle`
          )}
        >
          <div className="d-flex align-items-center gap-2 fs-6 text-white fw-bold bold">
            {loading && <LoaderSmall width={20} />}
            {!loading && (
              <>
                <div className="topReceiveBlock-token">
                  <img src={BASE_URL + coinImage} alt="" />
                </div>
                {`${toFixed(
                  receiveTokenAmount,
                  maxPlatformDecimals
                )} ${coinSymbol}`}{' '}
              </>
            )}
          </div>
          <div className="d-flex flex-row">
            <div className="d-flex justify-content-center flex-column receiveShortBlock">
              <p className="text-white bold">
                In less than {minutes} minute{minutes > 1 ? `s` : ''}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default InfoBlock;
