import { ethers } from 'ethers';
import { formatEther, parseEther } from 'ethers/lib/utils';
import { Contract, uint256 } from 'starknet';
import { NetworkTypes } from '../providers/web3Provider';
import { balanceABIFragment } from '../starknet';
import { starknetNativeAddress } from '../starknet/constants/contractAddresses.constant';
import { balanceSchema, decimalsSchema } from '../starknet/hooks/balanceSchema';
import { getStarknetProvider } from '../starknet/utils/getStarknetProvider';
import { INetwork } from '../types/apiTypes';
import { getMockNetwork } from './getMockRpcUrl';
import { toFixed } from './numbers';
import { walletStore } from '../stores/wallet.store';

const fetchNativeEvmBalance = async (account: string, rpc_url?: string) => {
  if (!rpc_url) {
    return '0';
  }

  const provider = new ethers.providers.JsonRpcProvider(rpc_url);

  const balance = await provider.getBalance(account);
  const parsedBalance = formatEther(balance);

  return parsedBalance;
};

const getStarknetBalance = async (
  walletAddress: string,
  rpc_url: string,
  is_rpc: boolean
) => {
  if (!rpc_url) {
    return '0';
  }

  const provider = getStarknetProvider(rpc_url, is_rpc);
  const contract = new Contract(
    balanceABIFragment,
    starknetNativeAddress,
    provider
  );
  const [balance, decimals] = await Promise.all([
    contract.call('balanceOf', [walletAddress], {
      parseResponse: true,
    }),
    contract.call('decimals', []),
  ]);

  const parsedBalance = balanceSchema.parse(balance);
  const parsedDecimals = decimalsSchema.parse(decimals);

  const balanceAsBN = uint256.uint256ToBN(parsedBalance.balance);
  const formatted = (
    Number(balanceAsBN.toString()) /
    10 ** (18 || Number(parsedDecimals))
  ).toString();

  return formatted;
};

const getBalanceByNetworkType = async (
  userAddress: string,
  network: INetwork
) => {
  const networkType = network.network_type;
  const mockNetwork = getMockNetwork(network);
  if (networkType === NetworkTypes.STARKNET) {
    return await getStarknetBalance(
      userAddress,
      mockNetwork.rpc_url,
      mockNetwork.is_rpc
    );
  }

  return await fetchNativeEvmBalance(userAddress, mockNetwork.rpc_url);
};

export const getSendAmount = async (
  inputedAmount: string,
  userAddress: string,
  network: INetwork
): Promise<string> => {
  try {
    if (inputedAmount.startsWith('.')) {
      inputedAmount = `0${inputedAmount}`;
    }

    const userBalance = await getBalanceByNetworkType(userAddress, network);

    const { transactionCurrencyFee } = walletStore.getState();

    const transactionPriceBn = parseEther(
      toFixed(transactionCurrencyFee?.source_chain_fee ?? 0, 18)
    ).toBigInt();

    const userBalanceBn = parseEther(userBalance).toBigInt();
    const inputedAmountBn = parseEther(inputedAmount).toBigInt();

    if (transactionPriceBn > userBalanceBn) {
      return '0';
    }

    const neededBalance = inputedAmountBn + transactionPriceBn;
    if (neededBalance > userBalanceBn) {
      const amountSend = userBalanceBn - transactionPriceBn;
      return toFixed(+formatEther(amountSend), 6);
    }

    return inputedAmount;
  } catch (error) {
    console.error(error);
  }
  return inputedAmount;
};
