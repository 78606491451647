import { AxiosError, InternalAxiosRequestConfig } from 'axios';
import { types } from 'starknet';
import { BASE_URL } from '../Config/ApiConfig';
import { NetworkTypes } from '../providers/web3Provider';
import { AppTypedData } from '../starknet/types/app_typed_data';
import { useSignStore } from '../stores/sign.store';
import { walletStore } from '../stores/wallet.store';
import {
  GetCurrencyPricesResponse,
  IAmountOut,
  IApiResp,
  ICurrency,
  IGetNetwork,
  INetwork,
  IOrder,
  IOrderResponse,
  IReceiver,
  ISimpleApiResp,
  ITransactionFee,
} from '../types/apiTypes';
import { OrderSearchStatuses } from '../types/enums';
import { getCookie } from '../utils/cookies';
import { ApiService as Api } from './CoreApi';
import { toFixed } from '../utils/numbers';
import { maxPlatformDecimals } from '../constants/numbers';

export const isDevelopment = process.env.NODE_ENV === 'development';
const networkTypeName = 'network-type';
const apiKey = 'api-key';

const requestInterceptor = (config: InternalAxiosRequestConfig) => {
  const api_key = getCookie('api_key');
  if (api_key) {
    config.headers[apiKey] = api_key;
  }
  return config;
};

const networkTypeSelector = (network: INetwork) => {
  const type =
    network.network_type === NetworkTypes.ZK_SYNC_ERA
      ? NetworkTypes.EVM
      : network.network_type;

  return type;
};

export class ApiService {
  private _api: Api;
  constructor() {
    this._api = new Api(BASE_URL, {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    });

    this._api.instance.defaults.headers.put['Content-Type'] =
      'application/json';
    this._api.instance.interceptors.request.use((config: any) =>
      requestInterceptor(config)
    );
  }

  async getNetwork() {
    const { setNetworkList, setLoading } = walletStore.getState();
    const url = `/api/network`;
    try {
      setLoading(true);

      const res: IGetNetwork = await this._api.get(url);
      setNetworkList(res.data);

      setLoading(false);
      return res.data;
    } catch (error) {
      isDevelopment && console.log(error, '======error getNetworks');
      setLoading(false);
    }
    return [];
  }
  async getCurrency() {
    const {
      setLoading,
      setCurrencyFromList,
      networkFrom,
      setCurrencyTo,
      networkTo,
    } = walletStore.getState();
    if (!networkFrom || !networkTo) {
      return;
    }
    const url = `/api/currency?network_from_id=${networkFrom}&network_to_id=${networkTo}`;
    try {
      setLoading(true);

      const res: IApiResp<ICurrency[]> = await this._api.get(url);
      const defaultToken = res.data
        .filter((c) => c.active)
        .find((el) => el.symbol.toLowerCase().includes('eth'));
      setCurrencyFromList(res.data);
      setCurrencyTo(
        defaultToken?.id ||
          res.data.filter((c) => c.active)[0]?.id ||
          res.data[0].id
      );

      setLoading(false);
    } catch (error) {
      isDevelopment && console.log(error, '======error getCurrency');
      setLoading(false);
    }
  }
  async getCurrencyPair() {
    const { setLoading, currencyFromId, networkTo, setCurrencyPair } =
      walletStore.getState();
    const url = `/api/currency/${currencyFromId}/pairs?network_to_id=${networkTo}`;
    try {
      setLoading(true);

      const res: ISimpleApiResp<IApiResp<ICurrency[]>> = await this._api.get(
        url
      );

      if (res.data) {
        setCurrencyPair(res.data.data);
      }

      setLoading(false);
    } catch (error) {
      isDevelopment && console.log(error, '======error getCurrency');
      setLoading(false);
    }
  }

  async getAllCurrencyPairs() {
    const { currencyFromId, setAllCurrencyPairs } = walletStore.getState();
    const url = `/api/currency/${currencyFromId}/pairs?limit=100`;
    try {
      const res: ISimpleApiResp<IApiResp<ICurrency[]>> = await this._api.get(
        url
      );

      if (res.data) {
        setAllCurrencyPairs(res.data.data);
      }

      return res.data.data;
    } catch (error) {
      isDevelopment && console.log(error, '======error getCurrency');
    }
  }

  async getTransactionFee(
    currencyFromId: string,
    currencyToId: string,
    walletSender?: string | null
  ) {
    const { setLoading, settransactionCurrencyFee } = walletStore.getState();
    let url: string;

    if (walletSender) {
      url = `/api/orders/fee/${currencyFromId}/${currencyToId}/?wallet_address=${walletSender}`;
    } else {
      url = `/api/orders/fee/${currencyFromId}/${currencyToId}`;
    }

    try {
      setLoading(true);

      const res: ISimpleApiResp<ITransactionFee> = await this._api.get(url);

      if (res.data) {
        settransactionCurrencyFee(res.data);
      }

      setLoading(false);
    } catch (error) {
      isDevelopment && console.log(error, '======error getCurrency');
      setLoading(false);
    }
  }

  async getAmountOut(
    wallet: string,
    currencyFromId: string,
    currencyPairId: string,
    amount: string
  ) {
    const { setLoading, setReceiveAmount } = walletStore.getState();
    const url = `/api/orders/calculate_amount_out`;
    try {
      setLoading(true);
      const data = {
        currency_in_id: currencyFromId,
        currency_out_id: currencyPairId,
        amount: amount,
        wallet_receiver: wallet,
      };

      const res: IApiResp<IAmountOut> = await this._api.post(url, data);
      if (res.data) {
        setReceiveAmount(toFixed(res.data.amount_out, maxPlatformDecimals));
      } else {
        setReceiveAmount('');
      }

      setLoading(false);
    } catch (error) {
      isDevelopment && console.log(error, '======error getAmountOut');
      setLoading(false);
      setReceiveAmount('');
    } finally {
      setLoading(false);
    }
  }

  async getReceiver() {
    const { setLoading, setReceiverWallet, currencyPair, currencyFromId } =
      walletStore.getState();
    const url = `/api/orders/receiver?currency_in_id=${currencyFromId}&currency_out_id=${currencyPair[0]?.id}`;
    try {
      setLoading(true);

      const res: IApiResp<IReceiver> = await this._api.get(url);

      if (res.data) {
        setReceiverWallet(res.data.wallet);
      }

      setLoading(false);
    } catch (error) {
      isDevelopment && console.log(error, '======error getReceiveAmount');
      setLoading(false);
    }
  }

  async getOrderById(orderId: string, setAsCurrent = true) {
    const { setCurrentOrder } = walletStore.getState();
    const url = `/api/orders/info/${orderId}`;
    try {
      const res: { message: string; data: IOrder } = await this._api.get(url);
      if (res && setAsCurrent) {
        setCurrentOrder(res.data);
      }
      return res.data;
    } catch (error) {
      isDevelopment && console.log(error, '======error getOrderById');
    }
  }

  async sendOrder(
    fromCurrency: ICurrency,
    toCurrency: ICurrency,
    amount: string,
    wallet_sender: string,
    walletTo: string
  ) {
    const { setLoading, setCurrentOrder } = walletStore.getState();
    const url = `/api/orders`;
    const type = networkTypeSelector(fromCurrency.contract.network);
    try {
      setLoading(true);
      const data = {
        currency_in_id: fromCurrency.id,
        currency_out_id: toCurrency.id,
        amount: amount,
        wallet_sender,
        wallet_receiver: walletTo,
      };

      const res: { message: string; data: IOrder } = await this._api.post(
        url,
        JSON.stringify(data),
        {
          headers: {
            [networkTypeName]: type,
          },
        }
      );

      if (res) {
        setCurrentOrder(res.data);
      }

      return res.data;
    } catch (error) {
      if (error instanceof AxiosError && error.response?.status === 401) {
        const { setResign } = useSignStore.getState();
        setResign(type);
      }
      isDevelopment && console.log(error, '======error sendOrder');
      throw error;
    } finally {
      setLoading(false);
    }
  }

  async getOrders(
    status: OrderSearchStatuses,
    search?: string,
    wallet?: string,
    setToStore = true,
    skip = 0,
    take = 50,
  ) {
    const { setOrders, setLoading } = walletStore.getState();
    const url = '/api/orders';

    try {
      if (setToStore) setLoading(true);

      let data: IOrder[] = [];

      if (take <= 50) {
        const res: IApiResp<IOrderResponse> = await this._api.get(url, {
          params: {
            take,
            skip,
            status,
            search: search || undefined,
            wallet: wallet || undefined,
          },
        });
        data = res.data.data;
      } else {
        for (let i = 0; i * 50 < take; i++) {
          const res: IApiResp<IOrderResponse> = await this._api.get(url, {
            params: {
              take: 50,
              skip: skip + i * 50,
              status,
              search: search || undefined,
              wallet: wallet || undefined,
            },
          });

          data = [...data, ...res.data.data];
        }
      }

      if (data && setToStore) {
        setOrders(data);
      }

      if (setToStore) setLoading(false);
      return data;
    } catch (error) {
      isDevelopment && console.log(error, '======error getOrders');
      setLoading(false);
    }
    return undefined;
  }

  async getSignMessage() {
    const url = `/api/wallet_auth/message`;
    try {
      const res: ISimpleApiResp<AppTypedData> = await this._api.get(url);

      return res;
    } catch (error) {
      isDevelopment && console.log(error, '======error getSignMessage');
      throw error;
    }
  }

  async validateSignedMessage(
    wallet_address: string,
    network_type: NetworkTypes,
    signature: string | types.Signature
  ) {
    const url = `/api/wallet_auth/message`;
    try {
      await this._api.post(url, { wallet_address, network_type, signature });
    } catch (error) {
      isDevelopment && console.log(error, '======error validateSignedMessage');
      throw error;
    }
  }

  async checkWallet(wallet_address: string, network_type: NetworkTypes) {
    const url = `/api/wallet_auth/wallet/${wallet_address}`;
    try {
      await this._api.get(url, {
        headers: { [networkTypeName]: network_type },
      });
    } catch (error) {
      isDevelopment && console.log(error, '======error checkWallet');
      throw error;
    }
  }

  async sendApiKeyRequest(data: {
    name: string;
    email: string;
    telegramUsername: string;
    description: string;
  }) {
    const url = `/api/dashboard/request-api-key`;
    return await this._api.post(url, data);
  }

  async getPrices(symbol?: string | string[]) {
    const url = '/api/prices';

    const response: GetCurrencyPricesResponse = await this._api.get(url, {
      params: symbol,
    });

    return response.data;
  }

  async closeOrder(orderid: string, network_type: INetwork) {
    const url = `api/orders/close/${orderid}`;

    return await this._api.put(
      url,
      {},
      {
        headers: {
          [networkTypeName]: networkTypeSelector(network_type),
        },
      }
    );
  }
}

export const api = new ApiService();
