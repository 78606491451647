import { SVGAttributes } from "react";

export function DocumentIcon(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_8591_10830)">
        <path d="M7 0V3.49995H10.5L7 0Z" fill="currentColor" />
        <path
          d="M7.00005 4.50005C6.44774 4.50005 6 4.05184 6 3.5V0.000488281H2.50005C1.94774 0.000488281 1.5 0.447793 1.5 1.0001V10.9991C1.5 11.5518 1.94774 11.999 2.50005 11.999L9.49995 12C10.0518 12 10.5 11.5518 10.5 11V4.50005H7.00005Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_8591_10830">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
