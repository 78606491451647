import { useState } from 'react';
import { default as Button } from 'react-bootstrap/Button';
import { useAccount as useEVMAccount } from 'wagmi';
import '../../../pages/SendPage/SendPage.css';
import { WalletButtonsProps } from '../../../pages/SendPage/components/walletBtns';
import WalletAddressInputl from '../../../pages/SendPage/components/walletIAddressInput';

import { useConnectedIcon } from '../../../hooks/useConnectedIcon';
import { shortAddress } from '../../../utils';
import { starknetWallets } from '../../constants/wallets.constant';
import { useAccount } from '../../hooks';
import { ConnectWalletModalWithDisabled } from '@/components/ConnectWalletModalWithDisabled';
import { NetworkTypes } from '@/providers/web3Provider';

export const FromStarknetWalletButtons = ({
  inputClass,
  setInputClass,
  ...props
}: WalletButtonsProps) => {
  const { address: account } = useEVMAccount();
  const icon = useConnectedIcon();

  const { connector: starknetConnector, address: starknetAddress } =
    useAccount();

  const [openModal, setOpenModal] = useState(false);

  const connectedAccount = account || starknetAddress;

  const onClickConnected = () => {
    setInputClass('hidden');
  };

  const onClickInputAddress = () => {
    setInputClass('showInput');
  };

  const onClickConnectEvmHandler = () => {
    setOpenModal(true);
    setInputClass('hidden');
  };

  const starknetWallet = starknetWallets.find(
    (item) => item.id === starknetConnector?.id
  );

  const connectedWallet = starknetWallet || { icon };

  // useEffect(()=>{
  //   if(!starknetAddress){
  //     setAmount('')
  //   }
  // }, [starknetAddress])

  if (!connectedAccount || !starknetWallet) {
    return (
      <div
        className={`${'d-none'} d-flex justify-content-between inputGroup mb-3 text-white w-100 text-nowrap`}
      />
    );
  }

  return (
    <>
      <div
        className={`d-flex justify-content-between inputGroup text-white w-100 text-nowrap select-receiving-wallet-wrapper`}
      >
        {!!account ? (
          <div
            onClick={onClickConnected}
            className={` leftWalletBtnDiv ${
              inputClass === 'hidden' ? '' : 'btn'
            } d-flex  align-items-center ${
              inputClass === 'hidden' && 'gradient'
            } leftWalletBtnDiv_connected`}
          >
            <div className="d-flex coinBtn wallet-icon-size me-2">
              <img
                width="13px"
                height="13px"
                src={connectedWallet.icon}
                alt="network icon networkSmallIcon"
              />
            </div>
            <div className="d-flex flex-column">
              <div className="text-white fw-bold wallet-btns-text-size connected-wallet-text">
                Connected Wallet
              </div>
              <div className=" fw-medium blanco address connected-address">{`${shortAddress(
                connectedAccount
              )}`}</div>
            </div>
          </div>
        ) : (
          <Button
            onClick={onClickConnectEvmHandler}
            variant="outline-secondary"
            className={`btn leftWalletBtnDiv wallet-btns-text-size connect-starknet-btn ${
              inputClass === 'hidden' && 'active'
            }`}
          >
            Connect EVM Wallet
          </Button>
        )}
        <div
          onClick={onClickInputAddress}
          className={`btn rightWalletBtnDiv text-white wallet-btns-text-size ${
            inputClass !== 'hidden' && 'gradient'
          }`}
        >
          Select Other Address
        </div>
      </div>
      <WalletAddressInputl {...props} />
      <ConnectWalletModalWithDisabled
        openModal={openModal}
        setOpenModal={setOpenModal}
        networkType={NetworkTypes.EVM}
        onlySelectedNetworkType
      />
    </>
  );
};
