import * as Tooltip from '@radix-ui/react-tooltip';
import { useRef } from 'react';
import { FeeTooltipBg } from './FeeTooltipBg';
import "./styles.css";

export function FeeTooltip() {
  const triggerRef = useRef<HTMLButtonElement>(null);

  return (
    <Tooltip.Provider>
      <Tooltip.Root>
        <Tooltip.Trigger
          ref={triggerRef}
          onClick={(event) => event.preventDefault()}
          className="fee-tooltip-trigger"
        >
          ?
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            side="top"
            align="start"
            alignOffset={-15}
            avoidCollisions={false}
            sideOffset={5}
            asChild
            onPointerDownOutside={(event) => {
              if (event.target === triggerRef.current) event.preventDefault();
            }}
          >
            <div className="fee-tooltip">
              <FeeTooltipBg />
              Cost of transfer to your address in Destination network
            </div>
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}
