import { useCallback, useEffect, useMemo, useState } from 'react';
import { default as Button } from 'react-bootstrap/Button';
import { useSearchParams } from 'react-router-dom';
import { useAccount as useEVMAccount } from 'wagmi';
import exchange from '../../Assets/Icons/exgange.svg';
import { Loader } from '../../components/Loader';
import InfoBlock from '../../components/infoBlock';
import { networkListIndexes } from '../../constants/mock_networks.constants';
import { isValidAddress } from '../../evm/utils/isValidAddress';
import { useBalance } from '../../hooks/useBalance';
import { useTransferInfo } from '../../hooks/useTransferInfo';
import { NetworkTypes } from '../../providers/web3Provider';
import { api } from '../../services/api';
import { useAccount } from '../../starknet';
import { FromStarknetWalletButtons } from '../../starknet/components/StarknetWalletButtons/FromStarknetWalletButtons';
import { ToStarknetWalletButtons } from '../../starknet/components/StarknetWalletButtons/ToStarknetWalletButtons';
import { isValidStarknetAddress } from '../../starknet/utils/isValidAddress';
import { useWalletStore } from '../../stores/wallet.store';
import { ICurrency, INetwork } from '../../types/apiTypes';
import { toFixed } from '../../utils/numbers';
import './SendPage.css';
import InputControl from './components/inputControl';
import NetworkSelect from './components/nerWorkSelect';
import WalletBtns from './components/walletBtns';
import { InfoIcon } from '../../Assets/Icons/Info';
import { Tooltip } from '../../components/tooltips';
import { withdrawalWarning } from '../../constants/netwroks';
import { useDebounceValue } from 'usehooks-ts';
import { motion } from 'framer-motion';

function SendForm({
  setShow,
  setShowTwo,
  openConfirm,
  setOpenModal,
}: {
  setShow: (value: boolean) => void;
  setShowTwo: (value: boolean) => void;
  openConfirm: () => void;
  setOpenModal: (value: boolean) => void;
}) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [walletAddress, setWalletAdress] = useState('');
  const [inputClass, setInputClass] = useState<'hidden' | 'showInput'>(
    'hidden'
  );

  const { address: account } = useEVMAccount();

  const { address: starknetAddress } = useAccount();

  const {
    currencyFromList,
    setNetworkFrom,
    setNetworkTo,
    networkFrom,
    networkTo,
    currencyFromId,
    setCurrencyTo,
    amount,
    setAmount,
    setWalletTo,
    walletTo,
    receiveAmount,
    setReceiveAmount,
    networksList,
    currencyPair,
  } = useWalletStore();

  const [tempToArray, setTempToArray] = useState<INetwork[] | undefined>();
  const [tempFromArray, setTempFromArray] = useState<INetwork[] | undefined>();

  const selectedNetwork = useMemo(() => {
    return networksList.find((network) => network.id === networkFrom);
  }, [networkFrom, networksList]);

  const destinationNetwork = useMemo(() => {
    return networksList.find((network) => network.id === networkTo);
  }, [networkTo, networksList]);

  const selectedToken = useMemo(() => {
    return currencyFromList.find((c) => c.id === currencyFromId);
  }, [currencyFromId, currencyFromList]);

  const isFromStarknet =
    selectedNetwork?.network_type === NetworkTypes.STARKNET;
  const isToStarknet =
    destinationNetwork?.network_type === NetworkTypes.STARKNET;

  const { amountUsd } = useTransferInfo();

  const [errorMessage, setErrorMessage] = useState('');

  const connectedAccount = useMemo(
    () => account || starknetAddress,
    [account, starknetAddress]
  );

  const changeWalletToHandler = useCallback(() => {
    if (isValidAddress(walletAddress)) {
      setWalletTo(walletAddress);
    } else if (isToStarknet && isValidStarknetAddress(walletAddress)) {
      setWalletTo(walletAddress);
    } else {
      setWalletTo('');
    }
  }, [walletAddress, isToStarknet, setWalletTo]);

  useEffect(() => {
    if (inputClass === 'showInput') {
      changeWalletToHandler();
    }
  }, [changeWalletToHandler, inputClass]);

  useEffect(() => {
    if (inputClass === 'hidden' && account && !isToStarknet) {
      setWalletTo(account);
    }
    if (inputClass === 'hidden' && starknetAddress && isToStarknet) {
      setWalletTo(starknetAddress);
    }
  }, [account, inputClass, setWalletTo, isToStarknet, starknetAddress]);

  useEffect(() => {
    const activeNetwokrs = networksList?.filter((net) => net.active);

    const fromQuery = searchParams.get('source');
    if (activeNetwokrs && activeNetwokrs.length > 1 && !networkFrom) {
      const defaultNetwork = networksList.find((item) =>
        fromQuery
          ? item.name.toLowerCase() === fromQuery.toLowerCase()
          : item.name.toLowerCase().includes('ethereum')
      );

      setNetworkFrom(defaultNetwork?.id ?? networksList[0].id);
    }

    const toQuery = searchParams.get('destination');
    if (activeNetwokrs && activeNetwokrs.length > 1 && !networkTo) {
      const availableNetworks = networksList.filter(
        (item) => item.name !== fromQuery
      );

      const defaultNetwork = availableNetworks.find((item) =>
        toQuery
          ? item.name.toLowerCase() === toQuery.toLowerCase()
          : item.name.toLowerCase().includes('zksync era')
      );
      setNetworkTo(defaultNetwork?.id ?? availableNetworks[1].id);
    }
    const queryAmount = searchParams.get('amount');
    if (queryAmount && !amount) {
      setAmount(queryAmount);
    }

    const queryReceiver = searchParams.get('receiver');
    if (
      (queryReceiver && isValidAddress(queryReceiver)) ||
      (queryReceiver && isValidStarknetAddress(queryReceiver))
    ) {
      setInputClass('showInput');
      setWalletAdress(queryReceiver);
    }

    const queryToken = searchParams.get('token');

    if (queryToken) {
      const token = currencyFromList.find(
        (token) => token.symbol.toLowerCase() === queryToken.toLowerCase()
      );
      token && setCurrencyTo(token.id);
    }
  }, [
    networksList,
    setNetworkFrom,
    setNetworkTo,
    currencyFromList,
    networkFrom,
    networkTo,
  ]);

  useEffect(() => {
    if (currencyFromId) api.getCurrencyPair();
  }, [networkFrom, networkTo, currencyFromId]);

  const exchangeNetwork = () => {
    const networkFromElement = networksList.find(
      (item) => item.id === networkFrom
    );
    const networkToElement = networksList.find((item) => item.id === networkTo);

    setNetworkFrom(networkTo);
    setNetworkTo(networkFrom);

    if (networkToElement) {
      setSearchParams((searchParams) => {
        searchParams.set('source', networkToElement?.name);
        return searchParams;
      });
    }
    if (networkFromElement) {
      setSearchParams((searchParams) => {
        searchParams.set('destination', networkFromElement?.name);
        return searchParams;
      });
    }
  };

  const setNetworkFromHandler = useCallback(
    (networkId: string) => {
      const networkFromId = networkFrom;
      const toId = networkTo;
      const networkFromElement = networksList.find(
        (item) => item.id === networkId
      );

      if (networkId === toId) {
        const networkToElement = networksList.find(
          (item) => item.id === networkFromId
        );
        if (networkToElement) {
          setSearchParams((searchParams) => {
            searchParams.set('destination', networkToElement.name);
            return searchParams;
          });
        }
        setNetworkTo(networkFromId);
      }

      if (networkFromElement) {
        setSearchParams((searchParams) => {
          searchParams.set('source', networkFromElement.name);
          return searchParams;
        });
      }

      setNetworkFrom(networkId);
      setReceiveAmount('');
      setErrorMessage('');
    },
    [networkTo, networkFrom, searchParams]
  );

  const setNetworkToHandler = useCallback(
    (networkId: string) => {
      const fromId = networkFrom;
      const toId = networkTo;
      const networkToElement = networksList.find(
        (item) => item.id === networkId
      );

      if (networkId === fromId) {
        const networkFromElement = networksList.find(
          (item) => item.id === toId
        );
        if (networkFromElement) {
          setSearchParams((searchParams) => {
            searchParams.set('source', networkFromElement.name);
            return searchParams;
          });
        }
        setNetworkFrom(toId);
      }

      if (networkToElement) {
        setSearchParams((searchParams) => {
          searchParams.set('destination', networkToElement.name);
          return searchParams;
        });
      }

      setNetworkTo(networkId);
      setReceiveAmount('');
      setErrorMessage('');
    },
    [networkTo, networkFrom, searchParams]
  );

  const { balance, loading } = useBalance(selectedNetwork, selectedToken);

  const validateWalletTo = (walletTo: string, isToStark: boolean): boolean => {
    if (isToStark) {
      return isValidStarknetAddress(walletTo);
    }
    return isValidAddress(walletTo);
  };

  const shouldDisableTransfer = useMemo(() => {
    return (
      !walletTo ||
      !validateWalletTo(walletTo, isToStarknet) ||
      !selectedToken ||
      +amount < selectedToken.min_send ||
      +amount > selectedToken.max_send ||
      !selectedToken.active ||
      !receiveAmount
    );
  }, [amount, selectedToken, walletTo, receiveAmount, isToStarknet]);

  const showMinMaxAmountError = useCallback(() => {
    if (!selectedToken || amount === '' || +amount === 0) {
      setErrorMessage('');
      return;
    }
    if (+amount < selectedToken.min_send) {
      setErrorMessage('The amount to send is less than the minimal limit');
      return;
    }

    if (+amount > selectedToken.max_send) {
      setErrorMessage('The amount to send exceeds the maximum limit');
      return;
    }

    setErrorMessage('');
  }, [selectedToken, amount]);

  const userBalance = useMemo(() => toFixed(+balance, 6), [balance]);

  const amountError = useMemo(() => {
    if (amount) {
      if (!connectedAccount || !Number(userBalance)) {
        return true;
      }

      return Number(amount) > Number(userBalance);
    }
    return false;
  }, [amount, userBalance, connectedAccount]);

  const usdtAmount = amountUsd && amountUsd !== '0.00' ? amountUsd : '0';

  const isAddressValid = useMemo(() => {
    if (isToStarknet) {
      return isValidStarknetAddress(walletAddress);
    }
    return isValidAddress(walletAddress);
  }, [walletAddress, isToStarknet]);

  const showAddressError =
    !!walletAddress && !isAddressValid && inputClass !== 'hidden';

  const [debounceAmount] = useDebounceValue(amount, 1000);

  useEffect(() => {
    if (debounceAmount && !isNaN(+debounceAmount) && !amountError) {
      const currencyToId = currencyPair?.[0]?.id;
      api.getAmountOut(walletTo, currencyFromId, currencyToId, debounceAmount);
    } else {
      setReceiveAmount('');
    }
  }, [
    setReceiveAmount,
    walletTo,
    debounceAmount,
    currencyFromId,
    currencyPair,
    amountError,
  ]);

  useEffect(() => {
    showMinMaxAmountError();
  }, [showMinMaxAmountError]);

  const fromList = useMemo(() => {
    const selectedFrom = networksList.filter((item) => item.id === networkFrom);

    const list = [
      ...networksList.filter(
        (item) => item.id !== networkFrom && item.id !== networkTo
      ),
    ];

    const sliced = list.slice(networkListIndexes.fromStartIndex, 5);
    sliced.unshift(...selectedFrom);

    const newNetworkFromTemp = tempFromArray?.findIndex(
      (item) => item.id === networkFrom
    );

    if (tempFromArray?.length && newNetworkFromTemp === 0) return tempFromArray;
    if (
      tempFromArray?.length &&
      !!newNetworkFromTemp &&
      newNetworkFromTemp !== -1
    ) {
      const tempSelected = tempFromArray[0];
      const newArr = new Array(...tempFromArray);
      newArr[0] = tempFromArray[newNetworkFromTemp];
      newArr[newNetworkFromTemp] = tempSelected;
      setTempFromArray(newArr);
      return newArr;
    }
    setTempFromArray(sliced);

    return sliced;
  }, [networkFrom, networkTo]);

  const toList = useMemo(() => {
    const selectedTo = networksList.filter((item) => item.id === networkTo);

    const list = new Array(
      ...networksList
        .slice(networkListIndexes.toStartIndex)
        .filter((item) => item.id !== networkTo)
    );

    list.unshift(...selectedTo);

    const newNetworkToTemp = tempToArray?.findIndex(
      (item) => item.id === networkTo
    );

    if (tempToArray?.length && newNetworkToTemp === 0) return tempToArray;

    if (tempToArray?.length && newNetworkToTemp && newNetworkToTemp !== -1) {
      const tempSelected = tempToArray[0];
      const newArr = new Array(...tempToArray);
      newArr[0] = tempToArray[newNetworkToTemp];
      newArr[newNetworkToTemp] = tempSelected;
      setTempToArray(newArr);
      return newArr;
    }
    setTempToArray(list);
    return list;
  }, [networkTo]);

  const handleAmountInput = (amount: string) => {
    const splitedInput = amount.split('.');
    if (splitedInput[0]?.length > 5) return;
    if (splitedInput[1]?.length > 6) return;

    setAmount(amount);
    setSearchParams((searchParams) => {
      searchParams.set('amount', amount);
      return searchParams;
    });
  };

  const handleChangeReceiverWallet = (address: string) => {
    setWalletAdress(address);
    setSearchParams((searchParams) => {
      searchParams.set('receiver', address);
      return searchParams;
    });
  };
  const handleChangeCurrency = (currency: ICurrency) => {
    setCurrencyTo(currency.id);
    setSearchParams((searchParams) => {
      searchParams.set('token', currency.symbol);
      return searchParams;
    });
  };

  const withdrawalWarningNetwork = useMemo(() => {
    if (!destinationNetwork?.name || !selectedNetwork?.name) return false;

    return withdrawalWarning.find(
      (el) => el === destinationNetwork?.name || el === selectedNetwork?.name
    );
  }, [selectedNetwork?.name, destinationNetwork?.name]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="formBg mt-0 form-max-width defaultRadius gradient-border-mask"
    >
      <div className="transitionHeight formBody text-white align-items-center send-form-min-height">
        {!!networksList.length ? (
          <>
            <NetworkSelect
              label="From"
              network={networkFrom}
              networkOff={networkTo}
              networks={fromList}
              setValue={setNetworkFromHandler}
              setShow={setShow}
            />
            <div className="d-flex justify-content-center align-items-center switch-btn-wrapper">
              <Button
                onClick={exchangeNetwork}
                className="transactionInfoDableDivCircle align-self-center exchange-btn-size"
                variant="outline-secondary"
              >
                <img src={exchange} className="exchangeImg" alt="" />
              </Button>
            </div>

            <NetworkSelect
              label="To"
              network={networkTo}
              networkOff={networkFrom}
              networks={toList}
              setValue={setNetworkToHandler}
              setShow={setShowTwo}
            />
            {withdrawalWarningNetwork && (
              <div className="send-page-badge">
                <Tooltip
                  variant="dark"
                  trigger={<InfoIcon />}
                  triggerProps={{
                    className: 'm-0',
                  }}
                  contentProps={{
                    side: 'bottom',
                    sideOffset: 11,
                    alignOffset: -19,
                  }}
                >
                  Due to network infrastructure <br />
                  withdrawals may be temporary limited
                </Tooltip>
                Withdrawals from {withdrawalWarningNetwork} temporary limited
              </div>
            )}
            <div className="d-flex align-items-center justify-content-between amount-send-text-wrapper">
              <div className="fs-6 text-white bold">
                Amount You Sent{' '}
                <span className="min-max-text">
                  {selectedToken
                    ? `(min ${selectedToken?.min_send} - max ${selectedToken?.max_send})`
                    : ''}
                </span>
              </div>
            </div>
            <InputControl
              value={amount}
              setValue={handleAmountInput}
              currency={currencyFromId}
              setCurrency={handleChangeCurrency}
              currencyArr={currencyFromList}
              userBalance={Number(userBalance)}
            />
            {!!errorMessage &&
              (!!amountError && !!connectedAccount ? null : (
                <p className="fw-medium blanco error-message mb-1 mt-2">
                  {errorMessage}
                </p>
              ))}
            {connectedAccount && amountError && !loading && (
              <p className="fw-medium error-message mb-1 mt-2">
                You don't have enough funds on your balance
              </p>
            )}
            <div
              className={`fs-6 ${'minimum-amount-bottom-small'} ${
                !!errorMessage && 'with-error'
              }`}
            >
              <span className="fw-medium blanco">Your Balance:</span>{' '}
              <span className="fz12">
                {connectedAccount ? userBalance : '0'}
              </span>
            </div>

            {!errorMessage && !amountError && <InfoBlock />}

            <div className="fs-6 text-white fw-bold select-address-text">
              Select Receiving Address
            </div>

            {isFromStarknet || isToStarknet ? (
              <>
                {isToStarknet && (
                  <ToStarknetWalletButtons
                    value={walletAddress}
                    setValue={handleChangeReceiverWallet}
                    classN={inputClass}
                    isValidAddress={isAddressValid}
                    setInputClass={setInputClass}
                    inputClass={inputClass}
                  />
                )}
                {isFromStarknet && (
                  <FromStarknetWalletButtons
                    value={walletAddress}
                    setValue={handleChangeReceiverWallet}
                    classN={inputClass}
                    isValidAddress={isAddressValid}
                    setInputClass={setInputClass}
                    inputClass={inputClass}
                  />
                )}
              </>
            ) : (
              <WalletBtns
                value={walletAddress}
                setValue={handleChangeReceiverWallet}
                classN={inputClass}
                isValidAddress={isAddressValid}
                setInputClass={setInputClass}
                inputClass={inputClass}
              />
            )}

            {showAddressError && (
              <p className="fw-medium error-message address-error-message">
                {'We couldn’t find this address, please check and try again.'}
              </p>
            )}

            {isFromStarknet ? (
              <>
                {!starknetAddress ? (
                  <Button
                    onClick={() => setOpenModal(true)}
                    variant="outline-secondary"
                    className={`btn btnGradient w-100 mt-1 py-3 `}
                  >
                    {!!account
                      ? 'Connect Required Wallets to Transfer'
                      : 'Connect Wallet'}
                  </Button>
                ) : (
                  <button
                    onClick={openConfirm}
                    className={`btn btnSecondary w-100 margin-top ${
                      shouldDisableTransfer || loading || amountError
                        ? 'disabled-link'
                        : 'btnGradient'
                    }`}
                  >
                    Create Transfer
                  </button>
                )}
              </>
            ) : (
              <>
                {!account ? (
                  <Button
                    onClick={() => setOpenModal(true)}
                    variant="outline-secondary"
                    className={`btn btnGradient w-100 mt-1 py-3`}
                  >
                    {!!starknetAddress
                      ? 'Connect Required Wallets to Transfer'
                      : 'Connect Wallet'}
                  </Button>
                ) : (
                  <button
                    onClick={openConfirm}
                    className={`btn btnSecondary w-100 margin-top ${
                      shouldDisableTransfer || loading || amountError
                        ? 'disabled-link'
                        : 'btnGradient'
                    }`}
                  >
                    Create Transfer
                  </button>
                )}
              </>
            )}

            {/* {!connectedAccount ? (
              <Button
                onClick={() => setOpenModal(true)}
                variant="outline-secondary"
                className={`btn btnGradient w-100 mt-1 py-3`}
              >
                Connect Wallet
              </Button>
            ) : (
              <button
                onClick={openConfirm}
                className={`btn btnSecondary w-100 margin-top ${
                  shouldDisableTransfer || loading || amountError
                    ? "disabled-link"
                    : "btnGradient"
                }`}
              >
                Create Transfer
              </button>
            )} */}
          </>
        ) : (
          <Loader />
        )}
      </div>
    </motion.div>
  );
}

export default SendForm;
