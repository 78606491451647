import { ArrowUpIcon } from '@/Assets/Icons/arrow-up';
import { useConnectedIcon } from '@/hooks/useConnectedIcon';
import {
  NetworkTypes,
  useWalletConnectInteractor,
} from '@/providers/web3Provider';
import { useStarknetConnectedIcon } from '@/starknet/hooks/useConnectedIcon';
import {
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
  Root,
} from '@radix-ui/react-popover';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { useDisconnect, useAccount as useEVMAccount } from 'wagmi';
import '../../../styles.css';
import './styles.css';
import { useWalletStore } from '@/stores/wallet.store';
import { useAccount } from '@/starknet';
import { shortAddress } from '@/utils';
import { ArrowDownIcon } from '@/Assets/Icons/arrow-down';
import { PowerIcon } from '@/Assets/Icons/Power';

export function WalletPopover() {
  const [isOpen, setIsOpen] = useState(false);

  // Addresses
  const { address: EVMAddress } = useEVMAccount();
  const { address: starknetAddress } = useAccount();

  // Icons
  const EVMIcon = useConnectedIcon();
  const starknetIcon = useStarknetConnectedIcon();

  const { networkFrom } = useWalletStore();

  // Disconnect functions
  const { handleDeactivate } = useWalletConnectInteractor();
  const { disconnect } = useDisconnect();

  const walletAddress = EVMAddress || starknetAddress;

  const disconnectEVM = async () => {
    if (EVMAddress) {
      disconnect();
    }
  };

  const disconnectStarknet = async () => {
    if (starknetAddress) {
      handleDeactivate(NetworkTypes.STARKNET);
    }
  };

  return (
    <Root open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <button className="wallet-popover--trigger">
          <div className="d-flex align-items-center">
            {EVMAddress && (
              <div className="wallet-popover--trigger-icon z-3">
                <img src={EVMIcon} alt="" />
              </div>
            )}
            {starknetAddress && (
              <div className="wallet-popover--trigger-icon z-2">
                <img src={starknetIcon} alt="" />
              </div>
            )}
          </div>
          <div className="d-none d-lg-flex align-items-center gap-2">
            <div className="text-start">
              <div
                id="wallet-popover--address"
                style={{ width: 'max-content' }}
              >
                {walletAddress && shortAddress(walletAddress, 4)}
              </div>
            </div>
            {isOpen && <ArrowUpIcon width={18} height={18} />}
            {!isOpen && <ArrowDownIcon width={18} height={18} />}
          </div>
        </button>
      </PopoverTrigger>
      <AnimatePresence key={networkFrom}>
        {isOpen && (
          <PopoverPortal forceMount>
            <PopoverContent
              hideWhenDetached
              align="end"
              sideOffset={17}
              avoidCollisions={false}
              autoFocus={false}
              onOpenAutoFocus={(e) => e.preventDefault()}
              asChild
            >
              <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.9 }}
                className="wallet-popover--menu"
              >
                {EVMAddress && (
                  <div className="wallet-popover--menu-item">
                    <div className="wallet-popover--menu-item--icon">
                      <img src={EVMIcon} alt="" />
                    </div>
                    <div>
                      <div className="wallet-popover--menu-item--label">
                        EVM wallet
                      </div>
                      <div className="wallet-popover--menu-item--address">
                        {shortAddress(EVMAddress, 6)}
                      </div>
                    </div>
                    <button
                      className="wallet-popover--menu-item--action"
                      onClick={disconnectEVM}
                    >
                      <PowerIcon />
                    </button>
                  </div>
                )}
                {starknetAddress && (
                  <div className="wallet-popover--menu-item">
                    <div className="wallet-popover--menu-item--icon">
                      <img src={starknetIcon} alt="" />
                    </div>
                    <div>
                      <div className="wallet-popover--menu-item--label">
                        Starknet wallet
                      </div>
                      <div className="wallet-popover--menu-item--address">
                        {shortAddress(starknetAddress, 6)}
                      </div>
                    </div>
                    <button
                      className="wallet-popover--menu-item--action"
                      onClick={disconnectStarknet}
                    >
                      <PowerIcon />
                    </button>
                  </div>
                )}
              </motion.div>
            </PopoverContent>
          </PopoverPortal>
        )}
      </AnimatePresence>
    </Root>
  );
}
