import clsx from 'clsx';
import { animate } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { stage } from '../../Config/ApiConfig';
import { ROUTES } from '../../constants/routes.constants';
import styles from './styles.module.css';

interface AppModeSwitchProps {
  variant: 'Faucet' | 'Testnet';
}

export function AppModeSwitch({ variant }: AppModeSwitchProps) {
  const navigate = useNavigate();

  const handleSwitch = async (url: string) => {
    if (variant === 'Faucet') {
      const box = document.getElementById('faucet-form');

      if (box) {
        await animate(box, { opacity: 0 }, { duration: 0.5 });
      }
    } else {
      const box = document.getElementById('bridge-form');

      if (box) {
        await animate(box, { opacity: 0 }, { duration: 0.5 });
      }
    }

    if (stage === 'development') {
      navigate(url);
    } else {
      window.open(url, '_self');
    }
  };

  return (
    <div
      className={clsx(
        styles.container,
        variant === 'Faucet' ? styles._2 : styles._1
      )}
    >
      <button
        className={styles.switchItem}
        onClick={() => handleSwitch(ROUTES.SEND_PAGE)}
      >
        Testnet
      </button>
      <button
        className={styles.switchItem}
        onClick={() => handleSwitch(ROUTES.FAUCET_PAGE)}
      >
        Faucet
      </button>
    </div>
  );
}
