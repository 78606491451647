import { SVGAttributes } from 'react';

export function InfoIcon(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      {...props}
    >
      <circle cx="7" cy="7" r="7" fill="#F8CBAB" />
      <circle cx="7" cy="4" r="1" fill="#5D4A73" />
      <path
        d="M6.25781 10.7481V5.88013H7.77394V10.7481H6.25781Z"
        fill="#5D4A73"
      />
    </svg>
  );
}
