import { useAccount } from 'wagmi';
import metamaskIcon from '../Assets/images/wallets/metaMask.png';

export const useConnectedIcon = () => {
  const { connector } = useAccount();

  const icons: Record<string, string> = {
    'Trust Wallet': 'trust',
    MetaMask: 'metaMask',
    Rainbow: 'rainbow',
    Phantom: 'phantom',
    'Rabby Wallet': 'rabby',
    'Coinbase Wallet': 'coinbase',
    imToken: 'imToken',
    'SafePal Wallet': 'safepal',
    'Coin98 Wallet': 'coin98',
    TokenPocket: 'tokenPocket',
    Bitget: 'bitget',
    'OKX Wallet': 'okx',
    Zerion: 'zerion',
    WalletConnect: 'walletConnect',
    foxwallet: 'foxwallet',
  };

  return connector && icons[connector.name]
    ? require(`../Assets/images/wallets/${icons[connector.name]}.png`)
    : metamaskIcon;
};
