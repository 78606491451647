import {
  PopoverClose,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
  Root,
} from '@radix-ui/react-popover';
import SimpleBar from 'simplebar-react';
import curetDown from '../../../../Assets/Icons/arrow-down.svg';
import { BASE_URL } from '../../../../Config/ApiConfig';
import { LoaderSmall } from '../../../../components/LoaderSmall';
import { maxPlatformDecimals } from '../../../../constants/numbers';
import { useWalletStore } from '../../../../stores/wallet.store';
import { toFixed } from '../../../../utils/numbers';
import { useFaucetStore } from '../../store';
import './styles.css';
import clsx from 'clsx';

export function SelectNetwork() {
  const { networksList } = useWalletStore();
  const [
    selectedNetwork,
    selectedCurrency,
    isCurrencyLoading,
    setSelectedNetwork,
    setError,
  ] = useFaucetStore((s) => [
    s.selectedNetwork,
    s.selectedCurrency,
    s.isCurrencyLoading,
    s.setSelectedNetwork,
    s.setError,
  ]);

  return (
    <div className="faucet-form--network-group">
      <label className="faucet-form--label">Test Network</label>
      <div className="d-flex gap-2">
        <Root>
          <PopoverTrigger className="faucet--select-network">
            {!selectedNetwork && <LoaderSmall width={16} height={16} />}
            {selectedNetwork && (
              <>
                <div className="faucet--select-network-icon">
                  <img
                    src={BASE_URL + selectedNetwork?.network_image_url}
                    alt={selectedNetwork?.name}
                  />
                </div>
                {selectedNetwork?.name}
              </>
            )}
            <img
              width="11"
              height="8"
              src={curetDown}
              alt="open"
              className="faucet--select-network-trigger-icon"
            />
          </PopoverTrigger>
          <PopoverPortal>
            <PopoverContent
              sideOffset={8}
              avoidCollisions={false}
              side="bottom"
              className="faucet--select-network-menu"
            >
              <SimpleBar
                style={{
                  maxHeight: '264px',
                  paddingRight: '10px',
                  height: '100%',
                }}
              >
                <div className="d-flex flex-column gap-3">
                  {networksList.map((network) => (
                    <PopoverClose
                      key={network.id}
                      className={clsx(
                        'faucet--select-network-menu-item',
                        !network.active && 'disabled-network'
                      )}
                      onClick={() => {
                        setSelectedNetwork(network);
                        setError(undefined);
                      }}
                    >
                      <span className="faucet--select-network-icon">
                        <img
                          src={BASE_URL + network.network_image_url}
                          alt={network.name}
                        />
                      </span>
                      {network.name}
                      {!network.active && (
                        <span className="disabled-network-description">
                          Temporary not available
                        </span>
                      )}
                    </PopoverClose>
                  ))}
                </div>
              </SimpleBar>
            </PopoverContent>
          </PopoverPortal>
        </Root>
        <div className="faucet-form--network-amount">
          {isCurrencyLoading && <LoaderSmall width={16} height={16} />}
          {!isCurrencyLoading && selectedCurrency && (
            <>
              <div className="faucet-form--network-amount-icon">
                <img
                  src={BASE_URL + selectedCurrency.image_url}
                  alt={selectedCurrency.symbol}
                />
              </div>
              {toFixed(selectedCurrency.amount_to_claim, maxPlatformDecimals)}{' '}
              {selectedCurrency.symbol}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
