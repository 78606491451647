import { useMemo } from 'react';
// import { Price } from "../services/PriceService";
import { getPrice, usePriceUpdater } from '../stores/price_updater.store';
import { useWalletStore } from '../stores/wallet.store';
import { BASE_URL } from './../Config/ApiConfig';

export const useTransferInfo = () => {
  const {
    networkFrom,
    networkTo,
    networksList,
    currencyPair,
    amount,
    currencyFromList,
    currencyFromId,
    receiveAmount,
  } = useWalletStore();

  const { prices } = usePriceUpdater();

  const networkFromInfo = useMemo(() => {
    return networksList.find((el) => el.id === networkFrom);
  }, [networksList, networkFrom]);

  const networkToInfo = useMemo(() => {
    return networksList.find((el) => el.id === networkTo);
  }, [networksList, networkTo]);

  const netwTo = useMemo(() => {
    return networksList.find((el) => el.id === networkTo)?.name ?? '';
  }, [networksList, networkTo]);

  const networkToImage = useMemo(() => {
    return (
      BASE_URL +
      networksList.find((el) => el.id === networkTo)?.network_image_url
    );
  }, [networksList, networkTo]);

  const netwFrom = useMemo(() => {
    return networksList.find((el) => el.id === networkFrom)?.name ?? '';
  }, [networksList, networkFrom]);

  const networkFromImage = useMemo(() => {
    return (
      BASE_URL +
      networksList.find((el) => el.id === networkFrom)?.network_image_url
    );
  }, [networksList, networkFrom]);

  const currencyToInfo = useMemo(() => {
    return currencyPair[0];
  }, [currencyPair]);

  const currencyFromInfo = useMemo(() => {
    return currencyFromList.find((el) => el.id === currencyFromId);
  }, [currencyFromList, currencyFromId]);

  const amountUsd = useMemo(
    () =>
      (getPrice(currencyFromInfo?.symbol ?? '', prices).usd * +amount).toFixed(
        2
      ),
    [prices, currencyFromInfo, amount]
  );

  const receiveAmountUsd = useMemo(
    () =>
      (
        getPrice(currencyToInfo?.symbol ?? '', prices).usd * +receiveAmount
      ).toFixed(2),
    [currencyToInfo, receiveAmount, prices]
  );

  return {
    currencyToInfo,
    networkFromImage,
    netwFrom,
    netwTo,
    networkToImage,
    amountUsd,
    currencyFromInfo,
    receiveAmountUsd,
    networkFromInfo,
    networkToInfo,
  };
};
