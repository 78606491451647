import Bowser from "bowser";
export type {
  StarknetWindowObject,
  DisconnectOptions,
} from "get-starknet-core";

type StoreVersion = "chrome" | "firefox" | "edge";

const ssrSafeWindow = typeof window !== "undefined" ? window : null;

export const getStoreVersion= (): StoreVersion | null => {
  const browserName = Bowser.getParser(ssrSafeWindow?.navigator.userAgent ?? "")
    .getBrowserName()
    ?.toLowerCase();
  switch (browserName) {
    case "firefox":
      return "firefox";
    case "microsoft edge":
      return "edge";
    case "android browser":
    case "chrome":
    case "chromium":
    case "electron":
    case "opera": // opera is chromium based
    case "vivaldi": // vivaldi is chromium based
      return "chrome";
    default:
      return null;
  }
};
