import { create } from 'zustand';
import { INetwork } from '../../../types/apiTypes';
import { IFaucetCurrency } from '../../../types/faucet';

export interface FaucetStore {
  selectedNetwork?: INetwork;
  setSelectedNetwork: (network: INetwork) => void;

  selectedCurrency?: IFaucetCurrency;
  setSelectedCurrency: (currency: IFaucetCurrency) => void;

  isCurrencyLoading: boolean;
  setIsCurrencyLoading: (isLoading: boolean) => void;

  otherAddress: string;
  setOtherAddress: (address: string) => void;

  error?: string;
  setError: (error?: string) => void;

  balance: number;
  setBalance: (balance: number) => void;

  isBalanceLoading: boolean;
  setIsBalanceLoading: (isLoading: boolean) => void;
}

export const faucetStore = create<FaucetStore>((set) => ({
  selectedNetwork: undefined,
  setSelectedNetwork: (network) => set({ selectedNetwork: network }),

  selectedCurrency: undefined,
  setSelectedCurrency: (currency) => set({ selectedCurrency: currency }),

  isCurrencyLoading: false,
  setIsCurrencyLoading: (isLoading) => set({ isCurrencyLoading: isLoading }),

  otherAddress: '',
  setOtherAddress: (address) => set({ otherAddress: address }),

  error: undefined,
  setError: (error) => set({ error }),

  balance: 0,
  setBalance: (balance) => set({ balance }),

  isBalanceLoading: false,
  setIsBalanceLoading: (isLoading) => set({ isBalanceLoading: isLoading }),
}));

export const useFaucetStore = faucetStore;
