import axios, { AxiosRequestConfig } from "axios";
import { BASE_URL } from "../Config/ApiConfig";
import { usePriceUpdater } from "../stores/price_updater.store";
import { CurrencyPriceDto, GetCurrencyPricesResponse } from "../types/apiTypes";
import { ApiService } from "./CoreApi";
const minute = 60_000;

type IPrice = { usd: number };

export type PriceCache = {
  value: Record<string, IPrice>;
  timestamp: number;
};

export class PriceService {
  private readonly _api: ApiService;
  _cache: PriceCache = {
    value: {},
    timestamp: 0,
  };
  private _timeout = minute * 1;

  constructor() {
    const cache = this.getLocalCache();
    this.setCache(cache);
    this._api = new ApiService(BASE_URL);
  }

  async fetchPrice() {
    const cache: PriceCache | null = localStorage.getItem("price_cache")
      ? JSON.parse(localStorage.getItem("price_cache")!)
      : null;

    if (cache && cache.timestamp + this._timeout > Date.now()) {
      return;
    }

    const response = await this._fetchPrice();

    const valueToCache = this.getValueToCacheFromResponse(response);

    this.setCache(valueToCache);
  }

  private async _fetchPrice(symbol?: string | string[]) {
    const url = "/api/prices";
    const paramsBody: AxiosRequestConfig["params"] = {};

    if (symbol) {
      paramsBody.symbol = symbol;
    }

    const response: GetCurrencyPricesResponse = await this._api.get(url, {
      params: paramsBody,
    });

    return response.data;
  }

  private getLocalCache() {
    const localCache = localStorage.getItem("price_cache");

    return localCache ? JSON.parse(localCache) : this._cache;
  }

  private setCache(cache: PriceCache) {
    this._cache = cache;
    localStorage.setItem("price_cache", JSON.stringify(this._cache));
    this.updateStore();
  }

  private getValueToCacheFromResponse(
    apiResponse: CurrencyPriceDto[]
  ): PriceCache {
    const fetched_price: Record<string, IPrice> = {};

    for (const price of apiResponse) {
      fetched_price[price.symbol.toUpperCase()] = { usd: price.usd };
    }

    return { value: fetched_price, timestamp: Date.now() };
  }

  private updateStore() {
    const { setPrices } = usePriceUpdater.getState();
    setPrices(this._cache);
  }
}

// export const Price = new PriceService();
