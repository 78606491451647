import { useCallback, useRef } from "react";

export const useDebouncedCallback = <T extends any[]>(
  func: (...arg: T) => any,
  wait: number
) => {
  const timeout = useRef<NodeJS.Timeout>();

  return useCallback(
    (...arg: Parameters<typeof func>) => {
      const later = () => {
        clearTimeout(timeout.current);
        func(...arg);
      };

      clearTimeout(timeout.current);
      timeout.current = setTimeout(later, wait);
    },
    [func, wait]
  );
};
