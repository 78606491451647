const stagingUrl = 'https://testnet.backend.retrobridge.dev';
const prodUrl = 'https://testnet.backend.retrobridge.io';

export type STAGES = 'production' | 'staging' | 'development';

const stages: Record<STAGES, string> = {
  production: prodUrl,
  staging: stagingUrl,
  development: stagingUrl,
};

export const stage = (process.env.REACT_APP_STAGE as STAGES) || 'production';

export const BASE_URL = stages[stage];
