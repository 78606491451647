import { Chain } from 'viem';

export const arbitrumOne: Chain = {
  id: 42161,
  name: 'Arbitrum One',
  network: 'arbitrumOne',
  nativeCurrency: {
    decimals: 18,
    name: 'Arbitrum One',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://arbitrum.llamarpc.com'] },
    default: { http: ['https://arbitrum.llamarpc.com'] },
  },
  blockExplorers: {
    default: { name: 'Arbiscan', url: 'https://arbiscan.io/' },
    etherscan: { name: 'Arbiscan', url: 'https://arbiscan.io/' },
  },
  testnet: false,
};

export const mode: Chain = {
  id: 34443,
  name: 'Mode',
  network: 'mode',
  nativeCurrency: {
    decimals: 18,
    name: 'Mode',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://1rpc.io/mode'] },
    default: { http: ['https://1rpc.io/mode'] },
  },
  blockExplorers: {
    default: { name: 'Mode blockscout', url: 'https://explorer.mode.network/' },
  },
  testnet: false,
};

export const zetaChain: Chain = {
  id: 7000,
  name: 'Zeta Chain',
  network: 'zetaChain',
  nativeCurrency: {
    decimals: 18,
    name: 'Zeta Chain',
    symbol: 'ZETA',
  },
  rpcUrls: {
    public: { http: ['https://zetachain-evm.blockpi.network/v1/rpc/public'] },
    default: { http: ['https://zetachain-evm.blockpi.network/v1/rpc/public'] },
  },
  blockExplorers: {
    default: {
      name: 'Zeta explorer',
      url: 'https://zetachain.blockscout.com/',
    },
  },
  testnet: false,
};

export const blast: Chain = {
  id: 81457,
  name: 'Blast',
  network: 'blast',
  nativeCurrency: {
    decimals: 18,
    name: 'Blast',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://rpc.blast.io'] },
    default: { http: ['https://rpc.blast.io'] },
  },
  blockExplorers: {
    default: { name: 'Blastscan', url: 'https://blastscan.io/' },
  },
  testnet: false,
};

export const blastSepolia: Chain = {
  id: 168587773,
  name: 'Blast Sepolia',
  network: 'blastSepolia',
  nativeCurrency: {
    decimals: 18,
    name: 'Blast Sepolia',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://sepolia.blast.io'] },
    default: { http: ['https://sepolia.blast.io'] },
  },
  blockExplorers: {
    default: {
      name: 'Blast Sepolia Explorer',
      url: 'https://testnet.blastscan.io',
    },
  },
  testnet: true,
};

export const taikoHekla: Chain = {
  id: 167009,
  name: 'Taiko Hekla',
  network: 'taikoHekla',
  nativeCurrency: {
    decimals: 18,
    name: 'Taiko Hekla',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://rpc.hekla.taiko.xyz'] },
    default: { http: ['https://rpc.hekla.taiko.xyz'] },
  },
  blockExplorers: {
    default: {
      name: 'Taiko Hekla Explorer',
      url: 'https://hekla.taikoscan.network',
    },
  },
  testnet: true,
};

export const beraChainArtio: Chain = {
  id: 80085,
  name: 'BeraChain Artio',
  network: 'beraChainArtio',
  nativeCurrency: {
    decimals: 18,
    name: 'BeraChain Artio',
    symbol: 'BERA',
  },
  rpcUrls: {
    public: { http: ['https://rpc.ankr.com/berachain_testnet'] },
    default: { http: ['https://rpc.ankr.com/berachain_testnet'] },
  },
  blockExplorers: {
    default: {
      name: 'Artio Testnet Explorer',
      url: 'https://explorer.berachain.com/',
    },
  },
  testnet: true,
};

export const zircuitTestnet: Chain = {
  id: 48899,
  name: 'Zircuit Testnet',
  network: 'zircuitTestnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Zircuit Testnet',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://zircuit1.p2pify.com'] },
    default: { http: ['https://zircuit1.p2pify.com'] },
  },
  blockExplorers: {
    default: { name: 'Zircuit Explorer', url: 'https://explorer.zircuit.com' },
  },
  testnet: true,
};

export const lineaSepolia: Chain = {
  id: 59141,
  name: 'Linea Sepolia',
  network: 'lineaSepolia',
  nativeCurrency: {
    decimals: 18,
    name: 'Linea Sepolia',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://rpc.sepolia.linea.build'] },
    default: { http: ['https://rpc.sepolia.linea.build'] },
  },
  blockExplorers: {
    default: {
      name: 'Linea Sepolia Explorer',
      url: 'https://sepolia.lineascan.build',
    },
  },
  testnet: true,
};

export const polygonZkEVMTestnet: Chain = {
  id: 1442,
  name: 'Polygon zkEVM Testnet',
  network: 'polygonZkEVMTestnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Polygon zkEVM Testnet',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://endpoints.omniatech.io/v1/polygon-zkevm/testnet/public'] },
    default: { http: ['https://endpoints.omniatech.io/v1/polygon-zkevm/testnet/public'] },
  },
  blockExplorers: {
    default: {
      name: 'Polygon zkEVM Testnet Explorer',
      url: 'https://testnet-zkevm.polygonscan.com',
    },
  },
  testnet: true,
};

export const shardeumSphinxTestnet: Chain = {
  id: 8082,
  name: 'Shardeum Sphinx',
  network: 'shardeumSphinxTestnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Shardeum Sphinx',
    symbol: 'SHM',
  },
  rpcUrls: {
    public: { http: ['https://sphinx.shardeum.org'] },
    default: { http: ['https://sphinx.shardeum.org'] },
  },
  blockExplorers: {
    default: {
      name: 'Shardeum Sphinx Testnet Explorer',
      url: 'https://explorer-sphinx.shardeum.org',
    },
  },
  testnet: true,
};

export const morphHoleskyTestnet: Chain = {
  id: 2810,
  name: 'Morph Holesky',
  network: 'morphHoleskyTestnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Morph Holesky',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://rpc-holesky.morphl2.io'] },
    default: { http: ['https://rpc-holesky.morphl2.io'] },
  },
  blockExplorers: {
    default: {
      name: 'Morph Holesky Testnet Explorer',
      url: 'https://explorer-holesky.morphl2.io/',
    },
  },
  testnet: true,
};
