import { Provider, RpcProvider } from "starknet";

export const getStarknetProvider = (rpc_url: string, is_rpc: boolean) => {
  if (is_rpc) {
    const rpcProvider = new RpcProvider({
      nodeUrl: rpc_url,
    });
    return new Provider(rpcProvider);
  }
  return new Provider({
    sequencer: { baseUrl: rpc_url },
  });
};
