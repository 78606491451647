import { ethers } from 'ethers';
import { useCallback } from 'react';

import { useAccount as useEVMAccount } from 'wagmi';

import { Contract, uint256 } from 'starknet';
import { Erc20Contract } from '../evm/contracts/erc20Contract.service';
import { NetworkTypes } from '../providers/web3Provider';
import { balanceABIFragment, useAccount } from '../starknet';
import { useWalletStore } from '../stores/wallet.store';
import { ICurrency, INetwork } from '../types/apiTypes';
import { useEVMSigner } from './useSigner';
import { logInGroup } from '../utils';

export interface WithdrawParams {
  sendedCurrency?: ICurrency;
  sendedAmount?: number;
  network?: INetwork;
  walletReceiver?: string;
}

export const useWithdraw = () => {
  const { address: account } = useEVMAccount();
  const { account: starknetAccount } = useAccount();
  const signer = useEVMSigner();

  const { setCanceled } = useWalletStore();
  const withdrawErc20 = useCallback(
    async (params: Required<WithdrawParams>) => {
      const { sendedCurrency, sendedAmount, walletReceiver } = params;
      if (!signer) throw Error('Can not retrieve signer');

      const instance = new Erc20Contract(
        sendedCurrency.contract.address,
        signer
      );
      const tx = await instance.transfer(
        walletReceiver,
        ethers.utils.parseUnits(`${sendedAmount}`, sendedCurrency.decimals)
      );

      const res = await tx?.wait();
      return res;
    },
    [signer]
  );

  const withdrawNative = useCallback(
    async (params: Required<WithdrawParams>) => {
      const { sendedAmount, walletReceiver } = params;
      if (!account) {
        throw new Error('No address');
      }
      if (!signer) throw Error('Can not retrieve signer');

      const withdrawedAmount = ethers.utils.parseEther(`${sendedAmount}`);

      const otpions = {
        from: account,
        to: walletReceiver,
        value: withdrawedAmount,
      };
      const tx = await signer.sendTransaction(otpions);
      return await tx.wait(1);
    },
    [account, signer]
  );

  const withdrawEvm = useCallback(
    async (params: Required<WithdrawParams>) => {
      const { sendedCurrency, walletReceiver, sendedAmount, network } = params;

      if (sendedCurrency.contract.address === ethers.constants.AddressZero) {
        return await withdrawNative({
          walletReceiver,
          sendedAmount,
          sendedCurrency,
          network,
        });
      } else {
        return await withdrawErc20({
          walletReceiver,
          sendedAmount,
          sendedCurrency,
          network,
        });
      }
    },
    [withdrawNative, withdrawErc20]
  );

  const withdrawStarknet = useCallback(
    async (params: Required<WithdrawParams>) => {
      const { sendedCurrency, walletReceiver, sendedAmount } = params;

      if (!starknetAccount) {
        throw new Error('No starknet account');
      }

      const erc20instance = new Contract(
        balanceABIFragment,
        sendedCurrency.contract.address,
        starknetAccount
      );

      const wei = ethers.utils.parseUnits(
        sendedAmount.toString(),
        sendedCurrency.decimals
      );

      const amountBn = uint256.bnToUint256(wei.toBigInt());
      const contractTx = erc20instance.populate('transfer', {
        recipient: walletReceiver,
        amount: amountBn,
      });

      const sendedTx = await starknetAccount.execute(contractTx);
      return await starknetAccount.waitForTransaction(
        sendedTx.transaction_hash
      );
    },
    [starknetAccount]
  );

  const withdraw = useCallback(
    async (params: WithdrawParams) => {
      const { sendedCurrency, walletReceiver, sendedAmount, network } = params;

      if (!sendedCurrency) {
        throw Error('No token provided');
      }
      if (!sendedAmount || +sendedAmount <= 0 || isNaN(+sendedAmount)) {
        throw Error('Invalid amount provided');
      }
      if (!walletReceiver) {
        throw Error('No address provided');
      }
      if (!sendedCurrency) {
        throw Error('No token provided');
      }
      if (!network) {
        throw Error("Can't retrieve network");
      }

      try {
        if (
          network.network_type === NetworkTypes.EVM ||
          network.network_type === NetworkTypes.ZK_SYNC_ERA
        ) {
          return await withdrawEvm({
            sendedCurrency,
            walletReceiver,
            sendedAmount,
            network,
          });
        }
        if (network.network_type === NetworkTypes.STARKNET) {
          return await withdrawStarknet({
            sendedCurrency,
            walletReceiver,
            sendedAmount,
            network,
          });
        }
      } catch (error: any) {
        logInGroup('useWithdraw', error);
        const parsedError = JSON.parse(JSON.stringify(error, null, 2));
        logInGroup('useWithdraw', parsedError);
        if (
          parsedError.code === 'TRANSACTION_REPLACED' &&
          parsedError?.replacement?.confirmations > 1
        ) {
          return true;
        }
        setCanceled(true);
        if (
          error.code === 'ACTION_REJECTED' ||
          error.message === 'User abort'
        ) {
          throw Error('ACTION_REJECTED');
        }
        throw Error("Can't create transaction");
      }
    },
    [withdrawEvm, withdrawStarknet, setCanceled]
  );

  return {
    withdraw,
  };
};
