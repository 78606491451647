import { Stack } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import SendBg from '../../Assets/images/send-bg-png.png';
import SendBgSmall from '../../Assets/images/send-bg-small.svg';
import Header from '../../components/Header/header';
import { Loader } from '../../components/Loader';
import Footer from '../../components/Footer';
import { useIsPageLoaded } from '../../hooks/useIsPageLoaded';
import './ProgressPage.css';
import ProgressForm from './form';
import { Helmet } from 'react-helmet';
import { PhaseTab } from '../../components/PhaseTab';
import { useMemo, useState } from 'react';
import { ConnectWalletModalWithDisabled } from '../../components/ConnectWalletModalWithDisabled';
import { useWalletStore } from '../../stores/wallet.store';

const ProgressPage = () => {
  const pageLoaded = useIsPageLoaded(SendBg);
  const [openModal, setOpenModal] = useState(false);
  const { networkFrom, networksList } = useWalletStore();
  const selectedNetwork = useMemo(() => {
    return networksList.find((network) => network.id === networkFrom);
  }, [networkFrom, networksList]);

  return (
    <div className="page-background-color">
      <Helmet>
        <title>App | Retro Bridge</title>
      </Helmet>
      <Container
        fluid
        className="mainSend  min-vh-100 w-100 d-flex flex-1  justify-content-center flex-column align-items-center page-padding-bottom pt-2 position-relative"
      >
        {pageLoaded ? (
          <>
            <img src={SendBg} alt="" className="send-bg-img" />
            <img src={SendBgSmall} alt="" className="send-bg-img-mobile" />
            <Header setOpenModal={setOpenModal} />
            <Stack className="mw-100 col-xs-12 col-sm-12 col-md-6 mx-auto col-lg-5 col-xl-4 w-100 flex align-items-center">
              <div className="status-form-mt">
                <ProgressForm />
              </div>
            </Stack>
            <div className="progress-page-footer-container">
              <Footer />
            </div>
          </>
        ) : (
          <Loader />
        )}
        <ConnectWalletModalWithDisabled
          setOpenModal={setOpenModal}
          openModal={openModal}
          networkType={selectedNetwork?.network_type}
          onlySelectedNetworkType
        />
      </Container>
    </div>
  );
};

export default ProgressPage;
