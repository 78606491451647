import { SVGAttributes } from "react";

export function Timer24(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      {...props}
    >
      <path
        d="M17.3575 9.4188C16.9311 9.38172 16.5826 9.68945 16.5456 10.1158C16.312 13.5564 13.7612 16.4149 10.3614 17.034C7.41768 17.5679 4.47763 16.3407 2.80926 13.9457L4.40719 14.1755C4.79277 14.2534 5.18206 13.942 5.21913 13.5564C5.25621 13.13 4.98556 12.7444 4.56291 12.7074L1.39299 12.2402C0.966627 12.2031 0.618122 12.4738 0.543971 12.8964L0.0805334 16.0664C0.0434584 16.4927 0.314106 16.8783 0.736762 16.9154C1.23357 16.9562 1.5487 16.6447 1.62656 16.2591L1.7934 15.0987C5.31182 19.4661 10.0908 18.6171 10.6321 18.5393C14.6918 17.8052 17.7839 14.3646 18.0545 10.2307C18.0916 9.80438 17.7839 9.45587 17.3575 9.4188Z"
        fill="white"
      />
      <path
        d="M17.4728 2.30808C17.0464 2.27101 16.6979 2.54166 16.6238 2.96431L16.431 4.32497C14.4141 1.49985 10.9142 0.0131401 7.38466 0.643416C3.32865 1.3775 0.273668 4.78099 0.00301998 8.95564C-0.0340551 9.382 0.273668 9.73051 0.737106 9.76758C1.12269 9.76758 1.43412 9.45986 1.47119 9.0335C1.70476 5.59293 4.25553 2.73445 7.65531 2.11529C10.6473 1.57771 13.5799 2.85309 15.2334 5.25184L13.8394 5.05164C13.4131 5.01456 13.0275 5.28521 12.9904 5.70787C12.9533 6.09345 13.224 6.48273 13.6466 6.51981L16.8165 6.98325C17.3393 7.00549 17.6285 6.7126 17.6656 6.32702L18.129 3.1571C18.1698 2.73074 17.8991 2.34516 17.4728 2.30808Z"
        fill="white"
      />
      <path
        d="M8.26443 11.7084L4.8102 11.7214V10.9364L6.40647 9.60834C6.9233 9.18311 7.13919 8.88217 7.13919 8.4831C7.13919 7.99245 6.89713 7.73076 6.46535 7.73076C6.01395 7.73076 5.75226 8.03824 5.75226 8.58123H4.69899C4.69899 7.49525 5.39245 6.7887 6.45881 6.7887C7.56442 6.7887 8.22517 7.38403 8.22517 8.41768C8.22517 9.07843 7.82611 9.61488 7.22423 10.1186L6.49806 10.727H8.26443V11.7084ZM12.5984 10.8644H12.003V11.7214H11.0021V10.8644H8.5946V9.93545L10.6815 6.88029H12.003V9.93545H12.5984V10.8644ZM9.71984 9.93545H11.0021V8.05132L9.71984 9.93545Z"
        fill="white"
      />
    </svg>
  );
}
