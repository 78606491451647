import { useEffect, useMemo } from 'react';
import Form from 'react-bootstrap/Form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import arrowDown from '../../Assets/Icons/arrowDown.svg';
import { BASE_URL } from '../../Config/ApiConfig';
import { Loader } from '../../components/Loader';
import { SEND_PAGE, ROUTES } from '../../constants/routes.constants';
// import { Price } from "../../services/PriceService";
import { api } from '../../services/api';
import { getPrice, usePriceUpdater } from '../../stores/price_updater.store';
import { useWalletStore } from '../../stores/wallet.store';
import { OrderStatuses } from '../../types/enums';
import { exponentNumberToString } from '../../utils/numbers';
import './ProgressPage.css';
import { Progress } from './components/Progress';
import StatusForm from './components/StatusForm';
import { NetworkInfo } from './components/networkInfo';
import plusOutline from '../../Assets/Icons/plus-outline.svg';
import discord from '../../Assets/Icons/discord.svg';

function ProgressForm() {
  const { currentOrder, canceled } = useWalletStore();

  const { prices } = usePriceUpdater();

  const { orderId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (orderId) {
      api.getOrderById(orderId).then((data) => {
        if (
          data?.status === OrderStatuses.COMPLETE ||
          data?.status === OrderStatuses.REJECTED ||
          data?.status === OrderStatuses.EXPIRED
        ) {
          clearInterval(id);
        }
      });

      const id = setInterval(async () => await api.getOrderById(orderId), 2000);

      return () => clearInterval(id);
    } else {
      navigate(SEND_PAGE);
    }
  }, [orderId, navigate]);

  const amountInUsd = useMemo(() => {
    if (!currentOrder) {
      return 0;
    }
    return (
      getPrice(currentOrder.currency_in.symbol, prices).usd *
      currentOrder.amount_in
    );
  }, [currentOrder, prices]);

  const amountOutUsd = useMemo(() => {
    if (!currentOrder) {
      return 0;
    }
    return (
      getPrice(currentOrder.currency_out.symbol, prices).usd *
      currentOrder.amount_out
    );
  }, [currentOrder, prices]);

  if (!currentOrder) {
    return (
      <div className=" formBg defaultRadius formContainer  gradient-border-mask mt-0">
        <Form className="transitionHeight formBody text-light bg-opacity-25 h-100   defaultRadius align-items-center  ">
          <div className="transactionInfoDableDiv">
            <Loader />
          </div>
        </Form>
      </div>
    );
  }

  if (
    currentOrder.status === OrderStatuses.COMPLETE ||
    currentOrder.status === OrderStatuses.REJECTED ||
    currentOrder.status === OrderStatuses.EXPIRED ||
    canceled
  ) {
    return <StatusForm />;
  }

  return (
    <div className=" formBg defaultRadius formContainer  gradient-border-mask mt-0">
      <Form className="transitionHeight formBody text-light bg-opacity-25 h-100   defaultRadius align-items-center ">
        <div className="transferInfoTitle">Transfer Status</div>

        <div className="transactionInfoDableDiv">
          <div className="infoDiv info-div-padding">
            <NetworkInfo
              netwSrc={
                BASE_URL +
                currentOrder.currency_in.contract.network.network_image_url
              }
              netw={currentOrder.currency_in.contract.network.name ?? ''}
              wallet={currentOrder.wallet_sender!}
              amount={`${currentOrder.amount_in}`}
              amountUsd={`${amountInUsd}`}
              coinSimbol={currentOrder.currency_in.symbol ?? ''}
            />
          </div>
          <div className="circle circle-bg">
            <div className="transactionInfoDableDivCircle">
              <img className="arrowDownIcon" src={arrowDown} alt="arrow" />
            </div>
          </div>

          <div className="infoDiv info-div-padding">
            <NetworkInfo
              netwSrc={
                BASE_URL +
                currentOrder.currency_out.contract.network.network_image_url
              }
              netw={currentOrder.currency_out.contract.network.name ?? ''}
              wallet={currentOrder.wallet_receiver ?? ''}
              amount={exponentNumberToString(currentOrder.amount_out)}
              amountUsd={`${amountOutUsd}`}
              coinSimbol={currentOrder.currency_out.symbol}
            />
          </div>
        </div>

        <div className="separator ms-auto me-auto " />

        <div className="progressStatusText">Transfer Status</div>
        <div className="progressStatusText progressStatusText-regular textSecondary">
          Assets will be sent as soon as the transfer is confirmed
        </div>
        <Progress order={currentOrder} />
        <div className="progressActionWrapper">
          <Link
            to={ROUTES.SEND_PAGE}
            className="d-flex justify-content-center align-items-center btn btnGradient w-100"
          >
            <img src={plusOutline} alt="" />
            Create Another Transfer
          </Link>
        </div>
        <div className="progressActionWrapper mt-3">
          <a
            href="http://discord.gg/retrobridge"
            target="_blank"
            rel="noopener noreferrer"
            className="progressAction"
          >
            <img src={discord} alt="" />
            Support
          </a>
        </div>
      </Form>
    </div>
  );
}

export default ProgressForm;
