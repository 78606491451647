import network1 from '../../../../Assets/images/Faucet/Starknet.png';
import network2 from '../../../../Assets/images/Faucet/Avalanche.png';
import network3 from '../../../../Assets/images/Faucet/Solana.png';
import network4 from '../../../../Assets/images/Faucet/Shardeum.png';
import network5 from '../../../../Assets/images/Faucet/Bera.png';
import network6 from '../../../../Assets/images/Faucet/Ethereum.png';
import network7 from '../../../../Assets/images/Faucet/Optimism.png';
import network8 from '../../../../Assets/images/Faucet/Arbitrum.png';
import './styles.css';

export function FaucetBg() {
  return (
    <div className="faucet-bg">
      <img src={network1} className="faucet-bg--img" alt="" />
      <img src={network2} className="faucet-bg--img" alt="" />
      <img src={network3} className="faucet-bg--img" alt="" />
      <img src={network4} className="faucet-bg--img" alt="" />
      <img src={network5} className="faucet-bg--img" alt="" />
      <img src={network6} className="faucet-bg--img" alt="" />
      <img src={network7} className="faucet-bg--img" alt="" />
      <img src={network8} className="faucet-bg--img" alt="" />
    </div>
  );
}
