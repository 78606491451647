import copy from '../../../Assets/Icons/copy.svg';
import { notify } from '../../../services/toast';
import { shortAddress } from '../../../utils';
export const Wallet = ({ wallet }: { wallet: string }) => {
  const copyToClipboard = () => {
    window.navigator.clipboard.writeText(wallet);
    notify('Copied');
  };
  return (
    <div
      onClick={copyToClipboard}
      className="d-flex align-items-center tableWalletSell  "
    >
      <img src={copy} className="copyIcon" alt="coin" />

      <div className="ps-1 ms-2  me-2 tableFont">{`${shortAddress(
        wallet
      )}`}</div>
    </div>
  );
};
