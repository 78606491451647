import { BroadcastChannel, createLeaderElection } from 'broadcast-channel';
import { useEffect, useRef } from 'react';
import { stage } from '../Config/ApiConfig';
import { logInGroup } from '../utils';

interface UseSSEBroadcastProps {
  url: string | null;
  onError?: (event: Event) => void;
  onMessage?: (message: MessageEvent) => void;
  onOpen?: (event: Event) => void;
  channel?: string;
  listeners?: Record<string, (data: any) => void>;
}

const isDevelopment = stage === 'staging';

export const useSSEBroadcast = ({
  url,
  onError,
  onMessage,
  onOpen,
  channel = 'RetroBridge',
  listeners = {},
}: UseSSEBroadcastProps) => {
  const eventSource = useRef<EventSource | null>(null);
  const broadcast = useRef(new BroadcastChannel(channel));
  const isLeader = useRef(false);

  useEffect(() => {
    const broadcastChannel = new BroadcastChannel(channel);
    const elector = createLeaderElection(broadcastChannel);

    elector.awaitLeadership().then(() => {
      isDevelopment &&
        logInGroup(
          'SSE Leader Election',
          'I am the leader tab for',
          channel,
          eventSource.current
        );
      if (!eventSource.current) {
        createChannels();
      }

      isLeader.current = true;
      broadcast.current = broadcastChannel;
    });
  }, [channel]);

  useEffect(() => {
    broadcast.current.onmessage = (e: any) => {
      isDevelopment && logInGroup('SSE Broadcast', 'broadcast', e, listeners[e.event]);

      onMessage && onMessage(e.data);
      listeners[e.event]?.(e.data);
    };
  }, [listeners, onMessage]);

  useEffect(() => {
    if (eventSource.current) {
      eventSource.current.close();

      isDevelopment && logInGroup('SSE Close connection', 'Closed event source');
    }

    if (url && isLeader.current) {
      createChannels();

      isDevelopment && logInGroup('SSE Recreated event source', 'Recreated event source for', url);
    }
  }, [url]);

  useEffect(() => {
    const cleanup = () => {
      eventSource.current?.close();
    };

    window.addEventListener('beforeunload', cleanup);

    return () => {
      window.removeEventListener('beforeunload', cleanup);
    };
  }, []);

  const createChannels = () => {
    if (!url) return;

    eventSource.current = new EventSource(url, {
      withCredentials: true,
    });
    isDevelopment && logInGroup('SSE EventSource', 'eventSource', eventSource.current);

    eventSource.current.onopen = (e) => {
      isDevelopment && logInGroup('SSE Connect', 'Connected to event source at', url);
      onOpen && onOpen(e);
    };

    Object.entries(listeners).forEach(([event, listener]) => {
      eventSource.current?.addEventListener(event, (e) => {
        isDevelopment && logInGroup(`SSE ${event}`, event, e.data);
        broadcast.current.postMessage({ event, data: JSON.parse(e.data) });
      });
    });

    eventSource.current.onmessage = (e) => {
      isDevelopment && logInGroup('SSE Message Event', 'event', e.data);
      broadcast.current.postMessage(e.data);
      onMessage && onMessage(e);
    };

    eventSource.current.onerror = function (e) {
      isDevelopment && logInGroup('SSE Error', 'error', e, eventSource.current);

      if (eventSource.current?.readyState === EventSource.CLOSED) {
        isDevelopment &&
          logInGroup('SSE Reconnect', 'Reconnecting to event source at', url);
        createChannels();
        return;
      }

      onError && onError(e);
    };
  };
};
