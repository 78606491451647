import { useAccount } from "../";
import { starknetWallets } from "../constants/wallets.constant";

export const useStarknetConnectedIcon = () => {
  const { connector: starknetConnector } = useAccount();


  const starknetWallet = starknetWallets.find( item => item.id === starknetConnector?.id)

  return starknetWallet?.icon || "";
};
