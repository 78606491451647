import '../Explorer.css';

function AmountBlock({
  currencyInIcon,
  currencyOutIcon,
  amountIn,
  amountOut,
}: {
  currencyInIcon: string;
  currencyOutIcon: string;
  amountIn: string;
  amountOut: string;
}) {
  return (
    <div
      className={`d-flex justify-content-between   text-light w-100 text-nowrap`}
    >
      <div className="amountBlockBtnDivContainer">
        <div className="transferinfoSubyitle mb-2">Amount Sent</div>
        <div
          className={`leftAmountBlockBtnDiv d-flex  align-items-center px-2 px-md-4`}
        >
          <div className="d-flex imgDiv20 me-2">
            <img
              src={currencyInIcon}
              alt="network icon networkSmallIcon"
              className="coinImg"
            />
          </div>

          <div className="text-light">{amountIn}</div>
        </div>
      </div>
      <div className="amountBlockBtnDivContainer">
        <div className="transferinfoSubyitle mb-2">Amount Received</div>
        <div className={`rightAmountBlockDiv text-light`}>
          <div className="d-flex imgDiv20 me-2">
            <img
              src={currencyOutIcon}
              alt="network icon networkSmallIcon"
              className="coinImg"
            />
          </div>

          <div className="text-light">{amountOut}</div>
        </div>
      </div>
    </div>
  );
}

export default AmountBlock;
