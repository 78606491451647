import { useCallback, useState } from 'react';
import { default as Button } from 'react-bootstrap/Button';
import { useAccount as useEVMAccount } from 'wagmi';
import { useConnectedIcon } from '../../../hooks/useConnectedIcon';
import '../../../pages/SendPage/SendPage.css';
import { WalletButtonsProps } from '../../../pages/SendPage/components/walletBtns';
import WalletAddressInputl from '../../../pages/SendPage/components/walletIAddressInput';
import { shortAddress } from '../../../utils';
import { starknetWallets } from '../../constants/wallets.constant';
import { useAccount } from '../../hooks';
import { ConnectStarkWalletModal } from '../ConnectStarkWalletModal/ConnectStarkWalletModal';

export const ToStarknetWalletButtons = ({
  inputClass,
  setInputClass,
  ...props
}: WalletButtonsProps) => {
  const { connector: starknetConnector, address: starknetAddress } =
    useAccount();
  const { address: connectedEthAccount, isConnected: connectedEthWallet } =
    useEVMAccount();
  const icon = useConnectedIcon();

  const [openModal, setOpenModal] = useState(false);

  const connectedAccount = starknetAddress;

  const connectedWallet = starknetWallets.find(
    (item) => item.id === starknetConnector?.id
  );

  const anyConnectedAccount = connectedAccount || connectedEthAccount;
  const anyConnectedWallet = connectedWallet || { icon };

  const onClickConnected = useCallback(() => {
    if (!connectedAccount) {
      return;
    }
    setInputClass('hidden');
  }, [connectedAccount]);

  const onClickInputAddress = () => {
    setInputClass('showInput');
  };

  const onClickConnectStarknetHandler = () => {
    setOpenModal(true);
    setInputClass('hidden');
  };

  // useEffect(() => {
  //   if (!connectedEthAccount) {
  //     setAmount("");
  //   }
  // }, [connectedEthAccount]);

  if (!anyConnectedAccount || !anyConnectedWallet || !connectedEthWallet) {
    return (
      <div
        className={`${'d-none'} d-flex justify-content-between inputGroup mb-3 text-white w-100 text-nowrap`}
      />
    );
  }

  return (
    <>
      <div
        className={`d-flex justify-content-between inputGroup text-white w-100 text-nowrap select-receiving-wallet-wrapper`}
      >
        {!!starknetConnector ? (
          <div
            onClick={onClickConnected}
            className={` leftWalletBtnDiv ${
              inputClass === 'hidden' ? '' : 'btn'
            } d-flex  align-items-center ${
              inputClass === 'hidden' && 'gradient'
            } leftWalletBtnDiv_connected`}
          >
            <div className="d-flex coinBtn wallet-icon-size me-2">
              <img
                width="13px"
                height="13px"
                src={anyConnectedWallet.icon}
                alt="network icon networkSmallIcon"
              />
            </div>
            <div className="d-flex flex-column">
              <div
                className="text-white fw-bold wallet-btns-text-size connected-wallet-text"
              >
                Connected Wallet
              </div>
              <div className=" fw-medium blanco address connected-address text-white">{`${shortAddress(
                anyConnectedAccount
              )}`}</div>
            </div>
          </div>
        ) : (
          <Button
            onClick={onClickConnectStarknetHandler}
            variant="outline-secondary"
            className={`btn leftWalletBtnDiv wallet-btns-text-size connect-starknet-btn ${
              inputClass === 'hidden' && 'active'
            }`}
          >
            Connect StarkNet Wallet
          </Button>
        )}
        <div
          onClick={onClickInputAddress}
          className={`btn rightWalletBtnDiv text-white wallet-btns-text-size ${
            inputClass !== 'hidden' && 'gradient'
          }`}
        >
          Select Other Address
        </div>
      </div>
      {inputClass !== 'hidden' && <WalletAddressInputl {...props} />}
      <ConnectStarkWalletModal
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
    </>
  );
};
