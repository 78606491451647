import { SVGAttributes, useId } from 'react';

export function FaucetIcon(props: SVGAttributes<SVGElement>) {
  const id = useId();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      {...props}
    >
      <path
        d="M11.2308 2.5V4.03846H5.38463H2.92309H2.30771V2.5H11.2308Z"
        fill="white"
      />
      <path
        d="M5.38463 4.44341V5.57694H3.8154H2.9231V4.03848H5.38463V4.44341Z"
        fill="white"
      />
      <path
        d="M9.69231 15.4627V16.3858H1.38461V15.4627H2.30769H3.84615H6H9.69231Z"
        fill="white"
      />
      <path
        d="M6.00002 15.4231C5.15685 15.4231 3.09463 15.4231 2.30771 15.4231V5.57693C3.63359 5.57693 4.68343 5.57693 6.00002 5.57693C6.00002 7.58858 6.00002 13.418 6.00002 15.4231Z"
        fill="white"
      />
      <path d="M13.3846 9.26926H11.2308V10.5H13.3846V9.26926Z" fill="white" />
      <path
        d="M13.3846 8.59231V9.26923H11.2308H6V7.11539H11.9077C12.7234 7.11539 13.3846 7.77662 13.3846 8.59231Z"
        fill="white"
      />
      <path
        d="M6.00001 15.673C5.83008 15.673 5.69232 15.5177 5.69232 15.3262V9.77752C5.69232 9.58602 5.83008 9.43073 6.00001 9.43073C6.16995 9.43073 6.30771 9.58602 6.30771 9.77752V15.3262C6.30771 15.5177 6.16995 15.673 6.00001 15.673Z"
        fill="white"
      />
      <path
        d="M6.00001 7.4231C5.83007 7.4231 5.69232 7.28535 5.69232 7.11541V5.88464H5.38463C5.21469 5.88464 5.07693 5.74689 5.07693 5.57695C5.07693 5.40701 5.21469 5.26926 5.38463 5.26926H6.00001C6.16995 5.26926 6.3077 5.40701 6.3077 5.57695V7.11541C6.3077 7.28535 6.16995 7.4231 6.00001 7.4231Z"
        fill="white"
      />
      <path
        d="M2.30769 15.673C2.13775 15.673 2 15.5177 2 15.3262V5.61605C2 5.42451 2.13775 5.26926 2.30769 5.26926H2.92308C3.09302 5.26926 3.23077 5.42451 3.23077 5.61605C3.23077 5.80758 3.09302 5.96284 2.92308 5.96284H2.61538V15.3262C2.61538 15.5177 2.47763 15.673 2.30769 15.673Z"
        fill="white"
      />
      <path
        d="M13.3846 9.57694C13.2147 9.57694 13.0769 9.43915 13.0769 9.26925V8.59232C13.0769 7.94762 12.5524 7.42309 11.9077 7.42309H6.30771V8.96156H11.2308C11.4007 8.96156 11.5385 9.09934 11.5385 9.26925C11.5385 9.43915 11.4007 9.57694 11.2308 9.57694H6.00001C5.83008 9.57694 5.69232 9.43915 5.69232 9.26925V7.1154C5.69232 6.94546 5.83008 6.80771 6.00001 6.80771H11.9077C12.8917 6.80771 13.6923 7.60829 13.6923 8.59232V9.26925C13.6923 9.43915 13.5545 9.57694 13.3846 9.57694Z"
        fill="white"
      />
      <path
        d="M13.3846 10.8077H11.2308C11.0609 10.8077 10.9231 10.6699 10.9231 10.5V9.26924C10.9231 9.09933 11.0609 8.96155 11.2308 8.96155H13.3846C13.5545 8.96155 13.6923 9.09933 13.6923 9.26924V10.5C13.6923 10.6699 13.5545 10.8077 13.3846 10.8077ZM11.5385 10.1923H13.0769V9.57693H11.5385V10.1923Z"
        fill="white"
      />
      <path
        d="M6.00002 7.42309H3.23079C3.06085 7.42309 2.9231 7.28534 2.9231 7.1154C2.9231 6.94546 3.06085 6.80771 3.23079 6.80771H6.00002C6.16996 6.80771 6.30771 6.94546 6.30771 7.1154C6.30771 7.28534 6.16996 7.42309 6.00002 7.42309Z"
        fill="white"
      />
      <path
        d="M3.84617 14.5C3.67624 14.5 3.53848 14.3622 3.53848 14.1923V9.88463C3.53848 9.71472 3.67624 9.57693 3.84617 9.57693C4.01611 9.57693 4.15387 9.71472 4.15387 9.88463V14.1923C4.15387 14.3622 4.01611 14.5 3.84617 14.5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 13.0411C11 13.7836 11.6037 14.3876 12.3458 14.3876C13.0883 14.3876 13.6924 13.7836 13.6923 13.0411C13.6923 12.5779 13.3616 12.1772 13.0417 11.7896L13.0417 11.7895L13.041 11.7886C12.9033 11.6218 12.7732 11.4641 12.6805 11.3155C12.6077 11.1987 12.4798 11.1278 12.3422 11.1278H12.342C12.2044 11.1278 12.0765 11.1989 12.0037 11.3157C11.9136 11.4605 11.7862 11.617 11.6513 11.7826C11.3311 12.1756 11 12.5821 11 13.0411Z"
        fill={`url(#${id})`}
      />
      <path
        d="M11.2308 4.03845H2.30769C2.13775 4.03845 2 3.92038 2 3.77472V2.45605C2 2.3104 2.13775 2.19232 2.30769 2.19232H11.2308C11.4007 2.19232 11.5385 2.3104 11.5385 2.45605V3.77472C11.5385 3.92038 11.4007 4.03845 11.2308 4.03845ZM2.61538 3.51099H10.9231V2.71979H2.61538V3.51099Z"
        fill="white"
      />
      <path
        d="M9.69232 16.6935H1.38463C1.21469 16.6935 1.07693 16.565 1.07693 16.4066V15.5461C1.07693 15.3877 1.21469 15.2592 1.38463 15.2592H9.69232C9.86223 15.2592 10 15.3877 10 15.5461V16.4066C10 16.565 9.86223 16.6935 9.69232 16.6935ZM1.69232 16.1198H9.38463V15.8329H1.69232V16.1198Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id={id}
          x1="12.3462"
          y1="11.1278"
          x2="12.3462"
          y2="14.3876"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E5C4FF" />
          <stop offset="1" stopColor="#A89AFF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
