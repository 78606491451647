import clsx from 'clsx';
import { ImgHTMLAttributes } from 'react';
import { useImgPreloader } from '../../hooks/useImagePreload';
import './styles.css';

interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  withSmoothLoading?: boolean;
}

export function Image({ withSmoothLoading, className, ...props }: ImageProps) {
  const { imgsPreloaded } = useImgPreloader(
    props.src && withSmoothLoading ? [props.src] : []
  );

  return (
    <img
      alt=""
      className={clsx(
        className,
        withSmoothLoading && 'with-smooth-loading',
        withSmoothLoading && imgsPreloaded && 'loaded'
      )}
      {...props}
    />
  );
}
