import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { useAccount as useEVMAccount, useSignMessage } from 'wagmi';

import { AxiosError } from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { constants } from 'starknet';
import { useDisconnect } from 'wagmi';
import { useNotify } from '../hooks/useToast';
import { useTransferInfo } from '../hooks/useTransferInfo';
import { api } from '../services/api';
import { Connector, useAccount, useConnectors, useNetwork } from '../starknet';
import { WrongAccountChainIdError } from '../starknet/errors';
import { AppTypedData } from '../starknet/types/app_typed_data';
import { getAppStarknetChanId } from '../starknet/utils/getAppStarknetChainId';
import { useSignStore } from '../stores/sign.store';
import { useWalletStore } from '../stores/wallet.store';
// import useAppStore from '../../stores/appStore';
interface PropsTypes {
  children: React.ReactNode;
}
export enum NetworkTypes {
  EVM = 'EVM',
  ZK_SYNC_ERA = 'ZK_SYNC_ERA',
  STARKNET = 'STARKNET',
  SOLANA = 'SOLANA',
  TON = 'TON',
}

const starknetNetworks: Record<string, string> = {
  [constants.StarknetChainId.SN_GOERLI]: 'Goerli Testnet',
  [constants.StarknetChainId.SN_GOERLI2]: 'Goerli 2 Testnet',
  [constants.StarknetChainId.SN_MAIN]: 'MainNet',
};

type IOverloadedActivateFunction = {
  (networkType: NetworkTypes.STARKNET, connector: Connector): Promise<void>;
};
export type ContextType = {
  handleDeactivate: (networkType: NetworkTypes) => void;
  handleActivate: IOverloadedActivateFunction;
};

const InitialContextValues = {
  handleActivate: async () => {},
  handleDeactivate: () => {},
};

export const WalletConnectInteractorContext =
  createContext<ContextType>(InitialContextValues);
export const useWalletConnectInteractor = () =>
  useContext(WalletConnectInteractorContext);

export const WalletConnectInteractorProvider = ({ children }: PropsTypes) => {
  const { address: account } = useEVMAccount();
  const { disconnect } = useDisconnect();
  const { signMessage, data } = useSignMessage();

  const { address, account: starknetAccount } = useAccount();
  const { connect: connectStarknet, disconnect: disconnectStarknet } =
    useConnectors();

  const navigate = useNavigate();
  const { networkFromInfo } = useTransferInfo();
  const { resign, setResign } = useSignStore();
  const { setWalletTo } = useWalletStore();

  const { notify } = useNotify();

  const { chain } = useNetwork();

  const handleActivate = useCallback<IOverloadedActivateFunction>(
    async (networkType, selectedConnector) => {
      try {
        if (
          networkType === NetworkTypes.STARKNET &&
          selectedConnector instanceof Connector
        ) {
          connectStarknet(selectedConnector)
            .then(() => {
              window.sessionStorage.setItem(
                'starknet-provider',
                selectedConnector.options.id
              );
              notify({ meassage: 'Wallet connected', type: 'success' });
            })
            .catch((err) => {
              if (err instanceof WrongAccountChainIdError) {
                notify({
                  title: `Wrong StarkNet Network`,
                  meassage: `Please ensure to switch StarkNet network to ${
                    starknetNetworks[getAppStarknetChanId()]
                  } in your wallet`,
                  type: 'error',
                });
                return;
              }
              console.error(err);
            });

          return;
        }
      } catch (e: any) {
        console.error(e);
      }
    },
    [account, connectStarknet, networkFromInfo]
  );

  const location = useLocation();

  const deactivateEthWallet = useCallback(() => {
    window.sessionStorage.removeItem('provider');
    disconnect();
    setWalletTo('');
  }, [disconnect]);

  const deactivateStarknetWallet = useCallback(() => {
    window.sessionStorage.removeItem('starknet-provider');
    disconnectStarknet();
    setWalletTo('');
  }, [disconnectStarknet]);

  const handleDeactivate = (networkType: NetworkTypes) => {
    if (
      location.pathname !== '/' &&
      location.pathname !== '/explorer' &&
      !location.pathname.includes('/phases')
    ) {
      navigate('/send');
    }

    if (networkType === NetworkTypes.STARKNET) {
      deactivateStarknetWallet();
    }
  };

  const deactivateWrongStarknetNetwork = useCallback(async () => {
    if (address && chain && networkFromInfo) {
      if (
        networkFromInfo.network_type === NetworkTypes.STARKNET &&
        chain?.id !== networkFromInfo.chainId
      ) {
        handleDeactivate(NetworkTypes.STARKNET);
        const network = starknetNetworks[networkFromInfo.chainId];
        notify({
          title: `Wrong StarkNet Network`,
          meassage: `Please ensure to switch StarkNet network to ${network} in your wallet`,
          type: 'error',
        });
      }
    }
  }, [chain, address, networkFromInfo]);

  const value = { handleActivate, handleDeactivate };

  const oldEthAddressRef = useRef(account);
  const oldStarknetAddressRef = useRef(address);

  const errorNotifier = (error?: unknown) => {
    if (error instanceof AxiosError) {
      return notify({ type: 'error', meassage: error.response?.data.message });
    }
    // if (error instanceof Error) {
    //   return notify({ type: "error", meassage: error.message });
    // }
    return notify({
      type: 'error',
      meassage: 'Something went wrong. Please try again later.',
    });
  };

  const checkIsAuthWallet = async (
    address: string,
    network_type: NetworkTypes
  ) => {
    try {
      await api.checkWallet(address, network_type);
      return true;
    } catch (error) {
      return false;
    }
  };

  const signStarknetWalletHelper = useCallback(
    async (address: string) => {
      if (!starknetAccount) {
        return;
      }

      try {
        const typedData = await getTypedDataWithAddress(address);

        const signed = await starknetAccount.signMessage(typedData);

        await api.validateSignedMessage(address, NetworkTypes.STARKNET, signed);
      } catch (error) {
        errorNotifier(error);
        deactivateStarknetWallet();
      }
    },
    [starknetAccount, deactivateStarknetWallet]
  );

  const signStarknetWallet = useCallback(
    async (address?: string) => {
      if (!address) {
        return;
      }

      if (address === oldStarknetAddressRef.current) {
        return;
      }

      const isAuth = await checkIsAuthWallet(address, NetworkTypes.STARKNET);

      if (isAuth) {
        return;
      }

      oldStarknetAddressRef.current = address;

      await signStarknetWalletHelper(address);
    },
    [signStarknetWalletHelper]
  );

  const signEthWalletHelper = useCallback(
    async (address: string) => {
      try {
        const typedData = await getTypedDataWithAddress(address);
        await signMessage({ message: typedData.message.value });
      } catch (error) {
        deactivateEthWallet();
        errorNotifier(error);
      }
    },
    [deactivateEthWallet]
  );

  useEffect(() => {
    if (data && account) {
      api.validateSignedMessage(account, NetworkTypes.EVM, data);
    }
  }, [data, account]);

  const getTypedDataWithAddress = async (
    address: string
  ): Promise<AppTypedData> => {
    const { data } = await api.getSignMessage();

    data.message.value = `${data.message.value}\n${address}`;
    return data;
  };

  const signEthWallet = useCallback(
    async (address?: `0x${string}`) => {
      if (!address || address === oldEthAddressRef.current) {
        return;
      }

      const isAuth = await checkIsAuthWallet(address, NetworkTypes.EVM);

      if (isAuth) {
        return;
      }

      oldEthAddressRef.current = address;
      await signEthWalletHelper(address);
    },
    [signEthWalletHelper]
  );

  const resignWalletHelper = useCallback(
    async (type: NetworkTypes) => {
      setResign(null);
      if (type === NetworkTypes.STARKNET && address) {
        return await signStarknetWalletHelper(address);
      }

      if (type !== NetworkTypes.STARKNET && account) {
        return await signEthWalletHelper(account);
      }
    },
    [account, address, signEthWallet, signStarknetWallet]
  );

  const routesWithSign = ['app.', '/send', '/phases/', '/faucet'];

  useEffect(() => {
    if (
      account &&
      routesWithSign.some((route) => window.location.href.includes(route))
    ) {
      signEthWallet(account);
    }
    oldEthAddressRef.current = account;
  }, [account, signEthWallet]);

  useEffect(() => {
    if (networkFromInfo?.network_type !== NetworkTypes.STARKNET) {
      return;
    }

    if (
      address &&
      routesWithSign.some((route) => window.location.href.includes(route))
    ) {
      signStarknetWallet(address);
    }

    oldStarknetAddressRef.current = address;
  }, [address, signStarknetWallet, networkFromInfo]);

  useEffect(() => {
    deactivateWrongStarknetNetwork();
  }, [deactivateWrongStarknetNetwork]);

  useEffect(() => {
    if (resign) {
      resignWalletHelper(resign);
    }
  }, [resign, resignWalletHelper]);

  return (
    <WalletConnectInteractorContext.Provider value={value}>
      {children}
    </WalletConnectInteractorContext.Provider>
  );
};
