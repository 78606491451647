import { create } from "zustand";
import { PriceCache, PriceService } from "../services/PriceService";
import { PriceDto } from "../types/apiTypes";

export type PriceUpdaterStore = {
  prices: PriceCache;
  setPrices: (val: PriceCache) => void;
};

export const usePriceUpdater = create<PriceUpdaterStore>()((set) => ({
  prices: { value: {}, timestamp: 0 },
  setPrices: (value) => set({ prices: value }),
}));

const Price = new PriceService();
export const getPrice = (symbol: string, store: PriceCache): PriceDto => {
  Price.fetchPrice()
  const upperSymbol = symbol.toUpperCase();

  const tokenPrice = store.value[upperSymbol];

  return tokenPrice || { usd: 0 };
};
