import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { setCookie } from '../utils/cookies';
import { stage } from '../Config/ApiConfig';

export const InitialUserApiKey = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isFirstRender = useRef(true);

  useEffect(() => {
    if(!isFirstRender.current || stage !== 'production') return;

    const apiKey = searchParams.get('track_id');

    if (apiKey) {
      window.gtag('event', 'page_view', {
        page_location: window.location.href,
        track_id: apiKey, // Send the track_id as a custom parameter
      });

      setCookie('api_key', apiKey);
      setSearchParams((params) => {
        params.delete('track_id');
        return params;
      });
    }

    return () => {
      isFirstRender.current = false;
    };
  }, [searchParams]);

  return null;
};
