import { api } from '../../../../services/api';
import { walletStore } from '../../../../stores/wallet.store';
import { IOrder } from '../../../../types/apiTypes';
import { OrderSearchStatuses } from '../../../../types/enums';
import { myHistoryPopoverStore } from './store';

export async function fetchOrders(
  address?: string,
  isRefetching: boolean | undefined = false
) {
  if (!address) return;
  try {
    const { setRecentOrders, setActiveOrders } =
      myHistoryPopoverStore.getState();
    const { addOngoingOrder, setOngoingOrders } = walletStore.getState();

    const active = await api.getOrders(
      OrderSearchStatuses.IN_PROGRESS,
      '',
      address,
      false,
      3
    );
    const recent = await api.getOrders(
      OrderSearchStatuses.COMPLETE,
      '',
      address,
      false,
      3
    );
    setRecentOrders(recent?.slice(0, 3) ?? []);
    setActiveOrders(active?.slice(0, 3) ?? []);
    if (isRefetching) {
      active?.forEach((order) => {
        addOngoingOrder(order);
      });
    } else {
      setOngoingOrders(active ?? []);
    }
  } catch (error) {
    console.warn(error);
  }
}
