import { Modal } from "react-bootstrap";

import "../../../pages/SendPage/SendPage.css";

import CloseIcon from "../../../Assets/Icons/close-icon.svg";
import { useNotify } from "../../../hooks/useToast";
import { useMemo } from "react";
import { starknetWallets } from "../../constants/wallets.constant";
import { StarknetWalletOptions } from "../../types/wallets.types";
import { Connector, useConnectors } from "../..";
import { getStarknet } from "get-starknet-core";
import { getStoreVersion } from "../../utils/getStoreVersion";
import { NetworkTypes, useWalletConnectInteractor } from "../../../providers/web3Provider";

const starknet = getStarknet();

export const ConnectStarkWalletModal = ({
  openModal,
  setOpenModal,
}: {
  setOpenModal: (value: boolean) => void;
  openModal: boolean;
}) => {
  const { notify } = useNotify();

  const { connectors } = useConnectors();
  const { handleActivate } = useWalletConnectInteractor();

  const starknetConnectors: (StarknetWalletOptions & {
    connector: Connector;
  })[] = useMemo(() => {
    return starknetWallets.reduce((prev, cur) => {
      const connector = connectors.find((item) => item.id === cur.id);

      if (connector) {
        const wallet = { ...cur, connector };
        prev.push(wallet);
      }
      return prev;
    }, [] as (StarknetWalletOptions & { connector: Connector })[]);
  }, [connectors]);

  const handleConnect = async (
    provider: StarknetWalletOptions & {
      connector: Connector;
    }
  ) => {
    const available = provider.connector.available();

    if (!available) {
      const storeVer = getStoreVersion();

      if (storeVer) {
        const wallet = (
          await starknet.getDiscoveryWallets({
            include: [provider.id],
          })
        )[0];

        const download = (wallet.downloads as Record<string, string>)[storeVer];

        if (download) {
          return window.open(download);
        } else {
          return notify({
            meassage: `Your browser doesn't support ${provider.name} wallet extension`,
            type: "error",
          });
        }
      }

      return notify({
        meassage: `Please install ${provider.name} extension before use`,
        type: "info",
      });
    }

    await handleActivate(NetworkTypes.STARKNET,provider.connector);

    setOpenModal(false);
    // notify({ meassage: "Wallet connected", type: "success" });
  };

  return (
    <Modal
      show={openModal}
      onHide={() => setOpenModal(false)}
      className="absoluteCenter"
    >
      <Modal.Body
        onClick={() => setOpenModal(false)}
        className="bg-transparent connectModalModalBody"
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className=" defaultRadius    gradient-border-mask-connect-wallet connectFormContainer"
        >
          <div
            className="connectModalCloseBtn"
            onClick={() => setOpenModal(false)}
          >
            <img
              className="notification-img"
              style={{ width: 20 }}
              alt=""
              src={CloseIcon}
            />
          </div>
          <div className=" flex-column d-flex ">
            <div className="d-flex connectModalTitle justify-content-center align-items-center  ">
              StarkNet Ecosystem Wallets
            </div>
            <div className="d-grid gap-1">
              {!!starknetConnectors.length &&
                starknetConnectors.map((item) => {
                  return (
                    <div
                      className="connectModalItem d-flex  align-items-center  "
                      onClick={() => {
                        handleConnect(item);
                      }}
                      key={item.id}
                    >
                      <div className="center connectModalIconContainer">
                        <img
                          src={item.icon}
                          style={{ height: "22.4px", width: "22.4px" }}
                          alt=""
                        />
                      </div>
                      <span className="connectNetworkSpan">{item.name}</span>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
