import { useId } from 'react';

export function NotificationIcon() {
  const id = useId();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8934 10.3625L15.8141 13.1841C16.0164 13.4812 16.0379 13.8659 15.8697 14.1838C15.7016 14.5016 15.3716 14.7004 15.012 14.7004H2.98837C2.62882 14.7004 2.29879 14.5016 2.13068 14.1838C1.96256 13.866 1.98396 13.4813 2.18628 13.1841L4.10704 10.3625V6.84235C4.10704 4.4684 5.79773 2.48952 8.04069 2.04353V0.959498C8.04069 0.429609 8.47026 0 9.00019 0C9.53012 0 9.95968 0.429609 9.95972 0.959498V2.04349C12.2027 2.48944 13.8934 4.46832 13.8934 6.84232V10.3625ZM6.86606 15.8658C6.86606 15.8611 6.86637 15.8564 6.86641 15.8517H11.1341C11.1341 15.8547 11.1342 15.8577 11.1343 15.8606C11.1344 15.8623 11.1344 15.8641 11.1344 15.8658C11.1344 17.0445 10.1789 18 9.00023 18C7.82156 18 6.86606 17.0445 6.86606 15.8658Z"
        fill={`url(#${id})`}
      />
      <circle cx="13.5" cy="3.5" r="3.5" fill="#51448A" />
      <circle cx="13.5" cy="3.5" r="2.5" fill="#C7FFAD" />
      <defs>
        <linearGradient
          id={id}
          x1="4.00018"
          y1="0.5"
          x2="16.5002"
          y2="19"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9A95" />
          <stop offset="1" stopColor="#FF9A95" />
        </linearGradient>
      </defs>
    </svg>
  );
}
