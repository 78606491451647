import { SVGAttributes } from "react";

export function Tick(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M7.24255 13.0816C6.51714 13.7617 5.3401 13.7617 4.61504 13.0816L0.544058 9.265C-0.181353 8.58524 -0.181353 7.48174 0.544058 6.80198C1.26912 6.12189 2.44616 6.12189 3.17157 6.80198L5.59708 9.07561C5.78018 9.24695 6.07742 9.24695 6.26087 9.07561L12.8284 2.91839C13.5535 2.2383 14.7305 2.2383 15.4559 2.91839C15.8043 3.24498 16 3.68808 16 4.1499C16 4.61172 15.8043 5.05482 15.4559 5.38141L7.24255 13.0816Z"
        fill="#FEFEFE"
      />
    </svg>
  );
}
