import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import check from '../../../Assets/Icons/check.svg';
import external from '../../../Assets/Icons/external.svg';
import { MINUTE } from '../../../constants/time';
import { IOrder } from '../../../types/apiTypes';
import { OrderStatuses } from '../../../types/enums';
import '../ProgressPage.css';
import { useWalletStore } from '../../../stores/wallet.store';
import { Loader } from '../../../components/Loader';

const exhaustiveCheck = (param: never) => {
  throw new Error(`Unhandled param: ${param}`);
};

export const Progress = ({ order }: { order: IOrder }) => {
  const status = order.status;
  const { isDisabled, isWaitingForConfirmation } = useWalletStore();

  const statusClass = useMemo(() => {
    switch (status) {
      case OrderStatuses.PENDING:
      case OrderStatuses.WAIT_DEPOSIT:
      case OrderStatuses.REJECTED:
      case OrderStatuses.EXPIRED:
        return 'transY0';
      case OrderStatuses.DEPOSITED:
        return 'trans50';
      case OrderStatuses.SEND_FAILED:
      case OrderStatuses.SENDING:
      case OrderStatuses.SENT:
      case OrderStatuses.COMPLETE:
        return 'transY100';
      default:
        return exhaustiveCheck(status);
    }
  }, [status]);

  const [hasDelay, setHasDelay] = useState(false);

  useEffect(() => {
    if (isDisabled) return;

    setHasDelay(false);

    const delay = setTimeout(() => {
      setHasDelay(true);
    }, 3 * MINUTE);

    return () => clearTimeout(delay);
  }, [statusClass, isDisabled]);

  const isDetectionDelay =
    hasDelay &&
    statusClass === 'transY0' &&
    status !== OrderStatuses.REJECTED &&
    status !== OrderStatuses.EXPIRED;

  const isTransferDelay = hasDelay && statusClass === 'transY100';

  return (
    <div className="progressContainer">
      {isWaitingForConfirmation && (
        <>
          <div className="progressDepositSending">
            <span className="progressDepositSending-loader">
              <Loader />
            </span>
            <span className="progressDepositSending-text">
              Awaiting transfer confirmation
            </span>
          </div>
        </>
      )}
      {!isWaitingForConfirmation && (
        <>
          <div
            className={clsx(
              'progressLeftDiv',
              hasDelay && statusClass !== 'trans50' && 'withDelay'
            )}
          >
            <div className={`progressCircle activeProgressCircle`}>
              {<img src={check} className="progressCheckIcon" alt="" />}
            </div>

            <div
              className={clsx(
                'progress',
                statusClass === 'transY0' && 'inactive'
              )}
            />

            <div
              className={`progressCircle ${
                statusClass === 'transY100' && 'activeProgressCircle'
              } middleCheckIcon`}
            >
              {statusClass === 'transY100' && (
                <img src={check} className="progressCheckIcon" alt="" />
              )}
            </div>

            <div
              className={`progressCircle ${
                status === OrderStatuses.COMPLETE && 'activeProgressCircle'
              }`}
            >
              {status === OrderStatuses.COMPLETE && (
                <img src={check} className="progressCheckIcon" alt="" />
              )}
            </div>

            <div className={` progressAbsolute ${statusClass}`}>
              <span className="ring ring-1"></span>
              <span className="ring ring-2"></span>
              <span className="ring ring-3"></span>
              <span className="ring ring-4"></span>
              <span className="arrow"></span>
            </div>
          </div>

          <div className="progressRightDiv">
            <div
              className={clsx(
                isDetectionDelay && 'withDelay',
                statusClass === 'transY0' && 'text-gradient',
                'title mb-1'
              )}
            >
              {isDetectionDelay
                ? 'Detection Is Experiencing Slight Delay...'
                : 'Detecting Your Transfer'}
              {order.transaction_in && (
                <a
                  href={
                    order.currency_in.contract.network.blockscan_url +
                    'tx/' +
                    order.transaction_in
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className="progressTransactionLink"
                >
                  <img src={external} alt="" />
                </a>
              )}
            </div>
            <div
              className={clsx(
                isDetectionDelay && 'withDelay',
                'title subtitle'
              )}
            >
              {isDetectionDelay
                ? 'Your assets will be detected in a while'
                : 'Estimated time: less than 1 minute'}
            </div>
            <div
              className={clsx(
                'title mTop40',
                statusClass === 'trans50' && 'text-gradient',
                statusClass === 'transY0' && 'textBlack'
              )}
            >
              Transfer Confirmation
            </div>
            <div
              className={clsx(
                isTransferDelay && 'withDelay',
                `title ${
                  statusClass === 'transY100' ? 'text-gradient' : 'textBlack'
                }`
              )}
            >
              {isTransferDelay
                ? 'Bridging is experiencing slight delay...'
                : 'Transfer Of Assets To Your Address'}
              {order.transaction_out && (
                <a
                  href={
                    order.currency_out.contract.network.blockscan_url +
                    'tx/' +
                    order.transaction_out
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={external} alt="" />
                </a>
              )}
            </div>
            <div
              className={clsx(
                isTransferDelay && 'withDelay',
                statusClass !== 'transY100' && 'textBlack',
                `title subtitle`
              )}
            >
              {isTransferDelay
                ? 'Your assets will be with you shortly in the destination network'
                : 'Estimated time: less than 1 minute'}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
