import { ROUTES } from '../../../constants/routes.constants';
import { OtherItem } from './OtherItem';
import './styles.css';

export function Other() {
  return (
    <div>
      <OtherItem
        backgroundSrc={require('../assets/FaucetBg.png')}
        href={ROUTES.FAUCET_PAGE}
      >
        <strong>
          Claim Free Testnet Tokens Across
          <br /> Networks with MultiChain Faucet
        </strong>
      </OtherItem>
    </div>
  );
}
