import { maxPlatformDecimals } from '../../../constants/numbers';
import { shortAddress } from '../../../utils';
import { decimalSlicer, toFixed } from '../../../utils/numbers';

export const NetworkInfo = ({
  netwSrc,
  netw,
  wallet,
  amount,
  amountUsd,
  coinSimbol,
}: {
  netwSrc: string;
  netw: string;
  wallet: string;
  amount: string;
  amountUsd: string;
  coinSimbol: string;
}) => {
  return (
    <div className="networkInfoContainer">
      <div className="networkInfoLeftDiv">
        <div className="imgDiv20">
          <img className="coinImg" src={netwSrc} alt="network icon" />
        </div>

        <div className="d-flex flex-column ms-2">
          <div className="infoText">{netw}</div>
          <div className="infoText secondarytext secondary-text-weight">{`${shortAddress(
            wallet
          )}`}</div>
        </div>
      </div>
      <div className="d-flex flex-column progress-info-block-price-div">
        <div className="infoText">{`${toFixed(
          +amount,
          maxPlatformDecimals
        )} ${coinSimbol}`}</div>
        <div className="infoText secondarytext infoText-bold">{`${toFixed(
          +amountUsd,
          2
        )} $`}</div>
      </div>
    </div>
  );
};
