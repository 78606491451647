import clsx from 'clsx';
import { useEffect, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useEffectOnce } from 'usehooks-ts';
import { useAccount as useEVMAccount } from 'wagmi';
import { BASE_URL } from '../../Config/ApiConfig';
import { useSSEBroadcast } from '../../hooks/useSSEBroadcast';
import { useAccount } from '../../starknet';
import { useWalletStore } from '../../stores/wallet.store';
import { IOrder } from '../../types/apiTypes';
import { OrderStatuses } from '../../types/enums';
import { bridgeRoutes } from '../../utils/getRoutes';
import { useMyHistoryPopoverStore } from '../Header/components/MyHistoryPopover/store';
import { fetchOrders } from '../Header/components/MyHistoryPopover/utils';
import { TransactionItem } from './TransactionItem';
import { useTransactionNotificationStore } from './store';
import './styles.css';

export function TransactionTrackingNotification() {
  const [isOpen, isHidden, setIsOpen] = useTransactionNotificationStore((s) => [
    s.isOpen,
    s.isHidden,
    s.setIsOpen,
  ]);
  const [isPopoverOpen, addIgnoredOrder, setOrder] = useMyHistoryPopoverStore(
    (state) => [state.isOpen, state.addIgnoredOrder, state.setOrder]
  );
  const { pathname } = useLocation();
  const { isConnected, address } = useEVMAccount();
  const { ongoingOrders, clearOngoingOrder, addOngoingOrder, setOngoingOrder } =
    useWalletStore();
  const { address: starknetAddress } = useAccount();

  const shouldConnect =
    bridgeRoutes.includes(window.location.host);

  useSSEBroadcast({
    url:
      shouldConnect && (address || starknetAddress)
        ? BASE_URL +
          `/api/orders/session_open?wallet_address=${
            address || starknetAddress
          }`
        : null,
    listeners: {
      order_updated: (e) => {
        try {
          const order: IOrder = e;

          if (
            order.status === OrderStatuses.REJECTED ||
            order.status === OrderStatuses.EXPIRED
          ) {
            setOngoingOrder(order.id, undefined);
            setOrder(order.id, undefined);
          } else {
            addOngoingOrder(order, true);
            setOrder(order.id, order);
          }
        } catch (error) {
          console.warn(error);
        }
      },
    },
  });

  useEffectOnce(() => {
    fetchOrders(address || starknetAddress);
  });

  useEffect(() => {
    fetchOrders(address || starknetAddress);
  }, [address, starknetAddress]);

  useEffect(() => {
    if (isPopoverOpen) {
      ongoingOrders.forEach((o) => addIgnoredOrder(o.id));
      clearOngoingOrder();
    }
  }, [isPopoverOpen]);

  useLayoutEffect(() => {
    if (
      bridgeRoutes.includes(window.location.host) ||
      pathname.startsWith('/send') ||
      pathname.startsWith('/progress')
    ) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [pathname]);

  if (!isOpen || isPopoverOpen || !isConnected || ongoingOrders.length <= 0)
    return null;

  return (
    <div className={clsx('transaction-notification', isHidden && 'hidden')}>
      {ongoingOrders.map((o) => (
        <TransactionItem order={o} key={o.id} />
      ))}
    </div>
  );
}
