import { MockNetwork, mockNetworks } from "../constants/mock_networks.constants";
import { chains } from "../providers/rainbow";
import { INetwork } from "../types/apiTypes";

export const getMockNetwork = (network?: INetwork): MockNetwork => {
  const mockNetwork = mockNetworks.find(
    (nw) => nw.id === network?.id
  );

  if (!mockNetwork) {
    throw new Error("Failed to get mock network");
  }

  return mockNetwork;
};

export const getNetwork = (network: INetwork) => {
  return chains.find((n) => n.id === +network.chainId);
};
