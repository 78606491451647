import { Other } from '../Other';
import { PhasesBlock } from '../PhasesBlock';

export function ActivitiesTab() {
  return (
    <div className="notifications-menu--tabs-content">
      <PhasesBlock />
      <div className="notifications-menu--group">
        <div className="notifications-menu--group-heading">Faucet</div>
        <Other />
      </div>
    </div>
  );
}
