import { useMemo } from "react";
import curetDown from "../../../Assets/Icons/curetDownWiti.svg";
import { BASE_URL } from "../../../Config/ApiConfig";
import { useWalletStore } from "../../../stores/wallet.store";
import { INetwork } from "../../../types/apiTypes";
import "../SendPage.css";
function NetworkSelect({
  label,
  network,
  networkOff,
  networks,
  setValue,
  setShow,
}: {
  label: string;
  network: string;
  networkOff: string;
  networks: INetwork[];
  setValue: (value: string) => void;
  setShow: (value: boolean) => void;
}) {
  // const networks: INetwork[] = useMemo((): INetwork[] => {
  //   const list = [
  //     ...networksList.filter((item) => item.id === network),
  //     ...networksList.filter(
  //       (item) => item.id !== network && item.id !== networkOff
  //     ),
  //   ];

  //   return list.slice(0, 7);
  // }, [network, networkOff, networksList]);

  const onClick = (id: string) => {
    setValue(id);
    // if (id === network) {
    //   // setAmount('');
    //   setReceiveAmount('');
    // } else {
    //   setValue(id);
    //   // setAmount('');
    //   setReceiveAmount('');
    // }
  };

  return (
    <div className="align-items-center">
      <div className="text-white bold network-label">{label}</div>

      <div className="d-flex flex-diections-row">
        <div className="w-100 d-flex flex-direction-row align-items-center networks-list flex-nowrap">
          {/* 
            wee need to slice to avoid big incoming array
            that can broke ui          
          */}
          {networks.slice(0,5).map((el) => {
            return el.id === network ? (
              <button
                key={el.id}
                style={{ width: "206px", justifyContent: "left" }}
                // disabled={el.id === networkOff}
                className={`d-flex border-0 bg-opacity-10 roundBtn selected-network padding-6 size-auto `}
              >
                <div className="d-flex coinBtn border-0 p-0">
                  <img
                    src={BASE_URL + el.network_image_url}
                    alt="token"
                    className={`${el.id === network && "ms1"} networkIcon`}
                  />
                </div>
                <div className="mx-1 networkSelectBtn-text">{el.name}</div>
              </button>
            ) : (
              <button
                key={el.id}
                onClick={() => onClick(el.id)}
                className={`d-flex coinBtn border-0 ${
                  !el.active ? "disabled-network-circle" : ""
                }`}
                disabled={!el.active}
              >
                <img
                  src={BASE_URL + el.network_image_url}
                  alt="token"
                  className={`${el.id === network && "ms1"} networkIcon`}
                />
              </button>
            );
          })}
          <div
            onClick={() => setShow(true)}
            className="rounded-circle d-flex  roundBtn btn "
          >
            <img
              width="11"
              height="8"
              src={curetDown}
              alt="open"
              className="btnIcon"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default NetworkSelect;
