import { useCallback, useEffect, useMemo, useState } from 'react';
import { Stack } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { Helmet } from 'react-helmet';
import SendBg from '../../Assets/images/send-bg-png.png';
import SendBgSmall from '../../Assets/images/send-bg-small.svg';
import Footer from '../../components/Footer';
import Header from '../../components/Header/header';
import { Loader } from '../../components/Loader';
import { useIsPageLoaded } from '../../hooks/useIsPageLoaded';
// import { Price } from "../../services/PriceService";
import { useSearchParams } from 'react-router-dom';
import { useWindowSize } from 'usehooks-ts';
import { useAccount as useEVMAccount } from 'wagmi';
import { ConnectWalletModalWithDisabled } from '../../components/ConnectWalletModalWithDisabled';
import { PhaseTab } from '../../components/PhaseTab';
import { usePhaseTabStore } from '../../components/PhaseTab/store';
import { useDebouncedCallback } from '../../hooks/useDebouncedCallback';
import { NetworkTypes } from '../../providers/web3Provider';
import { api } from '../../services/api';
import { useAccount } from '../../starknet';
import { useWalletStore } from '../../stores/wallet.store';
import TransferInfoForm from '../TransferInfoPage/form';
import './SendPage.css';
import ChooseNetworkModal from './components/chooseNetworkModal';
import SendForm from './form';
import { AppModeSwitch } from '../../components/AppModeSwitch';

const SendPage = () => {
  const [openModal, setOpenModal] = useState(false);
  const [isPhaseTabOpen] = usePhaseTabStore((state) => [state.isOpen]);
  const {
    setNetworkFrom,
    setNetworkTo,
    networkFrom,
    networkTo,
    clearStore,
    networksList,
  } = useWalletStore();
  const [show, setShow] = useState(false);
  const [showTwo, setShowTwo] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const { address: account } = useEVMAccount();
  const { address: starknetAddress } = useAccount();
  const { width } = useWindowSize();
  const { currencyPair, currencyFromId, settransactionCurrencyFee } =
    useWalletStore();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  const fromNet = networksList.find((item) => item?.id === networkFrom);
  const walletSender =
    fromNet?.network_type === NetworkTypes.STARKNET ? starknetAddress : account;

  const getTransactionFeeHandler = useCallback(async () => {
    if (currencyFromId && currencyPair && currencyPair.length) {
      await api.getTransactionFee(
        currencyFromId,
        currencyPair[0].id,
        walletSender
      );
    } else {
      settransactionCurrencyFee(null);
    }
  }, [
    currencyFromId,
    currencyPair,
    walletSender,
    networkTo,
    settransactionCurrencyFee,
  ]);

  const getDebouncedTransactionFee = useDebouncedCallback(
    getTransactionFeeHandler,
    500
  );

  useEffect(() => {
    getDebouncedTransactionFee();
  }, [getDebouncedTransactionFee]);

  const selectedNetwork = useMemo(() => {
    return networksList.find((network) => network.id === networkFrom);
  }, [networkFrom, networksList]);

  useIsPageLoaded(SendBg);

  const openConfirm = () => {
    setIsConfirmOpen(true);
  };

  const closeConfirm = useCallback(() => {
    setIsConfirmOpen(false);
  }, []);

  const setNetworkFromHandler = useCallback(
    (networkId: string) => {
      const fromId = networkFrom;
      const toId = networkTo;
      const networkFromElement = networksList.find(
        (item) => item.id === networkId
      );
      if (networkId === toId) {
        const networkToElement = networksList.find(
          (item) => item.id === fromId
        );
        if (networkToElement) {
          setSearchParams((searchParams) => {
            searchParams.set('destination', networkToElement.name);
            return searchParams;
          });
        }
        setNetworkTo(fromId);
      }

      if (networkFromElement) {
        setSearchParams((searchParams) => {
          searchParams.set('source', networkFromElement.name);
          return searchParams;
        });
      }
      setNetworkFrom(networkId);
    },
    [networkTo, networkFrom]
  );
  const [_, setSearchParams] = useSearchParams();

  const setNetworkToHandler = useCallback(
    (networkId: string) => {
      const fromId = networkFrom;
      const toId = networkTo;
      const networkToElement = networksList.find(
        (item) => item.id === networkId
      );
      if (networkId === fromId) {
        const networkFromElement = networksList.find(
          (item) => item.id === toId
        );
        if (networkFromElement) {
          setSearchParams((searchParams) => {
            searchParams.set('source', networkFromElement.name);
            return searchParams;
          });
        }
        setNetworkFrom(toId);
      }

      if (networkToElement) {
        setSearchParams((searchParams) => {
          searchParams.set('destination', networkToElement.name);
          return searchParams;
        });
      }

      setNetworkTo(networkId);
    },
    [networkTo, networkFrom]
  );

  useEffect(() => {
    api.getNetwork();
    return () => clearStore();
  }, [clearStore]);

  useEffect(() => {
    if (networkFrom && networkTo) {
      api.getCurrency();
    }
  }, [networkFrom, networkTo]);

  const modalOffsetTop = useMemo(() => {
    if (isPhaseTabOpen) {
      if (width < 480) {
        return '180px';
      }

      return '230px';
    }

    if (width > 480) {
      return 'calc(50vh - 286px)';
    }

    return undefined;
  }, [isPhaseTabOpen, width]);

  return (
    <div className="page-background-color">
      <Helmet>
        <title>App | Retro Bridge</title>
      </Helmet>
      <Container
        fluid
        className="send-page-container mainSend  min-vh-100 w-100 d-flex flex-1  justify-content-center flex-column align-items-center page-padding-bottom pt-2 position-relative"
      >
        <>
          <img src={SendBg} alt="" className="send-bg-img" />
          <img src={SendBgSmall} alt="" className="send-bg-img-mobile" />
          <Header setOpenModal={setOpenModal} />
          <Stack className="mw-100 col-xs-12 col-sm-12 col-md-6 mx-auto col-lg-5 col-xl-4 w-100 flex align-items-center">
            {isLoading && <Loader />}
            {!isConfirmOpen && !isLoading && (
              <div className="form-mt" id="bridge-form">
                <AppModeSwitch variant="Testnet" />
                <SendForm
                  setOpenModal={setOpenModal}
                  setShow={setShow}
                  setShowTwo={setShowTwo}
                  openConfirm={openConfirm}
                />
              </div>
            )}
            {isConfirmOpen && (
              <div className="form-info-mt">
                <TransferInfoForm closeConfirm={closeConfirm} />
              </div>
            )}
          </Stack>
          <div className="send-page-footer">
            <Footer />
          </div>
          <ChooseNetworkModal
            show={show}
            title="Select Source"
            handleClose={() => setShow(false)}
            setNetwork={setNetworkFromHandler}
            type={'from'}
            networkOff={networkFrom}
            networkTo={networkTo}
            offsetTop={modalOffsetTop}
          />
          <ChooseNetworkModal
            title="Select Destination"
            show={showTwo}
            handleClose={() => setShowTwo(false)}
            setNetwork={setNetworkToHandler}
            type={'to'}
            networkOff={networkFrom}
            networkTo={networkTo}
            offsetTop={modalOffsetTop}
          />
        </>

        <ConnectWalletModalWithDisabled
          setOpenModal={setOpenModal}
          openModal={openModal}
          networkType={selectedNetwork?.network_type}
          onlySelectedNetworkType
        />
      </Container>
    </div>
  );
};

export default SendPage;
