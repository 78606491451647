export enum OrderStatuses {
  PENDING = "Pending",
  WAIT_DEPOSIT = "Wait deposit",
  DEPOSITED = "Deposited",
  SENDING = "Sending",
  SENT = "Sent",
  COMPLETE = "Completed",
  SEND_FAILED = "Send failed",
  REJECTED = "Rejected",
  EXPIRED = 'Expired',
}

export enum OrderSearchStatuses {
  ALL = 'all',
  COMPLETE = 'complete',
  IN_PROGRESS = 'in_progress',
  HISTORY = 'history',
}