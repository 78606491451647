import {
  DialogClose,
  DialogContent,
  DialogOverlay,
  DialogPortal,
  Root,
} from '@radix-ui/react-dialog';
import { FaucetIcon } from '../../../../Assets/Icons/Faucet';
import { BASE_URL } from '../../../../Config/ApiConfig';
import { RightArrow } from '../../../../components/TransactionTrackingNotification/icons/Rightarrow';
import { useFaucetStore } from '../../store';
import successBg from './assets/success-bg.png';
import errorBg from './assets/error-bg.png';
import './styles.css';
import { useMemo } from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { INetwork } from '../../../../types/apiTypes';
import { IFaucetCurrency } from '../../../../types/faucet';
import { Tick } from '../../../../Assets/Icons/Tick';
import { Timer } from '../../../../Assets/Icons/Timer';
import { Timer24 } from '../../../../Assets/Icons/Timer24';
import { AnimatePresence, motion } from 'framer-motion';
dayjs.extend(relativeTime);

export enum ClaimDialogVariants {
  SUCCESS_CLAIMED = 'Success claimed',
  ALREADY_CLAIMED = 'Already claimed',
  WALLET_EXCEEDED_LIMIT = 'Wallet exceeded limit',
}

interface ClaimDialogProps {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  variant?: ClaimDialogVariants;
  selectedNetwork?: INetwork;
  selectedCurrency?: IFaucetCurrency;
}

export function ClaimDialog({
  isOpen,
  onOpenChange,
  variant = ClaimDialogVariants.SUCCESS_CLAIMED,
  selectedNetwork,
  selectedCurrency,
}: ClaimDialogProps) {
  const timeUntilReset = useMemo(() => {
    const hours = 24 - new Date().getUTCHours();
    return dayjs(dayjs().add(hours, 'h')).fromNow();
  }, []);

  return (
    <Root open={isOpen} onOpenChange={onOpenChange}>
      <AnimatePresence>
        {isOpen && (
          <DialogPortal forceMount>
            <DialogOverlay asChild>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="faucet-claim-dialog--overlay"
              ></motion.div>
            </DialogOverlay>
            <DialogContent asChild>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="faucet-claim-dialog"
              >
                <div className="faucet-claim-dialog--bg">
                  <img
                    src={
                      variant === ClaimDialogVariants.SUCCESS_CLAIMED
                        ? successBg
                        : errorBg
                    }
                    alt=""
                  />
                </div>
                <div className="faucet-claim-dialog--state">
                  {variant === ClaimDialogVariants.SUCCESS_CLAIMED && <Tick />}
                  {variant === ClaimDialogVariants.ALREADY_CLAIMED && <Timer />}
                  {variant === ClaimDialogVariants.WALLET_EXCEEDED_LIMIT && (
                    <Timer24 />
                  )}
                </div>
                {(variant === ClaimDialogVariants.SUCCESS_CLAIMED ||
                  variant === ClaimDialogVariants.ALREADY_CLAIMED) && (
                  <h2 className="faucet-claim-dialog--heading">
                    You have claimed {selectedCurrency?.amount_to_claim}{' '}
                    <span className="text-nowrap">
                      <span className="faucet-claim-dialog--heading-icon">
                        <img
                          src={BASE_URL + selectedCurrency?.image_url}
                          alt=""
                        />
                      </span>{' '}
                      {selectedCurrency?.symbol}
                    </span>{' '}
                    on{' '}
                    <span className="faucet-claim-dialog--heading-icon">
                      <img
                        src={BASE_URL + selectedNetwork?.network_image_url}
                        alt=""
                      />
                    </span>{' '}
                    {selectedNetwork?.name}
                  </h2>
                )}
                {variant === ClaimDialogVariants.WALLET_EXCEEDED_LIMIT && (
                  <h2 className="faucet-claim-dialog--heading">
                    You have already claimed tokens on all 3 networks today
                  </h2>
                )}
                <div className="faucet-claim-dialog--text">
                  {variant === ClaimDialogVariants.SUCCESS_CLAIMED && (
                    <>
                      Tokens will be transferred to your address within{' '}
                      <strong>one minute</strong>
                    </>
                  )}
                  {variant === ClaimDialogVariants.ALREADY_CLAIMED && (
                    <>
                      You will be able to make claims on this network again{' '}
                      <strong>{timeUntilReset}</strong>
                    </>
                  )}
                  {variant === ClaimDialogVariants.WALLET_EXCEEDED_LIMIT && (
                    <>
                      You will be able to make new claims
                      <br /> <strong>{timeUntilReset}</strong>
                    </>
                  )}
                </div>
                <DialogClose className="faucet-claim-dialog--action">
                  <RightArrow
                    width={16}
                    height={16}
                    style={{ transform: 'rotate(180deg)' }}
                  />
                  Back to <FaucetIcon /> Faucet
                </DialogClose>
              </motion.div>
            </DialogContent>
          </DialogPortal>
        )}
      </AnimatePresence>
    </Root>
  );
}
