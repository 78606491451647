import { useEffect, useRef } from "react";
import { useAccount, useConnectors } from "../starknet";

export const useEagerStarknetConnect = () => {
  const { connectors, connect } = useConnectors();
  const { isConnected } = useAccount();
  const tried = useRef(false);

  useEffect(() => {
    const provider = sessionStorage.getItem("starknet-provider");
    const connector = connectors.find((item) => item.id === provider);
    if (connector && !tried.current) {
      tried.current = true;
      const isAvail = connector.available()
      if (isAvail) {
        connect(connector).catch(() => (tried.current = true));
      } else {
        tried.current = true;
      }
    }
  }, [connectors]);

  useEffect(() => {
    if (!tried.current && isConnected) {
      tried.current = true;
    }
  }, [isConnected]);

  return tried.current;
};
