import { useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAccount as useEVMAccount } from 'wagmi';
import PlusIcon from '../../Assets/Icons/plus-icon.svg';
import retroIcon from '../../Assets/Icons/retroIcon.svg';
import WalletIcon from '../../Assets/Icons/wallet-icon.svg';
import { ROUTES } from '../../constants/routes.constants';

import { useWindowSize } from 'usehooks-ts';
import { AdventureBox } from '../../Assets/Icons/AdventureBox';
import { Globe } from '../../Assets/Icons/Globe';
import { Sun } from '../../Assets/Icons/Sun';
import { DappsIcon } from '../../Assets/Icons/dapps';
import { useEagerStarknetConnect } from '../../hooks/useEagerStarknetConnect';
import '../../pages/SendPage/SendPage.css';
import { useAccount } from '../../starknet';
import { useWalletStore } from '../../stores/wallet.store';
import { useOnClickOutside } from '../../utils';
import { ConnectWalletModalWithDisabled } from '../ConnectWalletModalWithDisabled';
import MobileMenu from '../MobileMenu';
import { NotificationsPopover } from '../NotificationsPopover';
import { WalletPopover } from './components/MobileWalletPopover';
import { MyHistoryPopover } from './components/MyHistoryPopover';
import { NetworkSwitch } from './components/NetworkSwitchPopover';
import './styles.css';

const Header = ({
  setOpenModal,
}: {
  setOpenModal?: (value: boolean) => void;
}) => {
  const { width } = useWindowSize();
  const ref = useRef<HTMLDivElement>(null);
  const ref2 = useRef<HTMLDivElement>(null);
  const starknetRef = useRef<HTMLDivElement>(null);
  const starknetMobileRef = useRef<HTMLDivElement>(null);
  const mobileDropdown = useRef<HTMLDivElement>(null);
  const { address: account } = useEVMAccount();
  const { address: starknetAddress } = useAccount();
  const [visible, setVisible] = useState(false);
  const [visibleStarknet, setVisibleStarknet] = useState(false);
  const [visibleStarknetMobile, setVisibleStarknetMobile] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { networkFrom, networksList } = useWalletStore();

  // useEagerEvmConnect();
  useEagerStarknetConnect();
  const selectedNetwork = useMemo(() => {
    return networksList.find((network) => network.id === networkFrom);
  }, [networkFrom, networksList]);

  const closeMobileDropdown = () => {
    setIsDropdownOpen(false);
  };

  const toggleMobileDropdown = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.stopPropagation();
    setIsDropdownOpen((prev) => !prev);
  };

  const closeDropdown = () => {
    setVisible(false);
  };
  const closeStarknetDropdown = () => {
    setVisibleStarknet(false);
  };
  const closeStarknetMobileDropdown = () => {
    setVisibleStarknetMobile(false);
  };
  const closeSecondDropdown = () => {
    setVisible2(false);
  };

  useOnClickOutside(ref, visible ? closeDropdown : undefined);
  useOnClickOutside(ref2, visible2 ? closeSecondDropdown : undefined);
  useOnClickOutside(
    starknetRef,
    visibleStarknet ? closeStarknetDropdown : undefined
  );
  useOnClickOutside(
    starknetMobileRef,
    visibleStarknetMobile ? closeStarknetMobileDropdown : undefined
  );
  useOnClickOutside(
    mobileDropdown,
    isDropdownOpen ? closeMobileDropdown : undefined
  );

  const isConnected = !!account || !!starknetAddress;

  return (
    <header className="header app-header">
      <div className="d-lg-none d-flex" style={{ minWidth: '34px' }}>
        <MobileMenu isAppMenu />
      </div>
      <Link to={ROUTES.SEND_PAGE} className="mx-auto mx-lg-0">
        <img
          src={retroIcon}
          alt="logo"
          style={{ width: '170px', height: '40px' }}
        />
      </Link>
      <nav className="header-nav ms-3">
        <Link to={ROUTES.PHASES_PAGE} className="header-nav-item">
          <Sun />
          Phases
        </Link>
        <Link
          to={ROUTES.PHASE_ADVENTURES_PAGE}
          className="header-nav-item adventures"
        >
          <AdventureBox />
          Retro Adventures
        </Link>
        <Link to={ROUTES.DAPP_EXPLORER_PAGE} className="header-nav-item">
          <DappsIcon />
          Dapps Explorer
        </Link>
      </nav>
      <div className="app-header-nav">
        <NetworkSwitch />
        <div className="header-nav-item--divider" />
        <NotificationsPopover />
        <div className="header-nav-item--divider" />
        <Link
          to={ROUTES.EXPLORER_PAGE}
          className="header-nav-item"
          aria-label="Explorer"
        >
          <Globe width={20} height={20} />
        </Link>
        {isConnected && <div className="header-nav-item--divider" />}
        {!account && !starknetAddress && (
          <button
            className="headerBtn header-nav-item fw-bold ms-0"
            onClick={() => (setOpenModal ? setOpenModal(true) : null)}
          >
            Connect wallet
          </button>
        )}
        {isConnected && <WalletPopover />}
      </div>
      {width <= 993 && (
        <div className="d-flex d-lg-none me-3">
          <NotificationsPopover />
        </div>
      )}
      {!isConnected && (
        <div
          ref={mobileDropdown as any}
          onClick={toggleMobileDropdown}
          className="position-relative d-lg-none"
        >
          <button className="header-wallet-button header-mobile-button-background">
            <img
              className="header-mobile-button-img-1"
              src={WalletIcon}
              alt=""
            />
            <img className="header-mobile-button-img-2" src={PlusIcon} alt="" />
          </button>
          <ConnectWalletModalWithDisabled
            openModal={isDropdownOpen}
            setOpenModal={setIsDropdownOpen}
            networkType={selectedNetwork?.network_type}
            onlySelectedNetworkType
          />
        </div>
      )}

      {isConnected && (
        <div className="position-relative d-flex d-lg-none">
          <WalletPopover />
        </div>
      )}
      <div className="transaction-popover-mobile">
        <MyHistoryPopover />
      </div>
    </header>
  );
};

export default Header;
