import { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Simplebar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import searchIcon from '../../../Assets/Icons/search.svg';
import { networkListIndexes } from '../../../constants/mock_networks.constants';
import { useWalletStore } from '../../../stores/wallet.store';
import { INetwork } from '../../../types/apiTypes';
import '../SendPage.css';
import { NetworksList } from './modal/networksList';
import { BASE_URL } from '../../../Config/ApiConfig';
import { BalanceTooltip } from './modal/BalanceTooltip';
import { useAccount } from 'wagmi';
import { api } from '../../../services/api';

const categorySortPoints = (category: string) => {
  let categoryPoints = 0;
  if (category.toLowerCase().includes('evm')) {
    categoryPoints += 3;
  }
  if (category.toLowerCase().includes('l1')) {
    categoryPoints += 2;
  }

  return categoryPoints;
};

function ChooseNetworkModal({
  show,
  handleClose,
  setNetwork,
  networkOff,
  title,
  networkTo,
  type,
  offsetTop,
}: {
  show: boolean;
  handleClose: () => void;
  setNetwork: (value: string) => void;
  type: 'from' | 'to';
  networkOff: string;
  networkTo: string;
  title: string;
  offsetTop?: number | string;
}) {
  const [search, setSearch] = useState('');
  const [modalMarginTop, setModalMarginTop] = useState('10%');
  const { networksList, currencyFromId, currencyFromList } = useWalletStore();

  const selectedCurrency = useMemo(() => {
    return currencyFromList.find((currency) => currency.id === currencyFromId);
  }, [currencyFromId, currencyFromList]);

  const { isConnected } = useAccount();

  useEffect(() => {
    if (!currencyFromId) return;
    api.getAllCurrencyPairs()
  }, [currencyFromId]);
  // const networks: INetwork[] = useMemo((): INetwork[] => {
  //   const network = type === "from" ? networkOff : networkTo;
  //   const list = [
  //     ...networksList.filter((item) => item.id === network),
  //     ...networksList.filter(
  //       (item) => item.id !== networkTo && item.id !== networkOff
  //     ),
  //   ];

  //   return list;
  // }, [networkTo, networkOff, networksList, type]);

  const networks = networksList;

  const filteredNetwork: INetwork[] = useMemo(() => {
    if (search) {
      return networks.filter((el) =>
        el.name.toLowerCase().startsWith(search.toLowerCase())
      );
    } else {
      return networks;
    }
  }, [networks, search]);

  const networksByCategory = useMemo(() => {
    return filteredNetwork.reduce((prev, cur) => {
      if (cur.category in prev) {
        prev[cur.category].push(cur);
      } else {
        prev[cur.category] = [cur];
      }

      return prev;
    }, {} as Record<string, INetwork[]>);
  }, [filteredNetwork]);

  const onNetworkPress = (el: INetwork) => {
    setNetwork(el.id);
    handleClose();
  };
  const stopEvent = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.stopPropagation();
  };

  const handleMarginTop = () => {
    const windowHeight = window.innerHeight;
    if (windowHeight > 900) {
      setModalMarginTop('40%');
    } else if (windowHeight < 900 && windowHeight > 800) {
      setModalMarginTop('15%');
    } else if (windowHeight < 800 && windowHeight > 750) {
      setModalMarginTop('5%');
    } else {
      setModalMarginTop('1%');
    }
  };

  useEffect(() => {
    handleMarginTop();
  }, []);

  const toList = useMemo(() => {
    const selectedTo = networks.filter((item) => item.id === networkTo);
    const list = [
      ...networks
        .slice(networkListIndexes.toStartIndex)
        .filter((item) => item.id !== networkTo && item.id !== networkOff),
    ];

    list.unshift(...selectedTo);

    return list;
  }, [networks, networkOff, networkTo]);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Body onClick={handleClose} className=" bg-transparent pt-5">
        <div
          onClick={stopEvent}
          className=" formModalBg defaultRadius select-source-modal-mt mainSendModal-bottom-shadow gradient-border-mask select-source-modal-max-width"
          style={{
            marginTop: offsetTop ?? modalMarginTop,
            marginBottom: offsetTop ? 0 : undefined,
          }}
        >
          <Form className="formBody text-white defaultRadius align-items-center select-source-modal-padding select-source-modal-size">
            <div className="d-flex flex-column">
              <div
                className="d-flex  align-items-center justify-content-center text-white mb-2"
                style={{ fontSize: '18px', fontWeight: 700 }}
              >
                {title}
              </div>
              <div className="d-flex flex-row position-relative w-100 ">
                <img
                  src={searchIcon}
                  className="select-source-search-icon"
                  alt="search"
                />
                <input
                  type="search"
                  placeholder={'Search by name'}
                  className="my-4 w-100 select-source-input"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <Simplebar
                style={{
                  maxHeight: '326px',
                  paddingRight: '10px',
                  height: '100%',
                }}
              >
                {isConnected && (
                  <div className="network-balance-info">
                    Balance in{' '}
                    <span className="network-balance-info-currency">
                      <div className="network-balance-info-currency-icon">
                        <img
                          src={BASE_URL + selectedCurrency?.image_url}
                          alt=""
                        />
                      </div>
                      {selectedCurrency?.symbol === 'WETH'
                        ? 'ETH'
                        : selectedCurrency?.symbol}
                      <BalanceTooltip>
                        <span className="network-balance-info-tooltip-trigger">
                          ?
                        </span>
                      </BalanceTooltip>
                    </span>
                  </div>
                )}
                <div className="select-source-modal-bottom ">
                  {Object.keys(networksByCategory)
                    .sort((a, b) => {
                      const aPoints = categorySortPoints(a);
                      const bPoints = categorySortPoints(b);

                      return bPoints - aPoints;
                    })
                    .map((category) => {
                      return (
                        <NetworksList
                          key={category}
                          setNetwork={onNetworkPress}
                          networks={networksByCategory[category]}
                          networkOff={networkOff}
                          handleClose={handleClose}
                          category={
                            category === 'undefined' ? undefined : category
                          }
                        />
                      );
                    })}
                </div>
              </Simplebar>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ChooseNetworkModal;
