import { Link } from 'react-router-dom';
import { ROUTES } from '../../constants/routes.constants';
import './styles.css';

export function MobileMenuNetworkSwitch() {
  return (
    <div className="mobile-menu--network-tabs testnet">
      <Link to={ROUTES.MAINNET_PAGE} className="mobile-menu--network-tabs-item">
        Mainnet
      </Link>
      <Link to="" className="mobile-menu--network-tabs-item">
        Testnet
      </Link>
    </div>
  );
}
