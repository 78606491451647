import { KeyboardEvent, useMemo, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import ArrowDown from '../../../Assets/Icons/arrow-down.svg';
import { BASE_URL } from '../../../Config/ApiConfig';
import { useWalletStore } from '../../../stores/wallet.store';
import { ICurrency } from '../../../types/apiTypes';
import '../SendPage.css';
function InputControl({
  value,
  setValue,
  currency,
  setCurrency,
  currencyArr,
  userBalance,
}: {
  value: string;
  setValue: (value: string) => void;
  currency: string;
  setCurrency?: (currency: ICurrency) => void;
  currencyArr: ICurrency[];
  userBalance: number;
}) {
  const { setReceiveAmount } = useWalletStore();

  const [arrowRight, setArrowRight] = useState(8);

  const btnTitle = useMemo(() => {
    if (currencyArr?.length > 0) {
      return (
        currencyArr
          .find((el) => el.id === currency)
          ?.symbol.split(' ')
          .slice(-1) ?? ''
      );
    } else {
      return '';
    }
  }, [currencyArr, currency]);

  const btnSrc = useMemo(() => {
    if (currencyArr?.length > 0) {
      return BASE_URL + currencyArr.find((el) => el.id === currency)?.image_url;
    } else {
      return ``;
    }
  }, [currencyArr, currency]);

  const setMax = () => {
    if (!currency) return;

    let max = currencyArr.find((el) => el.id === currency)?.max_send;
    if (max) {
      if (userBalance > +max) {
        setValue(String(max));
      } else if (userBalance <= +max) {
        setValue(String(userBalance));
      }
    }
  };

  const onClick = (el: ICurrency) => {
    if (el && el.id) {
      setValue('');
      setCurrency && setCurrency(el);
      setReceiveAmount('');
    }
  };

  const onArrowDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const belowZero = Number(value) - 1 < 0;
    if (e.key === 'ArrowDown' && belowZero) {
      e.preventDefault();
    }
  };

  const calculateArrowPosition = (element: HTMLDivElement) => {
    if (!element) return;
    const elementWidth = element.getBoundingClientRect().width;
    if (elementWidth > 50) {
      setArrowRight(2);
    } else {
      setArrowRight(8);
    }
  };

  const handleImput = (inputValue: string) => {
    if (/^[0-9]*(\.([0-9]+)?)?$/.test(inputValue)) {
      setValue(inputValue);
    } else {
      return;
    }
  };

  return (
    <>
      <style type="text/css">
        {`
    .btn-flat {
      background-color: #ffffff20;
      color: white;
      height:4rem;
      font-size:1rem;
      border-bottom-right-radius: 15px;
      border-top-right-radius: 15px;
      border-bottom-left-radius: 0px;
      border-top-left-radius: 0px;
     width:120px;
    }
    
   
    `}
      </style>
      <div className="d-flex inputGroup coin-input-group text-white ">
        <div className="coinInputDiv">
          <input
            className="coinInput"
            placeholder="0.00"
            onChange={(e) => handleImput(e.target.value)}
            value={value}
            onKeyDown={onArrowDown}
          />
          {currency && (
            <div onClick={setMax} className="maxBtn">
              Max
            </div>
          )}
        </div>

        <Dropdown>
          <Dropdown.Toggle
            variant="flat"
            id="input-group-dropdown-2 "
            className="font1 d-flex align-items-center dropdown overflow-hidden position-relative"
          >
            <div className="d-flex align-items-center">
              <div
                className={`smallCircleBtn ${
                  btnSrc
                    ? 'smallCircleBtnCoinIcon'
                    : 'smallCircleBtnCoinIconHidden'
                } me-1`}
              >
                {btnSrc && <img src={btnSrc} className="coinImg" alt="" />}
              </div>
              <div ref={calculateArrowPosition} className="mx-1">
                {' '}
                {btnTitle}
              </div>
            </div>
            <div
              className="d-flex align-items-center input-dropdown-arrow"
              style={{ right: arrowRight + 'px' }}
            >
              <img src={ArrowDown} alt="" />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu align="end" className="super-colors ">
            {currencyArr.map((el) => {
              return (
                <Dropdown.Item
                  key={el.id}
                  onClick={() => (setCurrency ? onClick(el) : null)}
                  className={`d-flex justify-content-between ${
                    !el.active ? 'disabled-currency' : ''
                  }`}
                  style={{ paddingTop: '9px', paddingBottom: '9px' }}
                >
                  <div className="d-flex align-items-center">
                    <div className="smallCircleBtn smallCircleBtnCoinIcon me-2">
                      <img
                        src={BASE_URL + el.image_url}
                        className="coinImg"
                        alt="coin"
                      />
                    </div>
                    <div className="ps-1 card-text-2 text-white me-2 tokens-dropdown-text-size">
                      {el.symbol}
                    </div>
                  </div>
                  {el.active && (
                    <div
                      className={` smallCircleBtn  small-checkbox ${
                        el.id === currency && 'checked'
                      }`}
                    >
                      {el.id === currency && <div className="smallCircle" />}
                    </div>
                  )}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
}

export default InputControl;
