import { starknetWallets } from '@/starknet/constants/wallets.constant';
import { logInGroup } from '@/utils';
import { OptionsProps } from '..';

export function StarknetOptions({ onOpenChange }: OptionsProps) {
  return (
    <div className="connect-wallet--group">
      <div className="connect-wallet--group-heading">Starknet wallets</div>
      <div className="connect-wallet--group-grid">
        {starknetWallets.map((item, i) => (
          <button
            className="connect-wallet--group-item"
            key={item.id}
            onClick={async () => {
              try {
                onOpenChange(false);
              } catch (error) {
                if (error instanceof Error) {
                  logInGroup('[Connect wallet]', error.message);
                }
              }
            }}
          >
            <img
              src={item.icon}
              style={{ height: '24px', width: '24px' }}
              alt=""
            />
            {item.name}
          </button>
        ))}
      </div>
    </div>
  );
}
