import { stage } from '../Config/ApiConfig';

const validDomain = {
  development: 'retrobridge.on.fleek.co',
  staging: '.retrobridge.dev',
  production: '.retrobridge.io',
};

export function setCookie(name: string, value: string, days = 365) {
  let expires = '';
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie =
    name +
    '=' +
    (value || '') +
    expires +
    `; domain=${validDomain[stage]}` +
    '; path=/';
}

export function getCookie(name: string) {
  var nameEQ = name + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
