import { useEffect, useMemo, useState } from 'react';
import { useConnectedIcon } from '../../../../hooks/useConnectedIcon';
import './styles.css';
import clsx from 'clsx';
import { useAccount } from 'wagmi';
import { shortAddress } from '../../../../utils';
import { AnimatePresence, motion } from 'framer-motion';
import { isValidAddress } from '../../../../evm/utils/isValidAddress';
import InvalidAddressIcon from '../../../../Assets/Icons/invalid-address.svg';
import ValidAddressIcon from '../../../../Assets/Icons/valid-address.svg';
import { useFaucetStore } from '../../store';

enum AddressTab {
  CONNECTED = 'connected',
  OTHER = 'other',
}

export function SelectAddress() {
  const { address } = useAccount();
  const [otherAddress, setOtherAddress, setError] = useFaucetStore((s) => [
    s.otherAddress,
    s.setOtherAddress,
    s.setError,
  ]);
  const [tab, setTab] = useState<AddressTab>(AddressTab.CONNECTED);
  const connectedIcon = useConnectedIcon();

  useEffect(() => {
    if (tab === AddressTab.CONNECTED) {
      setOtherAddress('');
    }
  }, [tab]);

  const isOtherAddressValid = useMemo(() => {
    const res = isValidAddress(otherAddress);
    if (otherAddress.length > 0 && !res) {
      setError('We couldn’t find this address, please check and try again.');
    } else {
      setError(undefined);
    }
    return res;
  }, [otherAddress]);

  const handleTabChange = (tab: AddressTab) => {
    setTab(tab);
    setError(undefined);
  };

  return (
    <div className="faucet-form--address-group">
      <label className="faucet-form--label">Select Receiving Address</label>
      <div className="d-flex gap-2">
        <button
          className={clsx(
            'faucet-form--address-group--item',
            tab === 'connected' && 'active'
          )}
          onClick={() => handleTabChange(AddressTab.CONNECTED)}
        >
          <img src={connectedIcon} className="z-1" alt="" />
          <span className="d-flex align-items-start flex-column z-1">
            <span>Connected Wallet</span>
            <span className="faucet-form--address">
              {address && shortAddress(address, 4)}
            </span>
          </span>
        </button>
        <button
          className={clsx(
            'faucet-form--address-group--item',
            tab === 'other' && 'active'
          )}
          onClick={() => handleTabChange(AddressTab.OTHER)}
        >
          <span className="z-1">Select Other Address</span>
        </button>
      </div>
      <AnimatePresence>
        {tab === 'other' && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            exit={{ height: 0 }}
            style={{ overflow: 'hidden' }}
          >
            <label className="faucet-form--address-input-container">
              <input
                type="text"
                className="faucet-form--address-input"
                placeholder="Enter your address here"
                value={otherAddress}
                onChange={(e) => setOtherAddress(e.target.value)}
              />
              {otherAddress.length > 0 && (
                <img
                  src={
                    isOtherAddressValid ? ValidAddressIcon : InvalidAddressIcon
                  }
                  alt="Other Address feedback"
                  className="faucet-form--address-input-feedback"
                />
              )}
            </label>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
