import { useAccount } from 'wagmi';
import { useConnectedIcon } from '../../../hooks/useConnectedIcon';
import { shortAddress } from '../../../utils';
import '../SendPage.css';
import WalletAddressInputl, {
  WalletAddressInputProps,
} from './walletIAddressInput';

export type WalletButtonsProps = WalletAddressInputProps & {
  inputClass: 'hidden' | 'showInput';
  setInputClass: (arg: 'hidden' | 'showInput') => void;
};
function WalletBtns({
  inputClass,
  setInputClass,
  ...props
}: WalletButtonsProps) {
  const icon = useConnectedIcon();
  const { address: account } = useAccount();

  const onClickConnected = () => {
    setInputClass('hidden');
  };

  const onClickInputAddress = () => {
    setInputClass('showInput');
  };

  if (!account) {
    return (
      <div
        className={`${'d-none'} d-flex justify-content-between inputGroup mb-3 text-white w-100 text-nowrap`}
      />
    );
  }

  return (
    <>
      <div
        className={`d-flex justify-content-between inputGroup text-white w-100 text-nowrap select-receiving-wallet-wrapper`}
      >
        <div
          onClick={onClickConnected}
          className={` leftWalletBtnDiv ${
            inputClass === 'hidden' ? '' : 'btn'
          } d-flex  align-items-center ${
            inputClass === 'hidden' && 'gradient'
          } leftWalletBtnDiv_connected`}
        >
          <div className="d-flex coinBtn wallet-icon-size me-2">
            <img
              width="20px"
              height="20px"
              src={icon}
              alt="network icon networkSmallIcon"
            />
          </div>
          <div className="d-flex flex-column">
            <div
              className="text-white fw-bold wallet-btns-text-size connected-wallet-text"
            >
              Connected Wallet
            </div>
            <div
              className=" fw-medium blanco address connected-address"
            >{`${shortAddress(account)}`}</div>
          </div>
        </div>
        <div
          onClick={onClickInputAddress}
          className={`btn rightWalletBtnDiv text-white wallet-btns-text-size ${
            inputClass !== 'hidden' && 'gradient'
          }`}
        >
          Select Other Address
        </div>
      </div>
      <WalletAddressInputl {...props} />
    </>
  );
}

export default WalletBtns;
