import emptyTable from "../../../Assets/Icons/emptyTable.svg";
const EmptyTablePlaceHolder = () => {
  return (
    <div className="emptyTablePlaceholder">
      <img src={emptyTable} alt="" className="emptyTableImage" />
      <span className="emptyTableText">There are no recent transactions</span>
    </div>
  );
};
export default EmptyTablePlaceHolder;
