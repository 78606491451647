import { useMemo } from 'react';
import { FeeTooltip } from '../../../components/tooltips/FeeTooltip';
import { OrderStatuses } from '../../../types/enums';
import { getMinimalDecimalNumber } from '../../../utils/numbers';
import '../Explorer.css';

const ROUND_TOKENS = ['usdc', 'usdt'];

function ExplorerModalInfoBlock({
  status,
  destinationFee,
  time,
  destinationCurrency,
}: {
  status: OrderStatuses;
  destinationFee: number;
  time: string;
  destinationCurrency: string;
}) {
  const getStatusColor = useMemo(() => {
    switch (status) {
      case OrderStatuses.COMPLETE:
        return 'greenColor';
      case OrderStatuses.DEPOSITED:
      case OrderStatuses.PENDING:
      case OrderStatuses.WAIT_DEPOSIT:
      case OrderStatuses.SENDING:
      case OrderStatuses.SENT:
        return 'orangeColor';
      default:
        return 'redColor';
    }
  }, [status]);

  const shouldRoundTokenDestination = ROUND_TOKENS.includes(
    destinationCurrency.toLowerCase()
  );

  return (
    <div
      className={`overflow-hidden ps-4 p-3  mb-4 textSecondary fs-6 transitionHeight d-flex flex-column  p-2  infoBlock infoBlockcontainer`}
    >
      <div className="height35 d-flex flex-row justify-content-between  align-items-center mb-2 ">
        <div className="  textSecondaryExplorerModal"> Status</div>

        <div className={`statusText ${getStatusColor}`}>{status}</div>
      </div>
      <div className="height35 d-flex flex-row justify-content-between  align-items-center textSecondaryExplorerModal mb-2">
        Destination Chain Fee
        <FeeTooltip />
        <div className=" text-light statusText">
          {`${
            shouldRoundTokenDestination
              ? Number(destinationFee).toFixed(2)
              : getMinimalDecimalNumber(destinationFee)
          } ${destinationCurrency}`}
        </div>
      </div>
      <div className="height35 d-flex flex-row justify-content-between align-items-center textSecondaryExplorerModal mb-2">
        Time Spent
        <div className=" text-light statusText">{time}</div>
      </div>
    </div>
  );
}

export default ExplorerModalInfoBlock;
