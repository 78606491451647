import { create, useStore } from 'zustand';
import {
  ICurrency,
  INetwork,
  IOrder,
  ITransactionFee,
} from '../types/apiTypes';

export type WalletStore = {
  privKey: string;
  networkFrom: string;
  networkTo: string;
  networksList: INetwork[];
  loading: boolean;
  skip: number;
  currencyFromId: string;
  currencyFromList: ICurrency[];
  amount: string;
  setAmount: (amount: string) => void;
  receiveAmount: string;
  receiverWallet: string;
  currentOrder: IOrder | null;
  orders: IOrder[];
  setOrders: (orders: IOrder[]) => void;
  setReceiverWallet: (receiverWallet: string) => void;
  setReceiveAmount: (amount: string) => void;
  address: string;
  walletTo: string;
  canceled: boolean;
  setCanceled: (canceled: boolean) => void;
  setCurrentOrder: (currentOrder: IOrder) => void;
  setWalletTo: (wallet: string) => void;
  setNetworkList: (networksList: INetwork[]) => void;
  setSkip: (skip: number) => void;
  setLoading: (loading: boolean) => void;
  setNetworkFrom: (network: string) => void;
  setNetworkTo: (network: string) => void;
  setCurrencyTo: (currencyFromId: string) => void;
  setCurrencyFromList: (currencyFromId: ICurrency[]) => void;
  currencyPair: ICurrency[];
  setCurrencyPair: (currencyPair: ICurrency[]) => void;
  clearStore: () => void;

  allCurrencyPairs: ICurrency[];
  setAllCurrencyPairs: (currencyPairs: ICurrency[]) => void;

  transactionCurrencyFee: ITransactionFee | null;
  settransactionCurrencyFee: (value: ITransactionFee | null) => void;

  ongoingOrders: IOrder[];
  addOngoingOrder: (order: IOrder, shouldUpdate?: boolean) => void;
  setOngoingOrder: (orderId: IOrder['id'], order: IOrder | undefined) => void;
  clearOngoingOrder: () => void;
  setOngoingOrders: (orders: IOrder[]) => void;

  isDisabled: boolean;
  setIsDisabled: (isDisabled: boolean) => void;

  NFTDiscount: {
    nftName: 'string';
    networkFee: 0;
  } | null;

  setNFTDiscount: (nftDiscount: WalletStore['NFTDiscount']) => void;

  isWaitingForConfirmation: boolean;
  setIsWaitingForConfirmation: (isWaitingForConfirmation: boolean) => void;
};

const CLEARED_STORE_VALUES = {
  amount: '',
  address: '',
  currentOrder: null,
  currencyFromId: '',
  currencyFromList: [],
  currencyPair: [],
  loading: false,
  networkFrom: '',
  networkTo: '',
  networksList: [],
  privKey: '',
  receiveAmount: '',
  skip: 0,
  transactionToken: null,
  walletTo: '',
  receiverWallet: '',
  orders: [],
  canceled: false,
  transactionCurrencyFee: null,
  allCurrencyPairs: [],
};

export const walletStore = create<WalletStore>()((set, get) => ({
  amount: '',
  address: '',
  currentOrder: null,
  currencyFromId: '',
  currencyFromList: [],
  currencyPair: [],
  loading: false,
  networkFrom: '',
  networkTo: '',
  networksList: [],
  privKey: '',
  receiveAmount: '',
  skip: 0,
  transactionToken: null,
  walletTo: '',
  receiverWallet: '',
  orders: [],
  canceled: false,
  transactionCurrencyFee: null,
  allCurrencyPairs: [],
  ongoingOrders: [],
  isDisabled: false,
  NFTDiscount: null,
  settransactionCurrencyFee: (value: ITransactionFee | null) =>
    set({ transactionCurrencyFee: value }),
  setCanceled: (canceled: boolean) => set({ canceled }),
  setOrders: (orders) => set({ orders }),
  setCurrentOrder: (currentOrder: IOrder) => set({ currentOrder }),
  setReceiverWallet: (receiverWallet: string) => set({ receiverWallet }),
  setWalletTo: (walletTo: string) => set({ walletTo }),
  setCurrencyPair: (currencyPair) => set({ currencyPair }),
  setNetworkList: (networksList) => set({ networksList }),
  setSkip: (skip: number) => set({ skip }),
  setLoading: (loading: boolean) => set({ loading }),
  setNetworkFrom: (networkFrom) => set({ networkFrom: networkFrom }),
  setNetworkTo: (networkTo) => set({ networkTo: networkTo }),
  setCurrencyTo: (currencyFromId: string) =>
    set({ currencyFromId: currencyFromId }),
  setCurrencyFromList: (currencyFromList: ICurrency[]) =>
    set({ currencyFromList }),
  setAmount: (amount: string) => set({ amount }),
  setReceiveAmount: (receiveAmount: string) => set({ receiveAmount }),
  clearStore: () => set(CLEARED_STORE_VALUES),
  setAllCurrencyPairs: (currencyPairs: ICurrency[]) =>
    set({ allCurrencyPairs: currencyPairs }),
  addOngoingOrder: (order, shouldUpdate = false) => {
    const orderFromStore = get().ongoingOrders.find((o) => o.id === order.id);

    if (orderFromStore) {
      if (!shouldUpdate) return;

      get().setOngoingOrder(order.id, order);
      return;
    }

    const res = [order, ...get().ongoingOrders];
    set({
      ongoingOrders: res,
    });
  },
  setOngoingOrder: (orderId, order) => {
    const orders = get().ongoingOrders;

    if (!order) {
      const res = orders.filter((o) => o.id !== orderId);
      set({ ongoingOrders: res });
    } else {
      const res = orders.map((o) => (o.id === orderId ? order : o));
      set({ ongoingOrders: res });
    }
  },
  clearOngoingOrder: () => {
    set({ ongoingOrders: [] });
  },
  setOngoingOrders: (orders) => {
    set({ ongoingOrders: orders });
  },
  setIsDisabled: (isDisabled: boolean) => set({ isDisabled }),

  setNFTDiscount: (nftDiscount) => set({ NFTDiscount: nftDiscount }),

  isWaitingForConfirmation: false,
  setIsWaitingForConfirmation: (isWaitingForConfirmation) =>
    set({ isWaitingForConfirmation }),
}));

export const useWalletStore = () => useStore(walletStore);
