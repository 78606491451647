import { useMemo } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import arrowDown from '../../../../Assets/Icons/arrowDown.svg';
import checkGreen from '../../../../Assets/Icons/checkGreen.svg';
import globe from '../../../../Assets/Icons/globe.svg';
import redClose from '../../../../Assets/Icons/redClose.svg';
import discord from '../../../../Assets/Icons/discord.svg';
import { BASE_URL, stage } from '../../../../Config/ApiConfig';
import {
  GET_POPUP_PARAMS,
  GET_POPUP_TYPE,
} from '../../../../constants/popup.constants';
import { ROUTES } from '../../../../constants/routes.constants';
import { isDevelopment } from '../../../../services/api';
import {
  getPrice,
  usePriceUpdater,
} from '../../../../stores/price_updater.store';
import { useWalletStore } from '../../../../stores/wallet.store';
import { OrderStatuses } from '../../../../types/enums';
import { isMobile } from '../../../../utils';
import { toFixed } from '../../../../utils/numbers';
import './StatusPage.css';
import { NetworkInfo } from './components/networkInfo';
import {
  useAccount,
  useNetwork,
  useSwitchNetwork,
  useToken,
  useWalletClient,
} from 'wagmi';
import { EthersError } from '../../../../types/ethers';
import { errors } from 'ethers';
import { NetworkTypes } from '../../../../providers/web3Provider';
import { useNotify } from '../../../../hooks/useToast';
import { DAppBanner } from './components/DAppBanner';

function StatusForm() {
  const navigate = useNavigate();

  const { currentOrder, canceled, setCanceled } = useWalletStore();

  const { prices } = usePriceUpdater();

  const { isConnected } = useAccount();
  const { chain } = useNetwork();
  const { switchNetworkAsync } = useSwitchNetwork();
  const { data: walletClient } = useWalletClient();
  const { notify } = useNotify();
  const outChainId = currentOrder?.currency_out.contract.network.chainId;
  const { data: tokenOut } = useToken({
    address:
      (currentOrder?.currency_out.contract.address as `0x${string}`) ?? '0x',
    chainId: outChainId ? +outChainId : undefined,
  });

  const amountInUsd = useMemo(() => {
    if (!currentOrder) {
      return 0;
    }
    return (
      getPrice(currentOrder.currency_in.symbol, prices).usd *
      currentOrder.amount_in
    );
  }, [currentOrder, prices]);

  const amountOutUsd = useMemo(() => {
    if (!currentOrder) {
      return 0;
    }
    return (
      getPrice(currentOrder.currency_out.symbol, prices).usd *
      currentOrder.amount_out
    );
  }, [currentOrder, prices]);

  if (!currentOrder) {
    return (
      <div className=" formBg defaultRadius  ">
        <Form className="transitionHeight formBody text-light  bg-dark bg-opacity-25   defaultRadius align-items-center ">
          <div className="transferInfoTitle">Waiting for order loading...</div>
        </Form>
      </div>
    );
  }
  const twoStepNavigate = () => {
    setCanceled(false);

    if (isDevelopment || process.env.REACT_APP_ONE_DOMAIN) {
      navigate(
        `${ROUTES.EXPLORER_PAGE}?${GET_POPUP_PARAMS.popup}=${GET_POPUP_TYPE.popup.order}&${GET_POPUP_PARAMS.orderId}=${currentOrder.id}`
      );
    } else {
      window.open(
        `${ROUTES.EXPLORER_PAGE}?${GET_POPUP_PARAMS.popup}=${GET_POPUP_TYPE.popup.order}&${GET_POPUP_PARAMS.orderId}=${currentOrder.id}`,
        '_blank'
      );
    }
  };

  const rejected =
    canceled ||
    currentOrder.status === OrderStatuses.REJECTED ||
    currentOrder.status === OrderStatuses.EXPIRED;

  const shouldAddToken =
    isConnected &&
    currentOrder.status === OrderStatuses.COMPLETE &&
    currentOrder.currency_out.contract.network.network_type !==
      NetworkTypes.STARKNET &&
    currentOrder.currency_out.symbol.toLowerCase() !== 'eth';

  const handleAddToken = async () => {
    if (!chain || !switchNetworkAsync || !walletClient || !tokenOut) return;

    try {
      if (chain.id !== +currentOrder.currency_out.contract.network.chainId) {
        await switchNetworkAsync(
          +currentOrder.currency_out.contract.network.chainId
        );
      }

      /* 
        Wagmi has bug with walletClient type
        so we need to use any type for now
      */
      const success: boolean = await (walletClient as any).watchAsset({
        type: 'ERC20',
        options: {
          address: currentOrder.currency_out.contract.address,
          symbol: tokenOut.symbol,
          decimals: currentOrder.currency_out.decimals,
          image: BASE_URL + currentOrder.currency_out.image_url,
        },
      });

      if (success) {
        notify({
          meassage: 'Token added successfully',
          type: 'success',
        });
      }
    } catch (error) {
      if ((error as EthersError).code === errors.ACTION_REJECTED) return;
      console.warn(error);
    }
  };

  const onBtnClick = async () => {
    if (rejected) {
      const params = new URLSearchParams();
      params.set('source', currentOrder.currency_in.contract.network.name);
      params.set(
        'destination',
        currentOrder.currency_out.contract.network.name
      );

      setCanceled(false);
      window.open(ROUTES.SEND_PAGE + '?' + params.toString(), '_self');
      return;
    }

    setCanceled(false);
    navigate(ROUTES.SEND_PAGE);
  };

  return (
    <div className="mt-0 formBg defaultRadius gradient-border-mask status-success-form-margin status-success-width">
      <Form
        className={`transitionHeight formBody text-light  ${
          !isMobile && 'bg-dark'
        } bg-opacity-25 d-flex h-100 flex-column   defaultRadius align-items-center statusForm`}
      >
        {rejected ? (
          <div className="rejectCircle center">
            <img src={redClose} alt="" className="redClose" />
          </div>
        ) : (
          <div className="greenCircle">
            <img src={checkGreen} alt="" className="greenCheck" />
          </div>
        )}
        <div className="statusTitle">
          {rejected ? 'Transfer Rejected' : 'Transfer Completed'}
        </div>
        <div className="statusSubtitle">
          <span>
            {rejected
              ? 'Unfortunately, your recent attempt to'
              : 'Your swap was successfully completed.'}
          </span>
          <br />
          <span>
            {rejected
              ? 'transfer has been declined'
              : `${toFixed(currentOrder.amount_out ?? 0, 5)} ${
                  currentOrder.currency_out.symbol
                } has been sent to your address`}
          </span>
        </div>

        {!rejected && (
          <div className="center">
            <Button
              variant="link"
              className="backBtn ms-auto me-auto view-in-explorer-btn"
              onClick={twoStepNavigate}
            >
              <div className="d-flex align-items-center">
                <img src={globe} className="globe" alt="back button" />
                <div className="ms-2 view-in-explorer-btn-text">
                  View In RetroBridge Explorer
                </div>
              </div>
            </Button>
          </div>
        )}

        <div className="transactionInfoDableDiv w-100">
          <div className="infoDiv ">
            <NetworkInfo
              netwSrc={
                BASE_URL +
                currentOrder.currency_in.contract.network.network_image_url
              }
              netw={currentOrder.currency_in.contract.network.name ?? ''}
              wallet={currentOrder.wallet_sender!}
              amount={`${currentOrder.amount_in}`}
              amountUsd={`${amountInUsd}`}
              coinSimbol={currentOrder.currency_in.symbol ?? ''}
            />
          </div>
          <div className="circle circle-bg-success-page">
            <div className="transactionInfoDableDivCircle">
              <img className="arrowDownIcon" src={arrowDown} alt="arrow" />
            </div>
          </div>

          <div className="infoDiv">
            <NetworkInfo
              netwSrc={
                BASE_URL +
                currentOrder.currency_out.contract.network.network_image_url
              }
              netw={currentOrder.currency_out.contract.network.name ?? ''}
              wallet={currentOrder.wallet_receiver ?? ''}
              amount={`${currentOrder.amount_out}`}
              amountUsd={`${amountOutUsd}`}
              coinSimbol={currentOrder.currency_out.symbol}
            />
          </div>
        </div>

        <DAppBanner network={currentOrder.currency_out.contract.network} />

        <div className="transfer-btn-group d-grid grid-cols-1 gap-2">
          <Button
            onClick={onBtnClick}
            variant="outline-secondary"
            className={`py-2 width258 create-another-transfer-button mb-0 bold`}
          >
            Create Another Transfer
          </Button>
          {rejected && (
            <div className="d-flex justify-content-center align-items-center ms-auto me-auto mt-2">
              <a
                href="http://discord.gg/retrobridge"
                target="_blank"
                rel="noopener noreferrer"
                className="progressAction"
              >
                <img src={discord} alt="" />
                Support
              </a>
            </div>
          )}
          {shouldAddToken && (
            <button
              type="button"
              className="btn py-2 add-token-button"
              onClick={handleAddToken}
            >
              Add{' '}
              <span className="add-token-button-icon">
                <img
                  src={`${BASE_URL}${currentOrder.currency_out.image_url}`}
                  alt=""
                />
              </span>{' '}
              {currentOrder.currency_out.symbol} to your wallet
            </button>
          )}
        </div>
        {shouldAddToken && (
          <div className="center">
            <a
              href={`${currentOrder.currency_out.contract.network.blockscan_url}/address/${currentOrder.currency_out.contract.address}`}
              target="_blank"
              rel="noopener noreferrer"
              className="add-token-contract"
            >
              Contract *{currentOrder.currency_out.contract.address.slice(-5)}
            </a>
          </div>
        )}
      </Form>
    </div>
  );
}

export default StatusForm;
