import { AxiosError } from 'axios';
import { ethers } from 'ethers';
import { parseEther } from 'ethers/lib/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import {
  useAccount as useEVMAccount,
  useNetwork as useEVMNetwork,
  useSwitchNetwork,
} from 'wagmi';
import arrowback from '../../Assets/Icons/arrowBack.svg';
import arrowDown from '../../Assets/Icons/arrowDown.svg';
import { BASE_URL } from '../../Config/ApiConfig';
import InfoBlock from '../../components/infoBlock';
import { PROGRESS_PAGE } from '../../constants/routes.constants';
import { useConnectedIcon } from '../../hooks/useConnectedIcon';
import { useNotify } from '../../hooks/useToast';
import { useTransferInfo } from '../../hooks/useTransferInfo';
import { useWithdraw } from '../../hooks/useWithdraw';
import { NetworkTypes } from '../../providers/web3Provider';
import { api } from '../../services/api';
import { useAccount, useNetwork } from '../../starknet';
import { starknetNativeAddress } from '../../starknet/constants/contractAddresses.constant';
import { useStarknetConnectedIcon } from '../../starknet/hooks/useConnectedIcon';
import { useWalletStore } from '../../stores/wallet.store';
import { ICurrency } from '../../types/apiTypes';
import { logInGroup, shortAddress } from '../../utils';

import { getSendAmount } from '../../utils/nativeCurrencyRecalculate';
import { exponentNumberToString } from '../../utils/numbers';
import './TransferInfoPage.css';
import { NetworkInfo } from './components/networkInfo';

function TransferInfoForm({ closeConfirm }: { closeConfirm: () => void }) {
  const navigate = useNavigate();
  const { chain: evmChain } = useEVMNetwork();
  const { address: account } = useEVMAccount();
  const { address: starknetAddress } = useAccount();
  const { switchNetwork } = useSwitchNetwork();

  const { notify } = useNotify();
  const { chain } = useNetwork();

  const {
    amount,
    receiveAmount,
    receiverWallet,
    walletTo,
    isDisabled,
    setIsDisabled,
    setCanceled,
    setAmount,
    setReceiveAmount,
    setIsWaitingForConfirmation,
  } = useWalletStore();

  const { withdraw } = useWithdraw();

  const {
    currencyFromInfo,
    currencyToInfo,
    networkFromImage,
    netwFrom,
    netwTo,
    networkToImage,
    amountUsd,
    receiveAmountUsd,
    networkFromInfo,
    networkToInfo,
  } = useTransferInfo();

  const ethIcon = useConnectedIcon();
  const starknetIcon = useStarknetConnectedIcon();

  const [infoBlockFromWidth, setInfoBlockFromWidth] = useState<number | null>(
    null
  );
  const [infoBlockToWidth, setInfoBlockToWidth] = useState<number | null>(null);

  const icon = useMemo(() => {
    if (networkToInfo?.network_type === NetworkTypes.STARKNET && starknetIcon) {
      return starknetIcon;
    }
    if (networkToInfo?.network_type === NetworkTypes.EVM && ethIcon) {
      return ethIcon;
    }
    return networkToInfo?.network_image_url
      ? BASE_URL + networkToInfo?.network_image_url
      : '';
  }, [starknetIcon, ethIcon, networkToInfo]);

  const setSendAmountHelper = useCallback(
    (sendAmount: string, currency: ICurrency) => {
      const sendAmountBn = parseEther(sendAmount);
      const minAmount = parseEther(exponentNumberToString(currency.min_send));
      const maxAmount = parseEther(exponentNumberToString(currency.max_send));

      const isLessThanMin = sendAmountBn.lt(minAmount);
      const isGreaterThanMax = sendAmountBn.gt(maxAmount);

      setAmount(sendAmount);

      if (isLessThanMin) {
        notify({
          meassage: 'The amount to send is less than the minimal limit',
          type: 'error',
        });
        return closeConfirm();
      }

      if (isGreaterThanMax) {
        notify({
          meassage: 'The amount to send exceeds the maximum limit',
          type: 'error',
        });
        return closeConfirm();
      }
    },
    []
  );

  const getSendAmountByAccount = useCallback(
    async (currency: ICurrency) => {
      const network = currency.contract.network;
      const currencyAddress = currency.contract.address.toLowerCase();

      setIsDisabled(true);

      if (
        network.network_type === NetworkTypes.STARKNET &&
        currencyAddress === starknetNativeAddress.toLowerCase() &&
        !!starknetAddress
      ) {
        const sendAmount = await getSendAmount(
          amount,
          starknetAddress,
          network
        );
        setSendAmountHelper(sendAmount, currency);
      }
      if (
        network.network_type !== NetworkTypes.STARKNET &&
        currencyAddress === ethers.constants.AddressZero.toLowerCase() &&
        !!account
      ) {
        const sendAmount = await getSendAmount(amount, account, network);
        setSendAmountHelper(sendAmount, currency);
      }

      setIsDisabled(false);
    },
    [account, starknetAddress, amount, setSendAmountHelper]
  );

  useEffect(() => {
    if (!walletTo.length) {
      closeConfirm();
    }
  }, [navigate, walletTo, account, starknetAddress]);

  useEffect(() => {
    if (currencyFromInfo) {
      getSendAmountByAccount(currencyFromInfo);
    }
  }, [currencyFromInfo, getSendAmountByAccount]);

  useEffect(() => {
    if (amount && !isNaN(+amount) && !!currencyFromInfo?.id) {
      api.getAmountOut(
        walletTo,
        currencyFromInfo.id,
        currencyToInfo?.id,
        amount
      );
    } else {
      setReceiveAmount('');
    }
  }, [
    setReceiveAmount,
    walletTo,
    amount,
    currencyToInfo.id,
    currencyFromInfo?.id,
  ]);

  const isWrongNetwork = useMemo(() => {
    if (!networkFromInfo) {
      return true;
    }
    if (networkFromInfo.network_type === NetworkTypes.STARKNET) {
      return chain?.id !== networkFromInfo.chainId;
    }
    if (!evmChain) {
      return true;
    }
    return evmChain.id !== +networkFromInfo.chainId;
  }, [evmChain, chain, networkFromInfo]);

  const switchNetworkHelper = useCallback(async () => {
    try {
      setIsDisabled(true);
      if (
        currencyFromInfo &&
        currencyToInfo &&
        networkFromInfo &&
        switchNetwork
      ) {
        switchNetwork(+networkFromInfo.chainId);
        // await changeNetworkAtMetamaskWithConfig(
        //   SUPPORTED_WALLETS.Metamask.connector,
        //   networkFromInfo
        // );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsDisabled(false);
    }
  }, [currencyFromInfo, currencyToInfo, networkFromInfo, switchNetwork]);

  useEffect(() => {
    api.getReceiver();
  }, []);

  const showBackendError = (error: AxiosError<any>) => {
    const defaultMessage = `Something went wrong. Please try again later.`;
    switch (error.response?.status) {
      case 503:
        return notify({
          title: `Temporary transfer limit`,
          meassage: `Destination network will become available shortly`,
          type: 'error',
        });
      // case 400:
      //   return notify({
      //     meassage: error.response.data?.message ?? defaultMessage,
      //     type: "error",
      //   })
      default:
        return notify({
          meassage: error?.response?.data?.message ?? defaultMessage,
          type: 'error',
        });
    }
  };

  const showSendError = (error: unknown) => {
    if (error instanceof AxiosError) {
      return showBackendError(error);
    }
    notify({
      meassage: `Something went wrong. Please try again later.`,
      type: 'error',
    });
  };

  const withdrawCurrencyHelper = useCallback(
    async (wallet_sender: string) => {
      let order;
      try {
        setIsDisabled(true);

        if (!currencyFromInfo || !currencyToInfo) {
          return;
        }

        order = await api.sendOrder(
          currencyFromInfo,
          currencyToInfo,
          amount,
          wallet_sender,
          walletTo
        );

        if (!order) {
          throw new Error(`Failed to create order`);
        }

        navigate(`${PROGRESS_PAGE}/${order.id}`);

        setIsWaitingForConfirmation(true);

        const result = await withdraw({
          sendedAmount: +amount,
          sendedCurrency: currencyFromInfo,
          network: networkFromInfo,
          walletReceiver: receiverWallet,
        });

        if (!result) {
          setCanceled(true);
        }
      } catch (error: any) {
        logInGroup('TransferInfoForm', error);
        if (
          (error.message === 'ACTION_REJECTED' ||
            error.message === "Can't create transaction") &&
          order &&
          networkFromInfo
        ) {
          api
            .closeOrder(order.id, networkFromInfo)
            .catch(() => console.warn('Failed to close order'));
        }
        showSendError(error);
        setCanceled(true);
      } finally {
        setIsWaitingForConfirmation(false);
        setIsDisabled(false);
      }
    },
    [
      withdraw,
      navigate,
      amount,
      currencyFromInfo,
      networkFromInfo,
      receiverWallet,
      currencyToInfo,
      walletTo,
      setCanceled,
    ]
  );

  const withdrawHelper = useCallback(async () => {
    if (
      networkFromInfo?.network_type === NetworkTypes.STARKNET &&
      starknetAddress
    ) {
      return await withdrawCurrencyHelper(starknetAddress);
    }
    if (account) {
      return await withdrawCurrencyHelper(account);
    }
  }, [networkFromInfo, withdrawCurrencyHelper, account, starknetAddress]);

  const onClickSendtransactionHandler = useCallback(() => {
    if (isWrongNetwork) {
      switchNetworkHelper();
    } else {
      withdrawHelper();
    }
  }, [isWrongNetwork, switchNetworkHelper, withdrawHelper]);

  return (
    <div className=" formBg defaultRadius gradient-border-mask form-max-width mt-0">
      <Form className="transitionHeight formBody text-light bg-opacity-25   defaultRadius align-items-center mobile-margin-bottom">
        <div className="transferInfoTitle">Your Transfer Details</div>

        <div className="transactionInfoDableDiv">
          <div className="infoDiv ">
            {networkFromInfo?.network_type === NetworkTypes.STARKNET &&
              !!starknetAddress && (
                <NetworkInfo
                  netwSrc={networkFromImage}
                  netw={netwFrom}
                  wallet={starknetAddress ?? ''}
                  amount={amount}
                  amountUsd={amountUsd}
                  coinSimbol={currencyFromInfo?.symbol ?? ''}
                  setWidth={setInfoBlockFromWidth}
                />
              )}
            {networkFromInfo?.network_type !== NetworkTypes.STARKNET &&
              !!account && (
                <NetworkInfo
                  netwSrc={networkFromImage}
                  netw={netwFrom}
                  wallet={account ?? ''}
                  amount={amount}
                  amountUsd={amountUsd}
                  coinSimbol={currencyFromInfo?.symbol ?? ''}
                  setWidth={setInfoBlockFromWidth}
                />
              )}
          </div>

          <div className="circle .circle-bg">
            <div className="transactionInfoDableDivCircle">
              <img className="arrowDownIcon" src={arrowDown} alt="arrow" />
            </div>
          </div>

          <div className="infoDiv">
            <NetworkInfo
              netwSrc={networkToImage}
              netw={netwTo}
              wallet={walletTo}
              amount={receiveAmount}
              amountUsd={receiveAmountUsd}
              coinSimbol={currencyToInfo?.symbol ?? ''}
              infoBlockFromWidth={infoBlockFromWidth}
              infoBlockToWidth={infoBlockToWidth}
              setWidth={setInfoBlockToWidth}
            />
          </div>
        </div>
        <div className="separator ms-auto me-auto " />
        <div className="transferinfoSubyitle">Amount You Sent</div>

        <div className="infoDiv mb-16">
          <div className="networkInfoLeftDiv">
            <div className="imgDiv20 me-3">
              <img
                className="coinImg"
                src={BASE_URL + currencyFromInfo?.image_url}
                alt="network icon"
              />
            </div>
            <div className="infoText ">{amount}</div>
          </div>
        </div>

        <InfoBlock />

        <div className="transferinfoSubyitle">Receiving Address</div>
        <div className="infoDiv">
          <div className="networkInfoLeftDiv">
            <div className="imgDiv20 me-3">
              <img className="coinImg" src={icon} alt="network icon" />
            </div>
            <div className="infoText ">{`${shortAddress(walletTo)}`}</div>
          </div>
        </div>
        <Button
          onClick={onClickSendtransactionHandler}
          variant="outline-secondary"
          className={`btn ${
            isDisabled ? 'disabled-link btnSecondary' : 'btnGradient'
          } w-100 my-4 py-3`}
        >
          {isWrongNetwork ? 'Switch Network' : 'Send Transfer'}
        </Button>

        <div className="center">
          <Button
            variant="link"
            onClick={closeConfirm}
            className="backBtn ms-auto me-auto"
          >
            <div className="d-flex">
              <img src={arrowback} className="arroBack" alt="back button" />
              <div className="ms-2">Back</div>
            </div>
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default TransferInfoForm;
