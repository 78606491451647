import { useEffect, useState } from 'react';
import SendBg from '../../Assets/images/send-bg-png.png';
import ExplorerMobileBg from '../../Assets/images/send-bg-small.svg';
import { ConnectWalletModalWithDisabled } from '../../components/ConnectWalletModalWithDisabled';
import Footer from '../../components/Footer';
import Header from '../../components/Header/header';
import { api } from '../../services/api';
import { FaucetForm } from './components/FaucetForm';
import { faucetStore, useFaucetStore } from './store';
import { faucetAPI } from '../../services/faucet';
import { useAccount } from 'wagmi';
import { Loader } from '../../components/Loader';

export function FaucetPage() {
  const [isWalletModalOpen, setIsWalletModalOpen] = useState(false);
  const [selectedNetwork] = useFaucetStore((s) => [s.selectedNetwork]);
  const { address } = useAccount();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    const { setSelectedNetwork } = faucetStore.getState();
    api.getNetwork().then((networks) => {
      if (networks.length > 0) {
        setSelectedNetwork(networks[0]);
      }
    });
  }, []);

  useEffect(() => {
    if (!selectedNetwork?.id || !address) return;

    faucetAPI.getBalance(address);
  }, [selectedNetwork, address]);

  useEffect(() => {
    if (!selectedNetwork?.id) return;
    const { setSelectedCurrency, setIsCurrencyLoading } =
      faucetStore.getState();
    setIsCurrencyLoading(true);
    faucetAPI
      .getCurrencyInfo(selectedNetwork.id)
      .then((currency) => {
        if (!currency) return;
        setSelectedCurrency(currency);
      })
      .finally(() => {
        setIsCurrencyLoading(false);
      });
  }, [selectedNetwork?.id]);

  return (
    <>
      <div className="page-background-color faucet-page">
        <div
          className={`explorerPageBg exlorerContainer textTable d-flex  flex-1 page-padding-bottom flex-column align-items-center pt-2 position-relative`}
        >
          <img src={SendBg} alt="" className="send-bg-img" />
          <img src={ExplorerMobileBg} alt="" className="send-bg-img-mobile" />
          <div className="explorer-header-container">
            <Header setOpenModal={setIsWalletModalOpen} />
          </div>

          {isLoading && <Loader />}
          {!isLoading && <FaucetForm />}

          <div className="explorer-footer-container">
            <Footer />
          </div>
        </div>
      </div>
      <ConnectWalletModalWithDisabled
        openModal={isWalletModalOpen}
        setOpenModal={setIsWalletModalOpen}
        networkType={selectedNetwork?.network_type}
        onlySelectedNetworkType
      />
    </>
  );
}
