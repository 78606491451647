import { SVGAttributes } from "react";

export function Globe(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.86436 2.59165C4.69915 3.10802 2.24715 5.74721 2.01758 9H5.52033C5.66391 6.67694 6.4812 4.45096 7.86436 2.59165ZM10.1356 2.59165C11.5188 4.45096 12.3361 6.67694 12.4797 9H15.9824C15.7529 5.74721 13.3009 3.10802 10.1356 2.59165ZM11.4776 9C11.3233 6.71301 10.4565 4.53162 9 2.7628C7.54349 4.53162 6.6767 6.71301 6.52241 9H11.4776ZM6.52241 10H11.4776C11.3233 12.287 10.4565 14.4684 9 16.2372C7.54349 14.4684 6.6767 12.287 6.52241 10ZM5.52033 10H2.01758C2.24715 13.2528 4.69915 15.892 7.86436 16.4083C6.4812 14.549 5.66391 12.3231 5.52033 10ZM10.1356 16.4083C11.5188 14.549 12.3361 12.3231 12.4797 10H15.9824C15.7529 13.2528 13.3009 15.892 10.1356 16.4083ZM9 17.5C13.4183 17.5 17 13.9183 17 9.5C17 5.08172 13.4183 1.5 9 1.5C4.58172 1.5 1 5.08172 1 9.5C1 13.9183 4.58172 17.5 9 17.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
