import dayjs from 'dayjs';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BASE_URL } from '../../../Config/ApiConfig';
import {
  GET_POPUP_PARAMS,
  GET_POPUP_TYPE,
} from '../../../constants/popup.constants';
import { ROUTES } from '../../../constants/routes.constants';
import { IOrder } from '../../../types/apiTypes';
import '../Explorer.css';
import { CoinIfo } from './coininfo';
import { Network } from './network';
import { StatusBtn } from './statusBtn';
import { Wallet } from './wallet';

export const ExplorerTable = ({ orders }: { orders: IOrder[] }) => {
  const getDetailsNavigate = (id: string) =>
    `${ROUTES.EXPLORER_PAGE}?${GET_POPUP_PARAMS.popup}=${GET_POPUP_TYPE.popup.order}&${GET_POPUP_PARAMS.orderId}=${id}`;

  return (
    <div className="d-flex explorerTable  textTable  w-100">
      <Table borderless className="tableExplorer w-100" striped hover>
        <thead className="ps-3 headlineText">
          <tr>
            <th style={{ width: '12%' }}>Token</th>
            <th className="cell-text-center statusTd">Status</th>
            <th style={{ width: '18%' }}>
              <div className="source-col">Source</div>
            </th>
            <th style={{ width: '16%' }}>Destination</th>
            <th className=" width10">From</th>
            <th className=" width10">To</th>
            <th
              className="cell-text-center text-center"
              style={{ width: '11.9%' }}
            >
              Date
            </th>
            <th
              className="cell-text-center text-center"
              style={{ width: '11.9%' }}
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody
          className="table-scroll-div w-100"
          style={{ display: 'block', maxHeight: '1000px' }}
          id="explorer-viewport"
        >
          {orders?.map((el) => {
            return (
              <tr key={el.id}>
                <td style={{ width: '12%' }}>
                  <CoinIfo coin={el} />
                </td>
                <td style={{ width: '12%' }}>
                  <StatusBtn status={el.status} />
                </td>
                <td style={{ width: '18%' }}>
                  <div className="source-col">
                    <Network
                      name={el.currency_in.contract.network.name}
                      url={
                        BASE_URL +
                        el.currency_in.contract.network.network_image_url
                      }
                    />
                  </div>
                </td>
                <td style={{ width: '16%' }}>
                  <Network
                    name={el.currency_out.contract.network.name}
                    url={
                      BASE_URL +
                      el.currency_out.contract.network.network_image_url
                    }
                  />
                </td>
                <td className="width10">
                  <Wallet wallet={el.wallet_sender} />
                </td>
                <td className="width10">
                  <Wallet wallet={el.wallet_receiver} />
                </td>

                <td
                  className="tableFont cell-text-center"
                  style={{ width: '12%' }}
                >
                  {dayjs(el.createdAt).format('DD/MM/YY HH:mm:ss')}
                </td>
                <td style={{ width: '12%' }} className="cell-text-center ">
                  <Link
                    to={getDetailsNavigate(el.id)}
                    className="textLinkTable "
                  >
                    View Details
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};
