const googleScript = document.createElement('script');
googleScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-505QX0Q87D';
googleScript.async = true;

const tagScript = document.createElement('script');
tagScript.innerHTML = `
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
  gtag('js', new Date());
  gtag('config', 'G-505QX0Q87D');
`;

document.body.append(googleScript);
document.body.append(tagScript);