import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import { ReactNode, useMemo } from 'react';
import { mockNetworks } from '../../../constants/mock_networks.constants';
import { useWalletStore } from '../../../stores/wallet.store';
import { INetwork } from '../../../types/apiTypes';
import './styles.css';

dayjs.extend(isToday);
dayjs.extend(isYesterday);

interface NewDestinationItemProps {
  children: ReactNode;
  chainId: INetwork['chainId'];
}

export function NewDestinationItem({
  children,
  chainId,
}: NewDestinationItemProps) {
  const { networksList: networks, setNetworkTo } = useWalletStore();

  const currentNetwork = useMemo(() => {
    return networks.find((network) => network.chainId === chainId);
  }, [networks, chainId]);

  const relativeDate = useMemo(() => {
    const value = dayjs(currentNetwork?.created_at);

    if (value.isToday()) {
      return 'Today';
    }
    if (value.isYesterday()) {
      return 'Yesterday';
    }

    return value.format('D MMM');
  }, [currentNetwork?.created_at]);

  const backgroundSrc = useMemo(() => {
    return mockNetworks.find((network) => network.chainId === chainId)
      ?.new_destination_bg;
  }, [chainId]);

  const handleClick = () => {
    if (currentNetwork) {
      setNetworkTo(currentNetwork.id);
    }
  };

  return (
    <div
      className="notifications-menu--new-destination"
      style={{
        backgroundImage: `url(${backgroundSrc})`,
      }}
      onClick={handleClick}
    >
      <div className="notifications-menu--new-destination--heading">
        {children}
      </div>
      <div className="notifications-menu--new-destination--date">
        {relativeDate}
      </div>
    </div>
  );
}
