import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import './styles.css';

interface PhaseItemProps {
  children?: ReactNode;
  href: string;
  target?: string;
  thumbnailSrc?: string;
  backgroundSrc?: string;
}

export function PhaseItem({
  children,
  href,
  target,
  thumbnailSrc,
  backgroundSrc,
}: PhaseItemProps) {
  return (
    <Link
      to={href}
      target={target}
      className="notifications-menu--journey-item"
      style={{
        backgroundImage: `url(${backgroundSrc})`,
      }}
    >
      <div>{children}</div>
      <div className="notifications-menu--journey-item--thumbnail">
        <img src={thumbnailSrc} draggable={false} alt="" />
      </div>
    </Link>
  );
}
