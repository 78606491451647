import { Contract, ContractInterface, ethers } from "ethers";
import { getContract } from "../utils/getContract";

export class BaseContract {
  contractAddress: string;
  contract: Contract;
  constructor(
    address: string,
    ABI: ContractInterface,
    provider: ethers.providers.Provider | ethers.Signer
  ) {
    this.contractAddress = address;
    this.contract = getContract(address, ABI, provider);
  }
}
