import '@rainbow-me/rainbowkit/styles.css';
import { Navigate, Route, Routes } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './App.css';

import { ToastContainer } from 'react-toastify';
import GetParameterPopups from './components/PopupManager';
import { WalletConnectInteractorProvider } from './providers/web3Provider';
// import { StarknetConfig } from '@starknet-react/core'
import { RainbowKitProvider, darkTheme } from '@rainbow-me/rainbowkit';
import { WagmiConfig } from 'wagmi';
import { useAppInteractor } from './App.interactor';
import { AppRouter } from './Config/RouterConfig';
import { InitialUserApiKey } from './components/InitialUserApiKey';
import { TransactionTrackingNotification } from './components/TransactionTrackingNotification';
import { chains, wagmiConfig } from './providers/rainbow';
import { StarknetConfig } from './starknet';
import { getRoutes } from './utils/getRoutes';
import { SEND_PAGE } from './constants/routes.constants';

export default function App() {
  const { starknetConnectors } = useAppInteractor();

  return (
    <AppRouter>
      <WagmiConfig config={wagmiConfig}>
        <RainbowKitProvider chains={chains} theme={darkTheme()} locale="en">
          <StarknetConfig connectors={starknetConnectors}>
            <WalletConnectInteractorProvider>
              <ToastContainer className="toast-position" />
              <InitialUserApiKey />
              <TransactionTrackingNotification />
              <Routes>
                {getRoutes()}
                <Route path="*" element={<Navigate replace to={SEND_PAGE} />} />
              </Routes>

              <GetParameterPopups />
            </WalletConnectInteractorProvider>
          </StarknetConfig>
        </RainbowKitProvider>
      </WagmiConfig>
    </AppRouter>
  );
}
