import { useEffect, useRef } from 'react';
import { shortAddress } from '../../../../../utils';
import { toFixed } from '../../../../../utils/numbers';
import { maxPlatformDecimals } from '../../../../../constants/numbers';

export const NetworkInfo = ({
  netwSrc,
  netw,
  wallet,
  amount,
  amountUsd,
  coinSimbol,
}: {
  netwSrc: string;
  netw: string;
  wallet: string;
  amount: string;
  amountUsd: string;
  coinSimbol: string;
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const currentWidth = ref.current?.getBoundingClientRect()?.width;
    if (currentWidth) {
      const nodes = document.querySelectorAll<HTMLElement>('#statusInfo');
      nodes.forEach((el) => {
        const prevWidth = el.getBoundingClientRect().width;
        if (prevWidth < +currentWidth) {
          el.style.width = `${currentWidth}px`;
        }
      });
    }
  }, [ref]);

  return (
    <div className="networkInfoContainer">
      <div className="networkInfoLeftDiv">
        <div className="imgDiv20">
          <img className="coinImg" src={netwSrc} alt="network icon" />
        </div>

        <div className="d-flex flex-column ms-2 align-items-start statusText">
          <div className="infoText">{netw}</div>
          <div className="infoText secondarytext secondary-text-weight">{`${shortAddress(
            wallet
          )}`}</div>
        </div>
      </div>
      <div
        ref={ref}
        id="statusInfo"
        className="d-flex flex-column align-items-start statusText"
      >
        <div className="infoText">{`${toFixed(
          +amount,
          maxPlatformDecimals
        )} ${coinSimbol}`}</div>
        <div className="infoText secondarytext secondary-text-weight">{`${toFixed(
          +amountUsd,
          2
        )} $`}</div>
      </div>
    </div>
  );
};
