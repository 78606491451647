import clsx from 'clsx';
import { useMemo } from 'react';
import { useBalance } from '../../../../hooks/useBalance';
import { useWalletStore } from '../../../../stores/wallet.store';
import { INetwork } from '../../../../types/apiTypes';
import { getMinimalDecimalNumber } from '../../../../utils/numbers';
import { LoaderSmall } from '../../../../components/LoaderSmall';

interface BalanceProps {
  network: INetwork;
}

const ROUND_TOKENS = ['usdc', 'usdt'];
const ETH_TOKENS = ['eth', 'weth'];

export function Balance({ network }: BalanceProps) {
  const { currencyFromId, currencyFromList, allCurrencyPairs } =
    useWalletStore();

  const selectedCurrency = useMemo(() => {
    return currencyFromList.find((currency) => currency.id === currencyFromId);
  }, [currencyFromId, currencyFromList]);

  const [isTokenInNetwork, currency] = useMemo(() => {
    let isTokenInNetwork = false;
    let symbol = selectedCurrency;
    const isETHSelected =
      !!selectedCurrency && ETH_TOKENS.includes(selectedCurrency?.symbol);

    allCurrencyPairs.forEach((currency) => {
      if (currency.contract.network.id === network.id) {
        isTokenInNetwork =
          symbol?.symbol === currency.symbol ||
          (isETHSelected && ETH_TOKENS.includes(currency.symbol.toLowerCase()));
        symbol = currency;
      }
    });
    return [
      isTokenInNetwork || selectedCurrency?.contract.network.id === network.id,
      symbol,
    ];
  }, [allCurrencyPairs, network.id, selectedCurrency]);

  const { balance, loading } = useBalance(network, currency);

  const formattedBalance = useMemo(() => {
    const shouldRoundToken = ROUND_TOKENS.includes(
      currency?.symbol.toLowerCase() ?? ''
    );

    let formatted = +balance <= 0 ? 0 : getMinimalDecimalNumber(balance);

    if (+formatted <= 0) return '0.00';

    if (shouldRoundToken) {
      return +formatted < 0.0001 ? '0.00' : formatted;
    }

    return +formatted < 0.000001 ? '0.00' : formatted;
  }, [balance, currency?.symbol]);

  if (!isTokenInNetwork) return null;

  return (
    <p
      className={clsx(
        'network-balance',
        +balance <= 0 && 'network-balance-disabled',
        loading && 'network-balance-loading'
      )}
    >
      {loading ? (
        <LoaderSmall
          width={16}
          height={16}
          className="network-balance-loader"
        />
      ) : (
        formattedBalance
      )}{' '}
      {currency?.symbol}
    </p>
  );
}
