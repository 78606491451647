import { SVGAttributes } from "react";

export function Sun(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="12"
      viewBox="0 0 21 12"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9137 3.97811C11.317 3.81162 10.6884 3.72229 10.0393 3.72229C9.39013 3.72229 8.76153 3.81162 8.16481 3.97815L9.51024 1.04522C9.60509 0.838422 9.81177 0.705883 10.0393 0.705883C10.2668 0.705883 10.4734 0.838422 10.5683 1.04522L11.9137 3.97811ZM3.95726 7.27669C4.58367 6.17009 5.5032 5.25056 6.6098 4.62415L3.58199 3.50067C3.36867 3.42149 3.12883 3.47396 2.96797 3.63481C2.80707 3.79567 2.75473 4.03552 2.83383 4.24884L3.95726 7.27669ZM3.05547 10.7059C3.05547 10.0568 3.1448 9.42816 3.31129 8.83145L0.378398 10.1769C0.171602 10.2717 0.0390625 10.4784 0.0390625 10.7059C0.0390625 10.9334 0.171602 11.1401 0.378398 11.2349L0.507297 11.2941H3.08009C3.06379 11.1001 3.05547 10.904 3.05547 10.7059ZM15.8291 11.2941H4.249C4.22949 11.1006 4.2195 10.9044 4.2195 10.7059C4.2195 7.49695 6.83013 4.88632 10.039 4.88632C13.2479 4.88632 15.8586 7.49695 15.8586 10.7059C15.8586 10.9044 15.8486 11.1006 15.8291 11.2941ZM19.5707 11.2941H16.998C17.0143 11.1001 17.0226 10.9039 17.0226 10.7058C17.0226 10.0567 16.9333 9.42811 16.7668 8.83135L19.6998 10.1768C19.9066 10.2717 20.0391 10.4783 20.0391 10.7058C20.0391 10.9333 19.9066 11.14 19.6998 11.2348L19.5707 11.2941ZM16.1209 7.27665L17.2443 4.24879C17.3234 4.03551 17.2711 3.79563 17.1102 3.63477C16.9493 3.47387 16.7094 3.42157 16.4962 3.50063L13.4683 4.62407C14.5749 5.25051 15.4944 6.17004 16.1209 7.27665Z"
        fill="#FFC3A1"
      />
    </svg>
  );
}
