import historyIcon from '../../../Assets/Icons/history.svg';
import searchIcon from '../../../Assets/Icons/search.svg';
import { OrderSearchStatuses } from '../../../types/enums';
import '../Explorer.css';
interface IExplorer {
  search: string;
  setSearch: (value: string) => void;
  status: OrderSearchStatuses;
  setStatus: (status: OrderSearchStatuses) => void;
}
export const SearchBar = ({
  search,
  setSearch,
  status,
  setStatus,
}: IExplorer) => {
  return (
    <>
      <div className="searchContainer d-flex flex-row w-100">
        <div className="d-flex flex-row position-relative flex-grow-1 ">
          <img src={searchIcon} className="searchIcon" alt="" />
          <input
            type="search"
            placeholder={'Search by address / source / destination etc.'}
            className="inputExplorer  ps-5 w-100"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        <div className="search-bar-buttons-container">
          <button
            onClick={() => setStatus(OrderSearchStatuses.ALL)}
            className={`explorerSearchBtn leftExplorerSearchBtn ${
              status === 'all' ? 'gradientBg' : 'btnSearchBg'
            }`}
          >
            All
          </button>
          <button
            onClick={() => setStatus(OrderSearchStatuses.COMPLETE)}
            className={`explorerSearchBtn   ${
              status === OrderSearchStatuses.COMPLETE
                ? 'gradientBg'
                : 'btnSearchBg'
            }`}
          >
            Completed
          </button>
          <button
            onClick={() => setStatus(OrderSearchStatuses.IN_PROGRESS)}
            className={`explorerSearchBtn  ${
              status === OrderSearchStatuses.IN_PROGRESS
                ? 'gradientBg'
                : 'btnSearchBg'
            }`}
          >
            Pending
          </button>
          <button
            onClick={() => setStatus(OrderSearchStatuses.HISTORY)}
            className={`explorerSearchBtn history rightExplorerSearchBtn  ${
              status === OrderSearchStatuses.HISTORY
                ? 'gradientBg'
                : 'btnSearchBg'
            }`}
          >
            <img src={historyIcon} alt="" />
            <span>My history</span>
          </button>
        </div>
      </div>
    </>
  );
};
