/**
 * Added to sync fee data with currency
 */

// import { InjectedConnector } from "./starknet/dist";
import { InjectedConnector } from "./starknet";
import { starknetWallets } from "./starknet/constants/wallets.constant";
export const useAppInteractor = () => {
  const connectors = starknetWallets.map(
    (item) => new InjectedConnector({ options: { id: item.id } })
  );

  return { starknetConnectors: connectors };
};
