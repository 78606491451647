import { NewDestinationItem } from '../NewDestinationItem';
import { Other } from '../Other';
import { PhasesBlock } from '../PhasesBlock';

export function LatestTab() {
  return (
    <div className="notifications-menu--tabs-content">
      <PhasesBlock />
      <div className="notifications-menu--group">
        <div className="notifications-menu--group-heading">Faucet</div>
        <Other />
      </div>
      <div className="notifications-menu--group">
        <div className="notifications-menu--group-heading">
          New Testnet Destinations
        </div>
        <NewDestinationItem chainId="421614">
          Bridging to Arbitrum Sepolia is now available on RetroBridge
        </NewDestinationItem>
        <NewDestinationItem chainId="167009">
          Bridging to Taiko Hekla is now available
          <br /> on RetroBridge
        </NewDestinationItem>
      </div>
    </div>
  );
}
