import { STAGES, stage } from '../Config/ApiConfig';

export const EXPLORER_PAGE = '/explorer';
export const PROGRESS_PAGE = '/progress';
export const SEND_PAGE = '/';
export const PRIVACY_POLICY_PAGE = '/terms-conditions';
export const AUDIT_PAGE = window.location.origin + '/audit.pdf';
export const DOCS_LINK = 'https://docs.retrobridge.io/';
export const FAUCET_PAGE = '/faucet';

export enum Routes {
  EXPLORER_PAGE = 'EXPLORER_PAGE',
  PROGRESS_PAGE = 'PROGRESS_PAGE',
  SEND_PAGE = 'SEND_PAGE',
  PRIVACY_POLICY_PAGE = 'PRIVACY_POLICY_PAGE',
  FAUCET_PAGE = 'FAUCET_PAGE',
  PHASES_PAGE = 'PHASES_PAGE',
  PHASE_ADVENTURES_PAGE = 'PHASE_ADVENTURES_PAGE',
  PHASE_JOURNEY_PAGE = 'PHASE_JOURNEY_PAGE',
  FAQ_PAGE = 'FAQ_PAGE',
  AUDIT_PAGE = 'AUDIT_PAGE',
  MONITORING_AUDIT_PAGE = 'MONITORING_AUDIT_PAGE',
  QUANTSTAMP_AUDIT_PAGE = 'QUANTSTAMP_AUDIT_PAGE',
  DEV_API_PAGE = 'DEV_API_PAGE',

  MAINNET_PAGE = 'MAINNET_PAGE',
  MAINNET_EXPLORER_PAGE = 'MAINNET_EXPLORER_PAGE',

  PHASE_MIDDAY_PAGE = 'PHASE_MIDDAY_PAGE',
  PHASE_ACTIVE_ADVENTURE = 'PHASE_ACTIVE_ADVENTURE',

  DAPP_EXPLORER_PAGE = 'DAPP_EXPLORER_PAGE',
}

const localRoutes: Record<Routes, string> = {
  [Routes.EXPLORER_PAGE]: '/explorer',
  [Routes.PROGRESS_PAGE]: '/progress',
  [Routes.PRIVACY_POLICY_PAGE]: 'https://retrobridge.dev/terms-conditions',
  [Routes.SEND_PAGE]: '/send',
  [Routes.FAUCET_PAGE]: '/faucet',
  [Routes.PHASES_PAGE]: 'https://retrobridge.dev/phases',
  [Routes.PHASE_ADVENTURES_PAGE]: 'https://retrobridge.dev/adventures',
  [Routes.PHASE_JOURNEY_PAGE]: 'https://retrobridge.dev/phases/journeys',
  [Routes.FAQ_PAGE]: 'https://retrobridge.dev/faq',
  [Routes.AUDIT_PAGE]: 'https://retrobridge.dev/audit.pdf',
  [Routes.MONITORING_AUDIT_PAGE]:
    'https://retrobridge.dev/security-monitoring.pdf',
  [Routes.QUANTSTAMP_AUDIT_PAGE]:
    'https://retrobridge.dev/quantstamp-sc-audit.pdf',
  [Routes.DEV_API_PAGE]: 'https://retrobridge.dev/for-developers',

  [Routes.MAINNET_PAGE]: 'https://app.retrobridge.dev',
  [Routes.MAINNET_EXPLORER_PAGE]: 'https://explorer.retrobridge.dev',

  [Routes.PHASE_MIDDAY_PAGE]: 'https://retrobridge.dev/phases/midday-journey',
  [Routes.PHASE_ACTIVE_ADVENTURE]: 'https://retrobridge.dev/adventures/taiko',

  [Routes.DAPP_EXPLORER_PAGE]: 'https://retrobridge.dev/dapp-explorer',
};

const stagingRoutes: Record<Routes, string> = {
  [Routes.EXPLORER_PAGE]: 'https://testnet.explorer.retrobridge.dev',
  [Routes.PROGRESS_PAGE]: '/progress',
  [Routes.PRIVACY_POLICY_PAGE]: 'https://retrobridge.dev/terms-conditions',
  [Routes.SEND_PAGE]: 'https://testnet.retrobridge.dev',
  [Routes.FAUCET_PAGE]: 'https://faucet.retrobridge.dev',
  [Routes.PHASES_PAGE]: 'https://retrobridge.dev/phases',
  [Routes.PHASE_ADVENTURES_PAGE]: 'https://retrobridge.dev/adventures',
  [Routes.PHASE_JOURNEY_PAGE]: 'https://retrobridge.dev/phases/journeys',
  [Routes.FAQ_PAGE]: 'https://retrobridge.dev/faq',
  [Routes.AUDIT_PAGE]: 'https://retrobridge.dev/audit.pdf',
  [Routes.MONITORING_AUDIT_PAGE]:
    'https://retrobridge.dev/security-monitoring.pdf',
  [Routes.QUANTSTAMP_AUDIT_PAGE]:
    'https://retrobridge.dev/quantstamp-sc-audit.pdf',
  [Routes.DEV_API_PAGE]: 'https://retrobridge.dev/for-developers',

  [Routes.MAINNET_PAGE]: 'https://app.retrobridge.dev',
  [Routes.MAINNET_EXPLORER_PAGE]: 'https://explorer.retrobridge.dev',

  [Routes.PHASE_MIDDAY_PAGE]: 'https://retrobridge.dev/phases/midday-journey',
  [Routes.PHASE_ACTIVE_ADVENTURE]: 'https://retrobridge.dev/adventures/taiko',

  [Routes.DAPP_EXPLORER_PAGE]: 'https://retrobridge.dev/dapp-explorer',
};

const productionRoutes: Record<Routes, string> = {
  [Routes.EXPLORER_PAGE]: 'https://testnet.explorer.retrobridge.io',
  [Routes.PROGRESS_PAGE]: '/progress',
  [Routes.PRIVACY_POLICY_PAGE]: 'https://retrobridge.io/terms-conditions',
  [Routes.SEND_PAGE]: 'https://testnet.retrobridge.io',
  [Routes.FAUCET_PAGE]: 'https://faucet.retrobridge.io',
  [Routes.PHASES_PAGE]: 'https://retrobridge.io/phases',
  [Routes.PHASE_ADVENTURES_PAGE]: 'https://retrobridge.io/adventures',
  [Routes.PHASE_JOURNEY_PAGE]: 'https://retrobridge.io/phases/journeys',
  [Routes.FAQ_PAGE]: 'https://retrobridge.io/faq',
  [Routes.AUDIT_PAGE]: 'https://retrobridge.io/audit.pdf',
  [Routes.MONITORING_AUDIT_PAGE]:
    'https://retrobridge.io/security-monitoring.pdf',
  [Routes.QUANTSTAMP_AUDIT_PAGE]:
    'https://retrobridge.io/quantstamp-sc-audit.pdf',
  [Routes.DEV_API_PAGE]: 'https://retrobridge.io/for-developers',

  [Routes.MAINNET_PAGE]: 'https://app.retrobridge.io',
  [Routes.MAINNET_EXPLORER_PAGE]: 'https://explorer.retrobridge.io',

  [Routes.PHASE_MIDDAY_PAGE]: 'https://retrobridge.io/phases/midday-journey',
  [Routes.PHASE_ACTIVE_ADVENTURE]: 'https://retrobridge.io/adventures/taiko',

  [Routes.DAPP_EXPLORER_PAGE]: 'https://retrobridge.io/dapp-explorer',
};

const routes: Record<STAGES, Record<Routes, string>> = {
  development: localRoutes,
  production: productionRoutes,
  staging: stagingRoutes,
};
export const ROUTES = routes[stage];
