import { Link } from 'react-router-dom';
import { ExternalIcon } from '../../../../Assets/Icons/External';
import './styles.css';
import { ROUTES } from '../../../../constants/routes.constants';

export function NetworkSwitch() {
  return (
    <div className="network-switch testnet">
      <Link
        to={ROUTES.MAINNET_PAGE}
        target="_blank"
        className="network-switch--item"
      >
        Mainnet <ExternalIcon />
      </Link>
      <button className="network-switch--item">
        Testnet & Faucet
      </button>
    </div>
  );
}
