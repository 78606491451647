import axios, { AxiosInstance, CreateAxiosDefaults } from 'axios';
import { BASE_URL } from '../Config/ApiConfig';
import { faucetStore } from '../pages/Faucet/store';
import { ICurrency, INetwork } from '../types/apiTypes';
import { IFaucetCurrency } from '../types/faucet';
import { logInGroup } from '../utils';
import { NetworkTypes } from '../providers/web3Provider';

export class FaucetAPI {
  private _api: AxiosInstance;

  constructor(path: string, config?: Omit<CreateAxiosDefaults, 'url'>) {
    this._api = axios.create({
      baseURL: path,
      ...config,
      withCredentials: true,
    });
  }

  async getCurrencyInfo(
    networkId: INetwork['id']
  ): Promise<IFaucetCurrency | null> {
    try {
      const url = `/api/faucet/info_currency/${networkId}`;
      const { data } = await this._api.get<IFaucetCurrency>(url);

      return data;
    } catch (error) {
      logInGroup('FaucetAPI.getCurrencyInfo', error);
    }

    return null;
  }

  async getBalance(address: string) {
    const { setBalance, setIsBalanceLoading } = faucetStore.getState();
    try {
      const url = `/api/faucet/faucet_balance/${address}`;
      setIsBalanceLoading(true);

      const { data } = await this._api.get<{
        available_amount_of_claims: number;
      }>(url);

      setBalance(data.available_amount_of_claims);

      return data.available_amount_of_claims;
    } catch (error) {
      logInGroup('FaucetAPI.getBalance', error);
    } finally {
      setIsBalanceLoading(false);
    }
    return 0;
  }

  async claim(
    network: INetwork,
    currencyId: ICurrency['id'],
    walletReceiver: string
  ) {
    const url = `/api/faucet/claim_tokens/${currencyId}/${walletReceiver}`;

    const { data } = await this._api.put(
      url,
      {},
      {
        headers: {
          'network-type': NetworkTypes.EVM,
        },
      }
    );

    return data;
  }
}

export const faucetAPI = new FaucetAPI(BASE_URL);
