import { AxiosError } from 'axios';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAccount } from 'wagmi';
import { FaucetIcon } from '../../../../Assets/Icons/Faucet';
import { ConnectWalletModalWithDisabled } from '../../../../components/ConnectWalletModalWithDisabled';
import { ROUTES } from '../../../../constants/routes.constants';
import { faucetAPI } from '../../../../services/faucet';
import { useSignStore } from '../../../../stores/sign.store';
import { faucetStore, useFaucetStore } from '../../store';
import { FaucetBg } from '../FaucetBg';
import { SelectAddress } from '../SelectAddress';
import { SelectNetwork } from '../SelectNetwork';
import './styles.css';
import { ClaimDialog, ClaimDialogVariants } from '../ClaimDialog';
import { INetwork } from '../../../../types/apiTypes';
import { IFaucetCurrency } from '../../../../types/faucet';
import { AppModeSwitch } from '../../../../components/AppModeSwitch';
import { NetworkTypes } from '@/providers/web3Provider';

export function FaucetForm() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isWalletModalOpen, setIsWalletModalOpen] = useState(false);
  const [balance, isBalanceLoading, error, setError] = useFaucetStore((s) => [
    s.balance,
    s.isBalanceLoading,
    s.error,
    s.setError,
  ]);
  const { setResign } = useSignStore();
  const { isConnected, address } = useAccount();
  const [isResultOpen, setIsResultOpen] = useState(false);
  const [claimResult, setClaimResult] = useState<{
    variant: ClaimDialogVariants;
    selectedNetwork: INetwork;
    selectedCurrency: IFaucetCurrency;
  } | null>(null);

  const handleSubmit = async () => {
    const { selectedNetwork, selectedCurrency, otherAddress } =
      faucetStore.getState();
    setError(undefined);
    setIsSubmitting(true);
    try {
      if (!selectedNetwork || !selectedCurrency || !address) return;

      const data = await faucetAPI.claim(
        selectedNetwork,
        selectedCurrency.id,
        !!otherAddress ? otherAddress : address
      );

      setClaimResult({
        variant: data.response_type,
        selectedNetwork,
        selectedCurrency,
      });
      setIsResultOpen(true);

      await faucetAPI.getBalance(address);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          setResign(selectedNetwork?.network_type ?? null);
          return;
        }

        setError(error.response?.data?.message || 'An error occurred');
      } else if (error instanceof Error) {
        setError(error.message);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const isClaimDisabled = isSubmitting || balance <= 0;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="faucet-form position-relative"
      id="faucet-form"
    >
      <FaucetBg />
      {isConnected ? (
        <div className="faucet-form--badge">
          Your balance:{' '}
          <span className="d-flex align-items-center gap-1">
            <FaucetIcon /> {balance}
          </span>
        </div>
      ) : (
        <div style={{ height: 38 }} />
      )}
      <h1 className="faucet-form--heading">Retrobridge Multichain Faucet</h1>
      <AppModeSwitch variant="Faucet" />
      <div className="faucet-form--content">
        <SelectNetwork />
        {!isConnected && (
          <button
            className="faucet-form--submit-btn btnGradient"
            onClick={() => setIsWalletModalOpen(true)}
          >
            Connect wallet
          </button>
        )}
        {isConnected && (
          <>
            <div>
              <SelectAddress />
              <AnimatePresence>
                {!!error && (
                  <motion.div
                    initial={{ height: 0, marginTop: 0 }}
                    animate={{ height: 'auto', marginTop: 8 }}
                    exit={{ height: 0, marginTop: 0 }}
                    style={{ overflow: 'hidden' }}
                    className="faucet-form--error"
                  >
                    {error}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
            {balance <= 0 && (
              <button
                className="faucet-form--submit-btn btnGradient btnDisabled"
                disabled
              >
                {isBalanceLoading ? (
                  'Loading...'
                ) : (
                  <>
                    You don’t have enough <FaucetIcon /> for the claim
                  </>
                )}
              </button>
            )}
            {balance > 0 && (
              <button
                className={clsx(
                  'faucet-form--submit-btn btnGradient',
                  isClaimDisabled && 'btnDisabled'
                )}
                onClick={handleSubmit}
                disabled={isClaimDisabled}
              >
                {isSubmitting ? 'Claiming...' : 'Claim'}
              </button>
            )}
          </>
        )}
        <div className="faucet-form--content-hint">
          1 bridge on Mainnet = <FaucetIcon width={16} height={16} /> 3 test
          faucets{' '}
          <Link to={ROUTES.MAINNET_PAGE} className="text-gradient">
            Get more
          </Link>
        </div>
      </div>
      <ConnectWalletModalWithDisabled
        openModal={isWalletModalOpen}
        setOpenModal={setIsWalletModalOpen}
        networkType={NetworkTypes.EVM}
        onlySelectedNetworkType
      />
      <ClaimDialog
        isOpen={isResultOpen}
        onOpenChange={setIsResultOpen}
        {...claimResult}
      />
    </motion.div>
  );
}
