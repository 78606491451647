import { stage, STAGES } from "../../Config/ApiConfig";
import { constants } from "starknet";

const appChainIds: Record<STAGES, constants.StarknetChainId> = {
  production: constants.StarknetChainId.SN_GOERLI,
  staging: constants.StarknetChainId.SN_GOERLI,
  development: constants.StarknetChainId.SN_GOERLI,
};

export const getAppStarknetChanId = () => {
  return appChainIds[stage];
};
