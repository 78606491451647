import { SVGAttributes } from 'react';

export function RoundProgress(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      style={{ transform: 'rotateZ(-90deg)' }}
      {...props}
    >
      <circle
        cx="10"
        cy="10"
        r="9"
        fill="none"
        stroke="white"
        opacity={0.2}
        strokeWidth="1"
      />
      <circle
        className='progress-circle'
        cx="10"
        cy="10"
        r="9"
        fill="none"
        strokeDasharray="100"
        stroke="white"
        strokeLinecap="round"
        strokeWidth="1"
        pathLength="100"
      />
    </svg>
  );
}
