import { intersectionBy } from 'lodash';
import { useMemo } from 'react';
import { NewDestinationItem } from '../NewDestinationItem';
import { mockNetworks } from '../../../constants/mock_networks.constants';
import { useWalletStore } from '../../../stores/wallet.store';

export function NewDestinationsTab() {
  const { networksList: networks } = useWalletStore();

  const filteredNetworks = useMemo(() => {
    const newNetworks = mockNetworks.filter(
      (network) => !!network.is_new_destination
    );

    return intersectionBy(networks, newNetworks, 'chainId').sort(
      (a, b) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
    );
  }, [networks]);

  return (
    <div className="notifications-menu--group">
      <div className="notifications-menu--group-heading">New Destinations</div>
      {filteredNetworks.map((network) => (
        <NewDestinationItem key={network.id} chainId={network.chainId}>
          Bridging to {network.name} is now available{' '}
          <span className="text-nowrap">on RetroBridge</span>
        </NewDestinationItem>
      ))}
    </div>
  );
}
