import { AccountInterface } from "starknet";
import { getAppStarknetChanId } from "./getAppStarknetChainId";

export const validateConnectedChain = async (account: AccountInterface) => {
  const accountChain = await account.getChainId();

  const appChainId = getAppStarknetChanId();

  return appChainId === accountChain;
};
