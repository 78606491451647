import { wallets } from '@/providers/rainbow';
import { WalletButton } from '@rainbow-me/rainbowkit';
import { OptionsProps } from '..';
import { useAccount } from 'wagmi';

export function EVMOptions({ onOpenChange }: OptionsProps) {
  useAccount({
    onConnect: () => {
      onOpenChange(false);
    },
  });

  return (
    <div className="connect-wallet--group">
      <div className="connect-wallet--group-heading">EVM wallets</div>
      <div className="connect-wallet--group-grid">
        {wallets.map((item, i) => (
          <WalletButton.Custom key={i} wallet={item.id}>
            {({ connect, connector }) => {
              return (
                <button
                  onClick={
                    connector.id === 'walletConnect'
                      ? connector.showWalletConnectModal
                      : connect
                  }
                  className="connect-wallet--group-item"
                >
                  <img
                    src={require(`@/Assets/images/wallets/${item.id}.png`)}
                    style={{ height: '24px', width: '24px' }}
                    alt=""
                  />
                  {item.name}
                </button>
              );
            }}
          </WalletButton.Custom>
        ))}
      </div>
    </div>
  );
}
