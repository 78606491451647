import { create } from "zustand";
import { NetworkTypes } from "../providers/web3Provider";

export type SignStore = {
  resign: NetworkTypes | null;
  setResign: (type: NetworkTypes | null) => void;
};

export const useSignStore = create<SignStore>()((set) => ({
  resign: null,
  setResign: (value) => set({resign: value}),
}));
