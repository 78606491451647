import { toast, cssTransition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "animate.css/animate.min.css";
export const bounce = cssTransition({
  enter: "animate__animated animate__bounceIn",
  exit: "animate__animated animate__bounceOut",
});
export const notify = (text: string) => {
  toast.dark(text, { toastId: "customId", transition: bounce });
};
