import { Buffer } from 'buffer';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "./Assets/fonts/satoshi/css/satoshi.css";
import "./Assets/fonts/aeonikPro/aeonikPro.css";
import './index.css';
import './var.css';
import { stage } from './Config/ApiConfig';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
window.Buffer = window.Buffer || Buffer;

if (stage === 'production') {
  require('./tag');
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
