import { ethers } from 'ethers';
import { formatEther, formatUnits } from 'ethers/lib/utils';
import { useAccount as useEVMAccount } from 'wagmi';

import { useCallback, useEffect, useState } from 'react';
import { Contract, uint256 } from 'starknet';
import { getErc20BalanceHelper } from '../evm/utils/getErc20BalanceHelper';
import { NetworkTypes } from '../providers/web3Provider';
import { balanceABIFragment, useAccount } from '../starknet';
import { balanceSchema, decimalsSchema } from '../starknet/hooks/balanceSchema';
import { getStarknetProvider } from '../starknet/utils/getStarknetProvider';
import { ICurrency, INetwork } from '../types/apiTypes';
import { getMockNetwork, getNetwork } from '../utils/getMockRpcUrl';
import { useDebouncedCallback } from './useDebouncedCallback';

export const useBalance = (
  network: INetwork | undefined,
  token: ICurrency | undefined
) => {
  const [loading, setLoading] = useState(true);
  const { address } = useEVMAccount();
  const { address: starknetAddress } = useAccount();

  const [balance, setBalance] = useState('0');

  const fetchBalanceCallback = useCallback(async () => {
    try {
      setLoading(true);
      if (
        address &&
        (network?.network_type === NetworkTypes.EVM ||
          network?.network_type === NetworkTypes.ZK_SYNC_ERA)
      ) {
        const mockNetwork = getNetwork(network);
        const balance = await fetchEvmBalance(
          address,
          mockNetwork?.rpcUrls.public.http[0],
          token?.contract.address,
          token?.decimals
        );
        return setBalance(balance);
      }
      if (starknetAddress && network?.network_type === NetworkTypes.STARKNET) {
        const mockNetwork = getMockNetwork(network);
        const balance = await getStarknetBalance(
          starknetAddress,
          mockNetwork.rpc_url,
          mockNetwork.is_rpc,
          token?.contract.address,
          token?.decimals
        );
        return setBalance(balance);
      }
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message);
      }
      throw new Error('unhandled error');
    } finally {
      setLoading(false);
    }
  }, [address, starknetAddress, network, token]);

  const getStarknetBalance = async (
    walletAddress: string,
    rpc_url: string,
    is_rpc: boolean,
    currencyAddress?: string,
    currencyDecimals?: number
  ) => {
    if (!rpc_url || !currencyAddress || !currencyDecimals) {
      return '0';
    }

    const provider = getStarknetProvider(rpc_url, is_rpc);
    const contract = new Contract(
      balanceABIFragment,
      currencyAddress,
      provider
    );
    const [balance, decimals] = await Promise.all([
      contract.call('balanceOf', [walletAddress], {
        parseResponse: true,
      }),
      contract.call('decimals', []),
    ]);

    const parsedBalance = balanceSchema.parse(balance);
    const parsedDecimals = decimalsSchema.parse(decimals);

    const balanceAsBN = uint256.uint256ToBN(parsedBalance.balance);
    const formatted = (
      Number(balanceAsBN.toString()) /
      10 ** (currencyDecimals || Number(parsedDecimals))
    ).toString();

    return formatted;
  };

  const fetchEvmBalance = async (
    account: string,
    rpc_url?: string,
    currencyAddress?: string,
    currencyDecimals?: number
  ) => {
    if (!rpc_url || !currencyAddress || !currencyDecimals) {
      return '0';
    }

    const provider = new ethers.providers.JsonRpcProvider(rpc_url);

    if (currencyAddress === ethers.constants.AddressZero) {
      const balance = await provider.getBalance(account);
      const parsedBalance = formatEther(balance);

      return parsedBalance;
    } else {
      const tokenBalance = await getErc20BalanceHelper(
        currencyAddress,
        account,
        provider
      );

      if (tokenBalance) {
        return formatUnits(tokenBalance!, currencyDecimals);
      } else {
        return '0';
      }
    }
  };

  const fetchBalance = useDebouncedCallback(fetchBalanceCallback, 500);

  useEffect(() => {
    fetchBalance();
  }, [fetchBalance]);

  useEffect(() => {
    if (network || token) {
      setBalance('0');
    }
  }, [network, token]);

  return { balance, loading };
};
