import { SVGAttributes } from "react";

export function BalanceTooltipBg(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="172"
      height="68"
      viewBox="0 0 172 68"
      fill="none"
      {...props}
    >
      <mask id="path-1-inside-1_5633_4570" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 0C5.37258 0 0 5.37258 0 12V46C0 52.6274 5.37258 58 12 58H130.227L136 68L141.773 58H160C166.627 58 172 52.6274 172 46V12C172 5.37258 166.627 0 160 0H12Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 0C5.37258 0 0 5.37258 0 12V46C0 52.6274 5.37258 58 12 58H130.227L136 68L141.773 58H160C166.627 58 172 52.6274 172 46V12C172 5.37258 166.627 0 160 0H12Z"
        fill="url(#paint0_linear_5633_4570)"
      />
      <path
        d="M130.227 58L130.66 57.75L130.515 57.5H130.227V58ZM136 68L135.567 68.25L136 69L136.433 68.25L136 68ZM141.773 58V57.5H141.485L141.34 57.75L141.773 58ZM0.5 12C0.5 5.64873 5.64873 0.5 12 0.5V-0.5C5.09644 -0.5 -0.5 5.09644 -0.5 12H0.5ZM0.5 46V12H-0.5V46H0.5ZM12 57.5C5.64872 57.5 0.5 52.3513 0.5 46H-0.5C-0.5 52.9036 5.09644 58.5 12 58.5V57.5ZM130.227 57.5H12V58.5H130.227V57.5ZM136.433 67.75L130.66 57.75L129.793 58.25L135.567 68.25L136.433 67.75ZM141.34 57.75L135.567 67.75L136.433 68.25L142.207 58.25L141.34 57.75ZM160 57.5H141.773V58.5H160V57.5ZM171.5 46C171.5 52.3513 166.351 57.5 160 57.5V58.5C166.904 58.5 172.5 52.9036 172.5 46H171.5ZM171.5 12V46H172.5V12H171.5ZM160 0.5C166.351 0.5 171.5 5.64873 171.5 12H172.5C172.5 5.09644 166.904 -0.5 160 -0.5V0.5ZM12 0.5H160V-0.5H12V0.5Z"
        fill="white"
        fillOpacity="0.3"
        mask="url(#path-1-inside-1_5633_4570)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5633_4570"
          x1="79.5"
          y1="-57"
          x2="79.5"
          y2="68"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6D395E" />
          <stop offset="1" stopColor="#6D395E" />
        </linearGradient>
      </defs>
    </svg>
  );
}
