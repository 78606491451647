import { BASE_URL } from '../../../Config/ApiConfig';
import { IOrder } from '../../../types/apiTypes';
import { toFixed } from '../../../utils/numbers';
import '../Explorer.css';
export const CoinIfo = ({ coin }: { coin: IOrder }) => {
  const decimals = +coin.amount_out < 1 ? 5 : 2;

  return (
    <div className="d-flex align-items-center   ">
      <div className="smallCircleBtn smallCircleBtnCoinIcon me-2">
        <img
          src={BASE_URL + coin.currency_in.image_url}
          className="coinImg"
          alt="coin"
        />
      </div>
      <div className="d-flex align-items-start flex-column">
        <div className="ps-1  me-2 tableFont">{`${coin.amount_in} ${coin.currency_in.symbol}`}</div>
        <div className="ps-1 textSecondaryTable me-2">{`${toFixed(
          coin.amount_out,
          decimals
        )} ${coin.currency_out.symbol}`}</div>
      </div>
    </div>
  );
};
