import { SVGAttributes } from "react";

export function Timer(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      {...props}
    >
      <path
        d="M15.9459 7.65728C15.3601 2.15266 10.33 0.189056 7.12935 0.539231C4.74357 0.801044 2.63598 2.10684 1.30727 4.11298C1.10437 4.42061 1.16982 4.82642 1.47745 5.0326C1.78508 5.23878 2.19089 5.13405 2.39707 4.8297C3.5196 3.16064 5.29011 2.07084 7.2668 1.86793C10.6573 1.51776 13.9299 3.58281 14.6205 7.79146C15.1277 10.8809 12.7027 14.7491 8.69695 15.1451C6.25554 15.3873 3.87632 14.2975 2.49852 12.3143L3.7585 12.5925C4.09886 12.6612 4.47194 12.455 4.54067 12.0819C4.60939 11.7416 4.40322 11.3685 4.03013 11.2998L1.376 10.7205C1.20582 10.6878 0.656013 10.7074 0.557833 11.231L0.0145701 13.9212C-0.0541559 14.2615 0.152022 14.6346 0.525106 14.7033C0.950553 14.7393 1.23855 14.5004 1.30727 14.1601L1.51018 13.2372C4.11195 16.8797 8.55623 16.5033 8.83113 16.4771C13.0921 16.0582 16.463 12.4812 15.9459 7.65728Z"
        fill="white"
      />
      <path
        d="M8.52349 4.72827C8.1504 4.72827 7.84277 5.0359 7.84277 5.40899V8.5082C7.84277 8.67838 7.9115 8.84856 8.04568 8.98601L10.0117 10.9673C10.4109 11.3469 10.8298 11.1047 11 11C11.2716 10.7284 11.2716 10.3193 11 10.0477L9.2042 8.23657V5.41226C9.20748 5.0359 8.89984 4.72827 8.52349 4.72827Z"
        fill="white"
      />
    </svg>
  );
}
