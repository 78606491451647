import { useEffect, useMemo } from 'react';
import { shortAddress } from '../../../utils';
import { decimalSlicer, toFixed } from '../../../utils/numbers';
import { maxPlatformDecimals } from '../../../constants/numbers';

export const NetworkInfo = ({
  netwSrc,
  netw,
  wallet,
  amount,
  amountUsd,
  coinSimbol,
  setWidth = undefined,
  infoBlockFromWidth,
  infoBlockToWidth,
}: {
  netwSrc: string;
  netw: string;
  wallet: string;
  amount: string;
  amountUsd: string;
  coinSimbol: string;
  setWidth?: (num: number) => void;
  infoBlockFromWidth?: number | null;
  infoBlockToWidth?: number | null;
}) => {
  const refCallback = (element: HTMLDivElement) => {
    const blockWidth = element?.getBoundingClientRect()?.width;
    if (setWidth !== undefined && blockWidth !== undefined) {
      setWidth(blockWidth);
    }
  };

  const blockWidth = useMemo(() => {
    if (infoBlockFromWidth && infoBlockToWidth) {
      return infoBlockFromWidth > infoBlockToWidth
        ? infoBlockFromWidth
        : infoBlockToWidth;
    } else {
      return '';
    }
  }, [infoBlockFromWidth, infoBlockToWidth]);

  useEffect(() => {
    const nodes = document.querySelectorAll<HTMLElement>('#infoBlockPriceDiv');
    nodes.forEach((el) => {
      const prevWidth = el.getBoundingClientRect().width;
      if (prevWidth < +blockWidth) {
        el.style.width = `${blockWidth}px`;
      }
    });
  }, [blockWidth]);

  return (
    <div className="networkInfoContainer">
      <div className="networkInfoLeftDiv">
        <div className="imgDiv20">
          <img className="coinImg" src={netwSrc} alt="network icon" />
        </div>

        <div className="d-flex flex-column ms-2">
          <div className="infoText">{netw}</div>
          <div className="infoText secondarytext secondary-text-weight">{`${shortAddress(
            wallet
          )}`}</div>
        </div>
      </div>
      <div
        ref={refCallback}
        id="infoBlockPriceDiv"
        className="d-flex flex-column info-block-price-div"
        style={blockWidth ? { width: blockWidth } : {}}
      >
        <div className="infoText">{`${toFixed(
          +amount,
          maxPlatformDecimals
        )} ${coinSimbol}`}</div>
        <div className="infoText secondarytext secondary-text-weight">{`${toFixed(
          +amountUsd,
          2
        )} $`}</div>
      </div>
    </div>
  );
};
