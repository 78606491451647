import { Route } from 'react-router-dom';
import {
  EXPLORER_PAGE,
  FAUCET_PAGE,
  PROGRESS_PAGE,
  ROUTES,
  SEND_PAGE,
} from '../constants/routes.constants';
import ExplorerPage from '../pages/ExplorerPage';
import ProgressPage from '../pages/ProgressPage';
import SendPage from '../pages/SendPage';
import { FaucetPage } from '../pages/Faucet';

export const explorerRoutes = [
  'testnet.explorer.retrobridge.io',
  'testnet.explorer.retrobridge.dev',
];
export const faucetRoutes = [
  'faucet.retrobridge.io',
  'faucet.retrobridge.dev',
];
export const bridgeRoutes = [
  'testnet.retrobridge.io',
  'testnet.retrobridge.dev',
];
export const landingRoutes = bridgeRoutes;

export const getRoutes = () => {
  if (explorerRoutes.includes(window.location.host)) {
    return <Route path={SEND_PAGE} element={<ExplorerPage />} />;
  }
  if (bridgeRoutes.includes(window.location.host)) {
    return (
      <>
        <Route path={SEND_PAGE} element={<SendPage />} />
        <Route path={`${PROGRESS_PAGE}/:orderId`} element={<ProgressPage />} />
      </>
    );
  }
  if (faucetRoutes.includes(window.location.host)) {
    return (
      <>
        <Route index path={"/"} element={<FaucetPage />} />
      </>
    );
  }
  if (landingRoutes.includes(window.location.host)) {
    return (
      <>
        <Route index path={SEND_PAGE} element={<SendPage />} />
      </>
    );
  }
  return (
    <>
      <Route index path={SEND_PAGE} element={<SendPage />} />
      <Route path={EXPLORER_PAGE} element={<ExplorerPage />} />
      <Route path={`${PROGRESS_PAGE}/:orderId`} element={<ProgressPage />} />
      <Route path={FAUCET_PAGE} element={<FaucetPage />} />
    </>
  );
};
