import { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import { Helmet } from 'react-helmet';
import { useDebounceValue } from 'usehooks-ts';
import ExplorerMobileBg from '../../Assets/images/send-bg-small.svg';
import SendBg from '../../Assets/images/send-bg-png.png';
import { Header } from '../../components/Header';
import { Loader } from '../../components/Loader';
import Footer from '../../components/Footer';
import { api } from '../../services/api';
import { useWalletStore } from '../../stores/wallet.store';
import { OrderSearchStatuses } from '../../types/enums';
import './Explorer.css';
import EmptyTablePlaceHolder from './components/emptyTablePlaceholder';
import { SearchBar } from './components/searchBar';
import { ExplorerTable } from './components/table';
import { useAccount } from 'wagmi';
import { ConnectWalletModalWithDisabled } from '../../components/ConnectWalletModalWithDisabled';
import { ConnectWalletPlaceHolder } from './components/connectWalletPlaceholder';
import { useSearchParams } from 'react-router-dom';
import { NetworkTypes } from '@/providers/web3Provider';
import InfiniteScroll from 'react-infinite-scroll-component';

export const ORDERS_PER_PAGE = 50;

const ExplorerPage = () => {
  const { address, isConnected } = useAccount();
  const { orders, setOrders } = useWalletStore();
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [status, setStatus] = useState<OrderSearchStatuses>(
    searchParams.has('myHistory')
      ? OrderSearchStatuses.HISTORY
      : OrderSearchStatuses.ALL
  );
  const [isConnectWalletOpen, setIsConnectWalletOpen] = useState(false);

  const [searchDebounce] = useDebounceValue(search, 300);
  const showHistory = status === OrderSearchStatuses.HISTORY;
  const [page, setPage] = useState(0);
  
  const shouldShowConnectWallet = !isConnected && showHistory;
  
  useEffect(() => {
    setSearchParams((params) => {
      params.delete('myHistory');
      return params;
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    setPage(0);
    api
      .getOrders(
        showHistory ? OrderSearchStatuses.ALL : status,
        searchDebounce,
        showHistory ? address : undefined,
        true,
        0,
        ORDERS_PER_PAGE
      )
      .finally(() => setLoading(false));
  }, [status, searchDebounce, address, showHistory]);

  useEffect(() => {
    const interval = setInterval(
      () => {
        api.getOrders(
          showHistory ? OrderSearchStatuses.ALL : status,
          searchDebounce,
          showHistory ? address : undefined,
          true,
          0,
          ORDERS_PER_PAGE * (page + 1)
        );
      },
      page >= 2 ? 15000 : 5000
    );
    return () => clearInterval(interval);
  }, [status, searchDebounce, address, showHistory, page]);

  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    if (status !== OrderSearchStatuses.IN_PROGRESS) {
      setHasMore(false);
      return;
    }
    setHasMore(orders.length < 200);
  }, [orders.length, status]);

  const loadMore = async () => {
    setPage(page + 1);

    const data = await api.getOrders(
      showHistory ? OrderSearchStatuses.ALL : status,
      searchDebounce,
      showHistory ? address : undefined,
      false,
      (page + 1) * ORDERS_PER_PAGE,
      ORDERS_PER_PAGE
    );

    if (!data) return;

    setOrders([...orders, ...data]);
  };

  return (
    <div className="page-background-color">
      <Helmet>
        <title>Explorer | Retro Bridge</title>
      </Helmet>
      <Container
        fluid
        className={`explorerPageBg exlorerContainer textTable d-flex  flex-1 page-padding-bottom flex-column align-items-center pt-2 position-relative`}
      >
        <img src={SendBg} alt="" className="send-bg-img" />
        <img src={ExplorerMobileBg} alt="" className="send-bg-img-mobile" />
        <div className="explorer-header-container">
          <Header />
        </div>

        <div className="explorerTitle d-flex">Recent Transactions</div>
        <SearchBar
          search={search}
          setSearch={setSearch}
          status={status}
          setStatus={setStatus}
        />

        {loading && !shouldShowConnectWallet && (
          <div style={{ height: 588 }}>
            <Loader />
          </div>
        )}

        {!loading && !orders.length && !shouldShowConnectWallet && (
          <div style={{ height: 588 }}>
            <EmptyTablePlaceHolder />
          </div>
        )}

        {shouldShowConnectWallet && (
          <div style={{ height: 588 }}>
            <ConnectWalletPlaceHolder setOpenModal={setIsConnectWalletOpen} />
          </div>
        )}

        {!loading && !!orders.length && !shouldShowConnectWallet && (
          <InfiniteScroll
            next={loadMore}
            hasMore={hasMore}
            scrollableTarget="explorer-viewport"
            dataLength={orders?.length ?? 0}
            loader={false}
          >
            <ExplorerTable orders={orders} />
          </InfiniteScroll>
        )}

        <div className="explorer-footer-container">
          <Footer />
        </div>
      </Container>
      <ConnectWalletModalWithDisabled
        setOpenModal={setIsConnectWalletOpen}
        openModal={isConnectWalletOpen}
        networkType={NetworkTypes.EVM}
        onlySelectedNetworkType
      />
    </div>
  );
};

export default ExplorerPage;
