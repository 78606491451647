import { stage, STAGES } from "../../Config/ApiConfig";

export const ethMainnetAddress =
  "0x049d36570d4e46f48e99674bd3fcc84644ddd6b96f7c741b1562b82f9e004dc7";
export const ethTestnetAddress =
  "0x049d36570d4e46f48e99674bd3fcc84644ddd6b96f7c741b1562b82f9e004dc7";

const stageAddresses: Record<STAGES, string> = {
  development: ethTestnetAddress,
  staging: ethTestnetAddress,
  production: ethTestnetAddress,
};

export const starknetNativeAddress = stageAddresses[stage];
