import React from "react";
import "../SendPage.css";
import InvalidAddressIcon from "../../../Assets/Icons/invalid-address.svg";
import ValidAddressIcon from "../../../Assets/Icons/valid-address.svg";

export type WalletAddressInputProps = {
  value: string;
  setValue?: (value: string) => void;
  classN: "hidden" | "showInput";
  isValidAddress: boolean;
};

function WalletAddressInputl({
  value,
  setValue,
  classN,
  isValidAddress,
}: WalletAddressInputProps) {
  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (setValue) {
      setValue(e.target.value);
    }
  };

  return (
    <div
      className={`${classN} w-100 ${
        classN === "hidden" ? "d-none" : "mt-2"
      } position-relative`}
    >
      <input
        className={`${classN} walletAddressInput w-100`}
        aria-label="Text input"
        placeholder="Enter your address here"
        onChange={onChangeInput}
        disabled={classN === "hidden"}
        value={value}
      />
      {!!value && (
        <div className="address-icon">
          <img
            src={isValidAddress ? ValidAddressIcon : InvalidAddressIcon}
            width="20px"
            height="20px"
            alt=""
          />
        </div>
      )}
    </div>
  );
}

export default WalletAddressInputl;
