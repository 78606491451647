import { RefObject, useEffect, useRef } from 'react';
import { IOrder } from '../types/apiTypes';
import { stage } from '../Config/ApiConfig';
import dayjs from 'dayjs';

import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';

dayjs.extend(isToday);
dayjs.extend(isYesterday);

export const filterOrders = (orders: IOrder[], search: string) => {
  if (search) {
    return orders.filter((el) => {
      return (
        el.currency_in.symbol.toLowerCase().includes(search.toLowerCase()) ||
        el.currency_out.symbol.toLowerCase().includes(search.toLowerCase()) ||
        el.createdAt.includes(search) ||
        el.updatedAt.includes(search) ||
        el.status.toLowerCase().includes(search.toLowerCase()) ||
        el.wallet_receiver.toLowerCase().includes(search.toLowerCase()) ||
        el.wallet_sender.toLowerCase().includes(search.toLowerCase()) ||
        el.currency_in.contract.network.name
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        el.currency_out.contract.network.name
          .toLowerCase()
          .includes(search.toLowerCase())
      );
    });
  } else {
    return orders;
  }
};

export const shortAddress = (address: string, chars = 4) => {
  return `${address.substring(0, chars)}...${address.substring(
    address.length - 3
  )}`;
};
export function useOnClickOutside(
  node: RefObject<HTMLDivElement>,
  handler?: () => void
) {
  const handlerRef = useRef(handler);
  useEffect(() => {
    handlerRef.current = handler;
  }, [handler]);

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (node.current?.contains(e.target) ?? false) {
        return;
      }
      if (handlerRef.current) handlerRef.current();
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [node]);
}

export const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

export const logInGroup = (group: string, ...args: any[]) => {
  if (stage === 'production') return;

  console.groupCollapsed(group);
  args.forEach((arg) => console.log(arg));
  console.groupEnd();
};
