import { stage, STAGES } from '../Config/ApiConfig';
// import { NetworkTypes } from "../providers/web3Provider";
import { INetwork } from '../types/apiTypes';

export type MockNetwork = Omit<
  INetwork,
  'network_type' | 'active' | 'nativeTransferGas' | 'gasPrice' | 'created_at'
> & {
  rpc_url: string;
  is_rpc: boolean;

  is_new_destination?: boolean;
  new_destination_bg?: string;
};

const testnetNetworks: MockNetwork[] = [
  {
    id: 'c7d59050-6f3e-4b9c-a793-852c89859553',
    name: 'Goerli Testnet',
    chainId: '5',
    network_image_url: '/static/networks/ethereum.svg',
    blockscan_url: 'https://goerli.etherscan.io/',
    token_symbol: 'ETH',
    token_decimals: 18,
    // network_type: NetworkTypes.EVM,
    is_rpc: true,
    rpc_url: 'https://rpc.ankr.com/eth_goerli',
    category: 'L1 EVM Networks',
  },
  {
    id: '839c3c7f-9d9a-4783-b1f1-02e258fe736b',
    name: 'Mumbai Testnet',
    chainId: '80001',
    network_image_url: '/static/networks/polygon.svg',
    blockscan_url: 'https://mumbai.polygonscan.com/',
    token_symbol: 'MATIC',
    token_decimals: 18,
    // network_type: NetworkTypes.EVM,
    is_rpc: true,
    rpc_url: 'https://rpc.ankr.com/polygon_mumbai',
    category: 'L1 EVM Networks',
  },
  {
    id: '0e6268d5-6757-47bf-bd04-217ff8d6974e',
    name: 'BNB Smart Chain Testnet',
    chainId: '97',
    network_image_url: '/static/networks/binance.svg',
    blockscan_url: 'https://testnet.bscscan.com/',
    token_symbol: 'BNB',
    token_decimals: 18,
    // network_type: NetworkTypes.EVM,
    is_rpc: true,
    rpc_url: 'https://bsc-testnet.publicnode.com',
    category: 'L1 EVM Networks',
  },
  {
    id: '588dc52b-1b9e-47e8-b4c8-bcdc9a5e85ac',
    name: 'Sepolia Testnet',
    chainId: '11155111',
    network_image_url: '/static/networks/ethereum.svg',
    blockscan_url: 'https://sepolia.etherscan.io/',
    token_symbol: 'ETH',
    token_decimals: 18,
    // network_type: NetworkTypes.EVM,
    is_rpc: true,
    rpc_url: 'https://ethereum-sepolia.publicnode.com',
    category: 'L1 EVM Networks',
  },
  {
    id: 'e8599c5c-3176-421f-8e2a-77306bd30fff',
    name: 'Fantom Testnet',
    chainId: '4002',
    network_image_url: '/static/networks/fantom.webp',
    blockscan_url: 'https://testnet.ftmscan.com/',
    token_symbol: 'FTM',
    token_decimals: 18,
    // network_type: NetworkTypes.EVM,
    is_rpc: true,
    rpc_url: 'https://rpc.ankr.com/fantom_testnet',
    category: 'L2 EVM Networks',
  },
  {
    id: '44a7bd7c-168a-42aa-8156-b5e27138ebb5',
    name: 'Scroll Sepolia Testnet',
    chainId: '534351',
    network_image_url: '/static/networks/scroll_network.svg',
    blockscan_url: 'https://sepolia-blockscout.scroll.io/',
    token_symbol: 'ETH',
    token_decimals: 18,
    // network_type: NetworkTypes.EVM,
    is_rpc: true,
    rpc_url: 'https://rpc.ankr.com/scroll_sepolia_testnet',
    category: 'L2 EVM Networks',
  },
  {
    id: '2d7e8bbc-193e-4ed5-a512-7cd49c1d27d9',
    name: 'Linea Testnet',
    chainId: '59140',
    network_image_url: '/static/networks/linea.svg',
    blockscan_url: 'https://goerli.lineascan.build/',
    token_symbol: 'ETH',
    token_decimals: 18,
    // network_type: NetworkTypes.EVM,
    is_rpc: true,
    rpc_url: 'https://rpc.goerli.linea.build',
    category: 'L2 EVM Networks',
  },
  {
    id: '355ff90f-ba61-41fa-900a-67e54d00a2f7',
    name: 'zkSync Era Testnet',
    chainId: '280',
    network_image_url: '/static/networks/zkSyncEra.svg',
    blockscan_url: 'https://goerli.explorer.zksync.io/',
    token_symbol: 'ETH',
    token_decimals: 18,
    // network_type: NetworkTypes.ZK_SYNC_ERA,
    is_rpc: true,
    rpc_url: 'https://testnet.era.zksync.dev',
    category: 'L2 EVM Networks',
  },
  {
    id: 'e2c334bf-e874-432d-a227-291d0784a32e',
    name: 'Starknet Testnet',
    chainId: '0x534e5f474f45524c49',
    network_image_url: '/static/networks/starkNet.svg',
    blockscan_url: 'https://testnet.starkscan.co/',
    token_symbol: 'ETH',
    token_decimals: 18,
    // network_type: NetworkTypes.STARKNET,
    is_rpc: false,
    rpc_url: 'https://alpha4.starknet.io',
    category: 'L2 EVM Networks',
  },
  {
    id: '30a9afc7-d927-4e17-acf2-16544ba8050d',
    name: 'Zora Testnet',
    chainId: '999999999',
    network_image_url: '/static/networks/zora.png',
    blockscan_url: 'https://sepolia.explorer.zora.energy/',
    token_symbol: 'ETH',
    token_decimals: 18,
    // network_type: NetworkTypes.EVM,
    is_rpc: true,
    rpc_url: 'https://sepolia.rpc.zora.energy',
    category: 'L2 EVM Networks',
  },
  {
    id: '38e020c4-7e6e-4c3f-9a60-8d98612a706a',
    name: 'Manta Pacific Testnet',
    chainId: '3441005',
    network_image_url: '/static/networks/mantaPacific.png',
    blockscan_url: 'https://pacific-info.testnet.manta.network/',
    token_symbol: 'ETH',
    token_decimals: 18,
    // network_type: NetworkTypes.EVM,
    is_rpc: true,
    rpc_url: 'https://pacific-rpc.testnet.manta.network/http',
    category: 'L2 EVM Networks',
  },
  {
    id: 'e5931d72-4022-4fde-96c2-2ca5e6e480b3',
    name: 'BeraChain Artio',
    chainId: '80085',
    network_image_url: '/static/networks/berachainArtio.png',
    blockscan_url: 'https://artio.beratrail.io/',
    token_symbol: 'BERA',
    token_decimals: 18,
    // network_type: NetworkTypes.EVM,
    is_rpc: true,
    rpc_url: 'https://artio.rpc.berachain.com',
    category: 'Innovative Networks',
  },
  {
    id: 'adfe993b-880c-4472-a347-a6e1db5558ea',
    name: 'Shardeum Sphinx',
    chainId: '8082',
    network_image_url: '/static/networks/shardeum.png',
    blockscan_url: 'https://explorer-sphinx.shardeum.org/',
    token_symbol: 'SHM',
    token_decimals: 18,
    // network_type: NetworkTypes.EVM,
    is_rpc: true,
    rpc_url: 'https://sphinx.shardeum.org',
    category: 'L1 EVM Networks',
  },
  {
    id: '836f297e-c678-4a47-80b8-9a869f7e7026',
    name: 'Arbitrum Sepolia',
    chainId: '421614',
    blockscan_url: 'https://sepolia.arbiscan.io/',
    network_image_url: '/static/networks/arbitrum.svg',
    token_symbol: 'ETH',
    token_decimals: 18,
    // network_type: NetworkTypes.EVM,
    is_rpc: true,
    rpc_url: 'https://public.stackup.sh/api/v1/node/arbitrum-sepolia',
    category: 'L2 EVM Networks',

    is_new_destination: true,
    new_destination_bg: require('../Assets/images/networks/arbitrumBannerBg.png'),
  },
  {
    id: '15c9ee99-b39c-4a8a-a10a-89411cb2b6cd',
    name: 'Taiko Hekla',
    chainId: '167009',
    blockscan_url: 'https://hekla.taikoscan.network/',
    network_image_url: '/static/networks/taikoHekla.png',
    token_symbol: 'ETH',
    token_decimals: 18,
    // network_type: NetworkTypes.EVM,
    category: 'Innovative Networks',
    is_rpc: true,
    rpc_url: 'https://taiko-hekla.blockpi.network/v1/rpc/public',

    is_new_destination: true,
    new_destination_bg: require('../Assets/images/networks/taikoBannerBg.png'),
  },
];

const stages: Record<STAGES, MockNetwork[]> = {
  production: testnetNetworks,
  staging: testnetNetworks,
  development: testnetNetworks,
};

//fromStartIndex - always 0
//toStartIndex - needs to be less than or equal to ARRAY_LENGTH / 2
//if ARRAY_LENGTH less than 14, toStartIndex = (ARRAY_LENGTH - 7)/2
//TODO add calculation for toStartIndex
const listIndexes: Record<
  STAGES,
  { fromStartIndex: number; toStartIndex: number }
> = {
  production: { fromStartIndex: 0, toStartIndex: 1 },
  staging: { fromStartIndex: 0, toStartIndex: 1 },
  development: { fromStartIndex: 0, toStartIndex: 1 },
};

export const mockNetworks = stages[stage as STAGES];
export const networkListIndexes = listIndexes[stage];
